import React from "react";
import { ImageUpload, Notify } from 'zent';


const accept = ['image/gif', "image/jpeg", "image/png"];
const maxUploadSize = 1024 *1024 * 3;
class Item extends React.Component {
    state = {
        errorType: 0,
        errorMsg: ""
    }
    ref = React.createRef();
    onUploadChange = files => {
        if (files.length == 0) {
            this.props.item.formData.value = "";
        } else {
            this.props.item.formData.value = files;
        }
        this.props.questionChange(this.props.item);
    };
    onUpload = (file, report) => {
        return new Promise((resolve, reject) => {
            let count = 0;
            const update = () => {
                if (count < 100) {
                    count += 10;
                    report(count);
                    setTimeout(update, 100);
                } else {
                    resolve();
                }
            };
            setTimeout(update, 100);
        });
    };
    onUploadError = (type, data) => {
        if (type === 'overMaxAmount') {
            Notify.error(`最多可上传 ${data.maxAmount} 张图片`);
        } else if (type === 'overMaxSize') {
            Notify.error(`图片大小不能超过 ${data.formattedMaxSize}`);
        }
    };
    onBeforeUpload(file) {
        return new Promise((resolve, reject) => {
            if (accept.includes(file.type)) {
                if(file.size > maxUploadSize){
                    this.setState({
                        errorType: "2",
                        errorMsg: "请上传3MB以内的图片"
                    })
                    reject(file);
                } else{
                    this.setState({
                        errorType: 0,
                    })
                    resolve(file);
                }
            } else {
                this.setState({
                    errorType: "1",
                    errorMsg: "仅支持jpg、gif、png"
                })
                reject(file);
            }
        });
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>

                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper">
                    <ImageUpload 
                        // maxSize={maxUploadSize}
                        // tips="建议尺寸 640*640，最多 8 张，单张图片不超过 3M"
                        maxAmount={8}
                        multiple
                        sortable
                        accept={accept.join(",")}
                        defaultFileList={this.props.item.formData.value}
                        onChange={this.onUploadChange.bind(this)}
                        beforeUpload={this.onBeforeUpload.bind(this)}
                        onUpload={this.onUpload.bind(this)}
                        onError={this.onUploadError.bind(this)} />
                    <div className="image_title_des">请选择图片，仅支持jpg、gif、png，最多8张，单张图片不超过3MB</div>
                </div>
                {this.state.errorType ? <div className="preview_regex_check_tips">{this.state.errorMsg}</div> : ""}
            </div>
        )
    }
}

export default Item;