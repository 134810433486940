import React from "react";
import { Radio, Tooltip, Input } from 'zent';
import star1 from "@/assets/images/icons/star.png";
import tip1 from "@/assets/images/icons/tip.png";
const RadioGroup = Radio.Group;

/**
 * @description 参与次数
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    state ={
        dayDist: false,
        timesDist: false
    }
    stateChange(e) {
        e.persist();
        if(e.target.value != "3"){
            this.setState({
                dayDist: false,
                timesDist: false
            })
        }
        this.props.setValue(this.props.item.key, e.target.value)
    }
    dayChange(e) {
        e.persist();
        this.props.item.day = e.target.value && e.target.value.trim();
        this.props.setValue(this.props.item.key, this.props.item.value);
    }
    timesChange(e) {
        e.persist();
        this.props.item.number = e.target.value && e.target.value.trim();
        this.props.setValue(this.props.item.key, this.props.item.value);
    }
    dayBlur() {
        if (this.props.item.value == "3") {
            this.setState({
                dayDist: true
            })
        }
    }
    timesBlur() {
        if (this.props.item.value == "3") {
            this.setState({
                timesDist: true
            })
        }
    }

    render() {
        return (
            <div className={`w1_form_item join_times`}>
                <div className="name_line">
                    <div className="power_title">
                        {this.props.item.required && <img src={star1}></img>}
                        <span>{this.props.item.title}</span>
                        <Tooltip centerArrow trigger="hover" title={this.props.item.tips} position="top-left" style={{ "background": "#fff", "color": "#333", "width": "200px" }}>
                            <div className="power_tip">
                                <img src={tip1}></img>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <RadioGroup onChange={this.stateChange.bind(this)} value={this.props.item.value}>
                    {this.props.item.options.map((one, index) => {
                        return <Radio value={one.key} key={index}>{one.key == "3" ? (<div className="day_times">
                            <span>每人</span>
                            <div className={`join_day_wrapper ${(this.props.item.day && (/^[1-9]\d*$/).test(this.props.item.day)) && "fill"} ${this.state.dayDist? "dist": ''}`}>
                                <Input placeholder={this.props.item.placeholder}
                                    onChange={this.dayChange.bind(this)}
                                    onBlur={this.dayBlur.bind(this)}
                                    value={this.props.item.day} />
                                <div className="require join_times_msg">{this.props.item.msg}</div>
                            </div>
                            <span>天内参与</span>
                            <div className={`join_times_wrapper ${(this.props.item.number && (/^[1-9]\d*$/).test(this.props.item.number)) && "fill"} ${this.state.timesDist? "dist": ''}`}>
                                <Input placeholder={this.props.item.placeholder}
                                    onChange={this.timesChange.bind(this)}
                                    onBlur={this.timesBlur.bind(this)}
                                    value={this.props.item.number} />
                                <div className="require join_times_msg">{this.props.item.msg}</div>
                            </div>
                            <span>次</span>
                        </div>) : one.value}</Radio>
                    })}
                </RadioGroup>

            </div>
        )
    }
}

export default Item