import React from "react";
import { Tabs, Radio } from 'zent';
import Title from "./formItem/title";
import Date from "./formItem/date";
import Description from "./formItem/description";
import TextLine from "./formItem/textLine";
import ColorLine from "./formItem/colorLine";
import UnderLine from "./formItem/underLine";
const TabPanel = Tabs.TabPanel;
const RadioGroup = Radio.Group;

// const urlRegex = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
class TextModule extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        optionsRefs: [],
        activeId: "1",
        fontSizeOptions: [
            {
                key: "16",
                value: "大 (16号)",
                text: "A"
            },
            {
                key: "14",
                value: "中 (14号)",
                text: "A"
            },
            {
                key: "12",
                value: "小 (12号)",
                text: "A"
            }
        ],
        fontWeightOptions: [
            {
                key: "normal",
                value: "常规体",
                text: "T"
            },
            {
                key: "bold",
                value: "加粗体",
                text: "T"
            },
        ],
        positionOptions: [
            {
                key: "left",
                value: "居左显示",
                text: <svg viewBox="0 0 1024 1024" width="16" height="16"><path d="M0 93.090909h1024v93.090909H0z"></path><path d="M0 332.334545h744.727273v93.09091H0z"></path><path d="M0 810.775273h744.727273v93.090909H0z"></path><path d="M0 571.531636h1024v93.137455H0z"></path></svg>
            },
            {
                key: "center",
                value: "居中显示",
                text: <svg viewBox="0 0 1024 1024" width="16" height="16"><path d="M-0.011289 143.976364h1024v64.007056H-0.011289zM223.990828 815.971425h575.984478v64.007056H223.990828zM0 591.969309h1024v64.007055H0zM223.990828 367.978481h575.984478v64.007055H223.990828z"></path></svg>
            },
        ],
        wxPositionOptions: [
            {
                key: "left",
                value: "居左显示",
                text: <svg viewBox="0 0 1024 1024" width="16" height="16"><path d="M0 93.090909h1024v93.090909H0z"></path><path d="M0 332.334545h744.727273v93.09091H0z"></path><path d="M0 810.775273h744.727273v93.090909H0z"></path><path d="M0 571.531636h1024v93.137455H0z"></path></svg>
            },
            {
                key: "center",
                value: "居中显示",
                text: <svg viewBox="0 0 1024 1024" width="16" height="16"><path d="M-0.011289 143.976364h1024v64.007056H-0.011289zM223.990828 815.971425h575.984478v64.007056H223.990828zM0 591.969309h1024v64.007055H0zM223.990828 367.978481h575.984478v64.007055H223.990828z"></path></svg>
            },
            {
                key: "right",
                value: "居右显示",
                text: <svg viewBox="0 0 1024 1024" width="16" height="16"><path d="M0 93.090909h1024v93.090909H0z"></path><path d="M279.272727 332.288h744.727273v93.090909H279.272727z"></path><path d="M279.272727 810.775273h744.727273v93.090909H279.272727z"></path><path d="M0 571.531636h1024v93.090909H0z"></path></svg>
            },
        ]
    }
    titleRef = React.createRef();
    moreUrlRef = React.createRef();

    setValue(key, value, index) {
        this.props.activeModule.formData[key].value = value;
        this.props.updateRequire();
    }

    setAttr(key1, key2, value) {
        this.props.activeModule.formData[key1][key2] = value;
        this.props.updateRequire();
    }
    onTabChange = id => {
        this.props.activeModule.formData.style_type = id;
        this.props.updateRequire();
    };
    onChange(e){
        this.setAttr("more", "style", e.target.value);
    }
    componentDidUpdate() {
    }

    componentDidMount() {
    }

    render() {
        const panels = [
            <TabPanel key="1" tab={<span>传统样式</span>} id="1">
                <div>
                    {/* 标题 */}
                    <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.title} ref={this.titleRef}></Title>
                    {/* 描述 */}
                    <Description setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.desc}></Description>
                    <TextLine title="显示位置" options={this.state.positionOptions} key1="title" key2="pos" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.title.pos}></TextLine>
                    <TextLine title="标题大小" options={this.state.fontSizeOptions} key1="title" key2="fontSize" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.title.fontSize}></TextLine>
                    <TextLine title="描述大小" options={this.state.fontSizeOptions} key1="desc" key2="fontSize" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.desc.fontSize}></TextLine>
                    <TextLine title="标题粗细" options={this.state.fontWeightOptions} key1="title" key2="fontWeight" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.title.fontWeight}></TextLine>
                    <TextLine title="描述粗细" options={this.state.fontWeightOptions} key1="desc" key2="fontWeight" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.desc.fontWeight}></TextLine>
                    <ColorLine title="标题颜色" defaultColor={this.props.activeModule.formData.title.defaultColor} key1="title" key2="color" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.title.color}></ColorLine>
                    <ColorLine title="描述颜色" defaultColor={this.props.activeModule.formData.desc.defaultColor} key1="desc" key2="color" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.desc.color}></ColorLine>
                    <ColorLine title="背景颜色" defaultColor={this.props.activeModule.formData.title.defaultBackground} key1="title" key2="background" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.title.background}></ColorLine>
                    <UnderLine title="底部分割线" trueValue="显示" falseValue="不显示" key1="title" key2="line" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.title.line}></UnderLine>
                    <div className="assist_line"></div>
                    <UnderLine title="查看更多" trueValue="显示" falseValue="不显示" key1="more" key2="show" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.more.show}></UnderLine>
                    {this.props.activeModule.formData.more.show && <div className="more_details">
                        <div className="more_style">
                            <RadioGroup onChange={this.onChange.bind(this)} value={this.props.activeModule.formData.more.style}>
                                <Radio value="1">样式一</Radio>
                                <Radio value="2">样式二</Radio>
                                <Radio value="3">样式三</Radio>
                            </RadioGroup>
                        </div>
                        <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.more}></Title>
                        <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.moreUrl}></Title>
                        
                    </div>}
                </div>
            </TabPanel>,
            <TabPanel key="2" tab="微信图文样式" id="2">
                <div>
                    {/* 标题 */}
                    <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.title} ref={this.titleRef}></Title>
                    <Date setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.date}></Date>
                    <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.author}></Title>
                    <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.link_title}></Title>
                    <TextLine title="位置选择" options={this.state.wxPositionOptions} key1="desc" key2="pos" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.desc.pos}></TextLine>
                    <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.wxUrl}></Title>
                </div>
            </TabPanel>,
        ];
        return (
            <div className="w1_single w1_base">
                <Tabs
                    activeId={this.props.activeModule.formData.style_type}
                    stretch
                    onChange={this.onTabChange}
                    type="normal">
                    {panels}
                </Tabs>
            </div>
        )
    }
}

export default TextModule