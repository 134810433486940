import React from "react";
import { withRouter } from 'react-router-dom'
import "./data.css"
import { Tag } from 'zent';
import { Tabs } from 'zent';
import { Button } from 'zent';
import { Progress } from 'zent';
import { Link } from 'zent';
import { Icon } from 'zent';
import { getAnswerData, getAnswerListByUser, getAnswerInputOption, getAnswerOtherOption, delData } from "@/api/index";
import global from '@/utils/config';
import { Grid } from 'zent';
import { Portal, PurePortal, Input, Pop, Dialog, Notify } from 'zent';

const TabPanel = Tabs.TabPanel;


class Data extends React.Component {
    state = {
        activeId: '1',
        question: {},
        module: [],
        level: 1,
        del_vis: false,

        current: 1,
        pageSize: 20,
        total: 10,
        total_title: '',
        datasets: [],
        bodyPortalVisible: false,

        info: {},
        index: 0,
        search: '',
        columns : [
            {
                title: '客户',
                name: 'nickname'
            },
            {
                title: '提交时间',
                name: 'date',
                width: 400
            },
            {
                title: '操作',
                width: 100,
                bodyRender: (data, pos) => {
                    return <Link onClick={this.showAnswer.bind(this, data.id)}>查看答卷</Link>
                    
                }
            },
        ],
        pcolumns: [
            {
              title: '序号',
              name: 'id',
              width: 100
            },
            {
                title: '提交时间',
                name: 'date',
                width: 200
              },
              {
                title: '提交内容',
                name: 'value',
                bodyRender: (data, pos) => {
                    if (this.state.info.type == 'image') {
                        var images = JSON.parse(data.value)
                        var test = images.map((item, index3) => {
                            var Content = Pop.withPop(function Content({ pop }) {
                                return (
                                  <div>
                                    <div style={{ height: '400px' }}><a href={item} target="_blank"><img src={item}  style={{height: "100%"}}></img></a></div>
                                    {/* <Button onClick={pop.close}>关闭</Button> */}
                                  </div>
                                );
                              });
                            return <Pop trigger="hover" content={<Content />} key={index3}><img src={item} className="col_img"></img></Pop>
                        })

                        return test;
                        
                    } else {
                        return <span>{data.value}</span>
                    }
                  }
              },
            {
              title: '操作',
              width: 200,
              bodyRender: (data, pos) => {
                if (this.state.info.type == 'image') {
                    return <Link onClick={this.showAnswer.bind(this, data.aid)}>查看答卷</Link>
                    // return <div className="image_op"><Link to={url}>查看答卷</Link> <span> | </span> <Link to={url}>下载图片</Link></div>
                } else {
                    return <Link onClick={this.showAnswer.bind(this, data.aid)}>查看答卷</Link>
                }
              }
            },
          ],
        pdatasets: [],
        pcurrent: 1,
        ppageSize: 10,
        ptotal: 0,

        poid:0
    };

    conDel() {
        delData({
            qid: this.state.question.id
        }).then(() => {
            this.setState({del_vis:false});
            Notify.success('已为您清空所有数据！');
        })
    }

    clickDel(val) {
        this.setState({del_vis:val});
    }

    showAnswer(aid) {
        this.props.history.push("/answer/" + aid);
    }

    popContent() {
        return Pop.withPop(function Content({ pop }) {
            return (
              <div>
                <div style={{ marginBottom: 16 }}>Pop 内容</div>
                <Button onClick={pop.close}>关闭</Button>
              </div>
            );
          });
    }

    // const Content = 


    ponSearchChange(e) {
        this.setState({ psearch: e.target.value });
    }

    psearch() {
            this.setState({
                current: 1
            },() => {
                this.init_data(this.state.info.type)
            })
    }

    ponChange = ({ current, pageSize }) => {
        this.setState({
            pcurrent: current
        },() => {
            this.init_data(this.state.info.type)
        })
      };


    hideBodyPortal() {
        this.setState({ bodyPortalVisible: false, pdatasets:[] });
    }

    onTabChange = id => {
        this.setState({
            activeId: id,
        });
    };

    onChange = ({ current, pageSize }) => {
        console.log("on change, current", current);
        var that = this;
        getAnswerListByUser({
            authority_id: localStorage.getItem("wj_authority_id"),
            qid: this.state.question.id,
            current: current
        }).then(res => {
            that.setState({
                current: res.data.current,
                pageSize: res.data.pageSize,
                datasets: res.data.list
            });
            // this.setState({
            //     current,
            //     pageSize,
            // });
        })
    };

    otherOption(one, oid, index) {
        this.setState({
            bodyPortalVisible: true,
            info: one,
            index: index,
            psearch: '',
            pcurrent: 1,
            poid: oid
        },() => {
            this.init_data(one.type)
        });

        // this.props.history.push("/otherOption/" + qid + "/" + mid + "/" + oid + "/" + index)
    }

    gotoOption(one, index) {
        this.setState({
            bodyPortalVisible: true,
            info: one,
            index: index,
            psearch: '',
            pcurrent: 1
        },() => {
            this.init_data(one.type)
        });
    }

    init_data(type) {
        if (type == 'input' || type == 'mobile' || type == 'mail' || type == 'date' || type == 'area' || type == 'image') {
            getAnswerInputOption({
                qid:this.state.question.id,
                mid:this.state.info.id, 
                current:this.state.pcurrent,
                search:this.state.psearch
            }).then(res => {
                let data = res.data.data;
                console.log(data);
                this.setState({
                    pcurrent: data.current,
                    ppageSize:data.pageSize,
                    ptotal: data.total,
                    pdatasets: data.list
                });
            })
        }
        else if (type == 'radio' || type == 'checkbox') {
            getAnswerOtherOption({
                qid:this.state.question.id,
                mid:this.state.info.id, 
                oid:this.state.poid,
                current:this.state.pcurrent,
                search:this.state.psearch
            }).then(res => {
                let data = res.data.data;
                console.log(data);
                this.setState({
                    pcurrent: data.current,
                    ppageSize:data.pageSize,
                    ptotal: data.total,
                    pdatasets: data.list
                })
            })
        }
    }

    export() {
        var url = "/getToFile?authority_id="+localStorage.getItem("wj_authority_id")+"&qid="+this.state.question.id;
        window.open(url);
    }

    constructor(props) {
        super(props);

        var qid = props.match.params.id;
        getAnswerData({
            authority_id: localStorage.getItem("wj_authority_id"),
            qid: qid
        }).then(res => {
            console.log(res);
            let data = res.data.data;
            this.setState({ question: data, module: data.module })
        })

        getAnswerListByUser({
            authority_id: localStorage.getItem("wj_authority_id"),
            qid: qid
        }).then(res => {
            this.setState({
                current: res.data.current,
                pageSize: res.data.pageSize,
                total: res.data.total,
                total_title: '详细数据 (' + res.data.total + ')',
                datasets: res.data.list
            })
        })
    }

    goBack(){
        this.props.history.goBack();
    }


    render() {
        return (
            <div className="wj_wrapper_container">
                <Dialog
                    visible={this.state.del_vis}
                    onClose={() => this.clickDel(false)}
                    footer={
                        <div>
                        <Button onClick={() => this.clickDel(false)}>关闭</Button>
                        <Button onClick={() => this.conDel()} type="primary">清空</Button>
                        </div>
                    }
                    title="提示"
                    >
                    <p>清空后数据不可恢复，确定清空吗？</p>
                </Dialog>
                <div className="wj_wrapper">
                    <div className="wj_title">
                        <Button className="wj_go_back" type="primary" onClick={this.goBack.bind(this)}>返回</Button>
                        <span className="title_left">{this.state.question.name}</span>
                        <Tag theme="blue" outline>{this.state.question.status}</Tag>
                        <div className="sub_title">
                            有效时间：{this.state.question.begin_time} 至 {this.state.question.end_time}
                        </div>
                    </div>
                    <Tabs activeId={this.state.activeId} onChange={this.onTabChange} align="right">
                        <TabPanel tab={<span>统计图表</span>} id="1">
                            <div>
                                <div className="top_btn_view">
                                    <Button type="primary" onClick={this.export.bind(this)}>导出数据</Button>
                                    <Button className="top_btn" onClick={() => this.clickDel(true)}>清空数据</Button>
                                </div>

                                {
                                    this.state.module.map((one, index) => {
                                        if (one.type != 'text' && one.type != 'textarea') {
                                            if (one.level == 1 && this.state.level == 0) {
                                                return <div className="data_row" key={index}>
                                                    <div>
                                                        {
                                                            one.required == 1 && <span className="bx"> * </span>
                                                        }

                                                        第{index + 1}题：{one.title} ({one.type_v})
                                                    </div>
                                                    <div className="no_permissions">
                                                        <Icon type="lock" />
                                                        <span>  该题设置了查看权限，仅高级管理员能查看统计数据</span>
                                                    </div>
                                                </div>
                                            } else {
                                                if (one.type == "radio" || one.type == "checkbox") {
                                                    return <div className="data_row" key={index}>
                                                        <div>
                                                            {
                                                                one.required == 1 && <span className="bx"> * </span>
                                                            }
                                                            第{index + 1}题：{one.title} ({one.type_v})
                                                        </div>
                                                        <div className="row_title">
                                                            <span className="col_1">选项</span>
                                                            <span className="col_2">问卷数</span>
                                                            <span className="col_3">比例</span>
                                                        </div>
                                                        {
                                                            one.list.map((val, index2) => {
                                                                if (val.type == 0) {
                                                                    return <div className="row_content" key={index2}>
                                                                        <div className="col_1">
                                                                            {
                                                                                val.image != "" && <img className="col_img" src={val.image}></img>
                                                                            }

                                                                            <span>{val.value}</span>
                                                                        </div>
                                                                        <span className="col_2">{val.answer_count}</span>
                                                                        <span className="col_3">
                                                                            <Progress percent={val.percent != "nan" ? val.percent * 100 : 0} />
                                                                        </span>
                                                                    </div>
                                                                } else {
                                                                    return <div className="row_content" key={index2}>
                                                                        <div className="col_1">
                                                                            <span>其他</span>
                                                                            <Link onClick={this.otherOption.bind(this, one, val.id, index + 1)} className="otherLink" target="_blank">查看详情</Link>
                                                                        </div>
                                                                        <span className="col_2">{val.answer_count}</span>
                                                                        <span className="col_3">
                                                                            <Progress percent={val.percent != "nan" ? val.percent * 100 : 0} />
                                                                        </span>
                                                                    </div>
                                                                }

                                                            })
                                                        }


                                                        <div className="row_content">
                                                            <div className="col_1">
                                                                <span>本题有效提交人数</span>
                                                            </div>
                                                            <span className="col_2">{one.user_count == null ? 0 : one.user_count}</span>
                                                        </div>
                                                    </div>
                                                } else {
                                                    return <div className="data_row" key={index}>
                                                        <div>
                                                            {
                                                                one.required == 1 && <span className="bx"> * </span>
                                                            }
                                                            第{index + 1}题：{one.title} ({one.type_v})</div>
                                                        <div className="row_content no_border">
                                                            <Button size="large" onClick={this.gotoOption.bind(this, one, index + 1)} >查看详细信息</Button>
                                                        </div>
                                                    </div>
                                                }
                                            }
                                        }
                                    })                                             //, one.id, index+1, one.type
                                }

                            </div>
                        </TabPanel>
                        <TabPanel tab={this.state.total_title} id="2">
                            <div>
                                <Grid
                                    columns={this.state.columns}
                                    datasets={this.state.datasets}
                                    pageInfo={{
                                        current: this.state.current,
                                        pageSize: this.state.pageSize,
                                        total: this.state.total,
                                        pageSizeOptions: [5, 10],
                                    }}
                                    onChange={this.onChange}
                                    ellipsis
                                />

                                <div className="page_bottom">
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>

                <Portal
                    visible={this.state.bodyPortalVisible}
                    onClose={this.hideBodyPortal.bind(this)}
                    className="layer"
                    style={{ background: 'rgba(0, 0, 0, 0.2)' }}
                    useLayerForClickAway
                    closeOnClickOutside
                    closeOnESC
                    blockPageScroll
                >
                    <div
                        className="zent-doc-portal-content"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate3d(-50%, -50%, 0)',
                            border: '1px solid #d3d3d3',
                            borderRadius: '3px',
                            background: 'white',
                            width: '80%'
                        }}
                    >
                        <div className="pop_head">
                            <div className="pop_head_left">详细信息</div>
                            <div className="pop_head_right">
                                <Icon type="close" onClick={this.hideBodyPortal.bind(this)} className="zenticon-demo" />
                            </div>
                        </div>

                        <div className="pop_contnet">
                            <div className="other_top">
                                <div className="other_top_left">
                                    {
                                        this.state.info.required == 1 && <span className="bx"> * </span>
                                    }
                                    第{this.state.index}题：{this.state.info.title}
                                </div>
                                <div className="other_top_right">
                                    <Input className="search_input" icon="search" onChange={this.ponSearchChange.bind(this)} onPressEnter={this.psearch.bind(this)} onIconClick={this.psearch.bind(this)} value={this.state.psearch} showClear />
                                </div>

                            </div>
                            <div className="p_content">
                                <Grid
                                    columns={this.state.pcolumns}
                                    datasets={this.state.pdatasets}
                                    pageInfo={{
                                    current: this.state.pcurrent,
                                    pageSize: this.state.ppageSize,
                                    total: this.state.ptotal,
                                    }}
                                    onChange={this.ponChange}
                                    ellipsis
                                />
                            </div>
                        </div>
                    </div>
                </Portal>
            </div>

        )
    }
}

export default withRouter(Data)