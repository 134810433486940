import React from "react";

class Item extends React.Component {
    state = {
    }
    ref = React.createRef();
    onChange = e => {
        e.persist();
        this.props.item.formData.value = e.target.value;
        this.props.questionChange(this.props.item);
    };
    showCascader() {
        this.props.updateMIndex(this.props.item.id);
        setTimeout(() => {
            this.props.showCascader("date");
        },0);
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "请选择日期"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper"><div className="w2_date_input"
                    onClick={this.showCascader.bind(this)}>{this.props.item.formData.value}</div>
                </div>
            </div>
        )
    }
}

export default Item;