import { createStore } from 'redux';

function getSystem(system = '') {
  var u = window.navigator.userAgent;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  
  if(isiOS){
    system = 'ios';
  } else if(isAndroid){
    system = "android"
  } else {
    system = "PC";
  }
  return system;
}

function getWxEnviroment(system = ''){
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("wxwork") >= 0 || ua.indexOf("micromessenger") >= 0) {
    if (ua.indexOf("wxwork") >= 0) {
      system = "wxwork";
      console.log("企业微信环境");
    } else {
      if(ua.toLowerCase().indexOf('miniprogram') >= 0){
        system = "wx";
        console.log("微信环境-小程序");
      } else {
        system = "wxweb";
        console.log("微信环境-网页");
      }
    }
  }
  return system;
}



// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
let systemStore = createStore(getSystem);
let wxEnviromentStore = createStore(getWxEnviroment);
const store = {
  systemStore,
  wxEnviromentStore
};

export default store;