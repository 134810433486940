import React from "react";
import { Select } from 'zent';
import { getTags } from "@/api/index";
import { FixedSizeList } from 'react-window';
import star1 from "@/assets/images/icons/star.png";

/**
 * @description 跳题组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    state = {
        open: false,
        hasResult: false,
        options: []
    }
    stateChange(e) {
        if (!e) {
            e = this.props.item.defaultOptions[this.props.item.default];
        }
        this.props.setValue(this.props.item.key, e)
    }
    stateBlur() {
        if (this.props.item.required) {
            this.dist = true;
        }
        this.state.open = !this.state.open;
        this.setState({
            open: this.state.open
        })
    }

    renderOptionList(options, renderOption) {
        return (
          <FixedSizeList
            height={8 * 32}
            itemCount={options.length}
            itemSize={32}
            width={240}
          >
            {({ index, style }) => (
              <div style={style}>{renderOption(options[index], index)}</div>
            )}
          </FixedSizeList>
        );
      }
    componentDidMount() {
        if(!this.state.hasResult){
            this.state.hasResult = true;
            /* getTags({}).then(res => {
                if(res.data.code != -1){
                    this.state.options = this.props.item.defaultOptions.slice(0);
                    this.props.item.options = res.data.map(one => {
                        one.key = one.id;
                        one.text = one.name;
                        return one;
                    });
                    this.setState({
                        options: [... new Set(this.state.options.concat(this.props.item.options))]
                    });
                }
            }) */
        }
    }

    componentWillUnmount = () => {
        this.setState = () => {
            return;
        };
    }

    render() {
        return <template></template>
        /* return (
            <div className={`w1_form_item ${!this.props.item.notsameline && "sameline"} ${this.props.item.value.length ? "fill" : ""} ${this.dist ? "dist" : ''}`}>
                <div className="name_line">
                    {this.props.item.required && <img src={star1}></img>}
                    <span>{this.props.item.title}</span>
                </div>
                <Select options={this.state.options}
                    className="rfs0"
                    multiple
                    collapsable={true}
                    hideCollapsePop
                    collapseAt={1}
                    inline={true}
                    width={this.props.item.width}
                    clearable
                    placeholder={this.props.item.placeholder}
                    notFoundContent="没有找到匹配的选择"
                    value={this.props.item.value}
                    onChange={this.stateChange.bind(this)}
                    onOpenChange={this.stateBlur.bind(this)}
                    open={this.state.open}
                    // renderOptionList={this.renderOptionList.bind(this)}
                />
                {this.props.item.msg && <div className="require">{this.props.item.msg}</div>}
            </div>
        ) */
    }
}

export default Item