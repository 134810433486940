import React from "react";
import { ColorPicker } from 'zent';

/**
 * @description 是否有权限查看
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    state = {
    }

    resetColor(){
        this.props.setAttr(this.props.key1, this.props.key2 ,this.props.defaultColor)
    }
    handleChange(color) {
        this.props.setAttr(this.props.key1, this.props.key2 ,color)
    }

    render() {
        return (
            <div className={`w1_form_item sameline text_line`}>
                <div className="name_line">
                    <span>{this.props.title}</span>
                    <span className="line_value">{this.props.value}</span>
                </div>
                <div className="color_wrapper">
                    <div className="reset_color" onClick={this.resetColor.bind(this)}>重置</div>
                    <ColorPicker color={this.props.value} onChange={this.handleChange.bind(this)} />
                </div>
            </div>
        )
    }
}

export default Item