import React from "react";
import { withRouter } from 'react-router-dom';
import RadioModule from "./components/radio";
import CheckboxModule from "./components/checkbox";
import InputModule from "./components/input";
import ImageModule from "./components/image";
import MobileModule from "./components/mobile";
import DateModule from "./components/date";
import TextModule from "./components/text";
import TextareaModule from "./components/textarea";

class Page extends React.Component {
    state = {
    }
    render() {
        return (
            <div className="w2_page_box">
                {
                    this.props.modules.map(m => {
                        if(!m.show){
                            return ""
                        }
                        let moduleDom = null;
                        switch (m.type) {
                            case "radio":
                                moduleDom = <RadioModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    paperMode={this.props.paperMode}
                                    questionChange={this.props.questionChange.bind(this)}></RadioModule>
                                break;
                            case "checkbox":
                                moduleDom = <CheckboxModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    timeCheck={this.props.timeCheck.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></CheckboxModule>
                                break;
                            case "input":
                                moduleDom = <InputModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    timeCheck={this.props.timeCheck.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></InputModule>
                                break;
                            case "image":
                                moduleDom = <ImageModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></ImageModule>
                                break;
                            case "mobile":
                                moduleDom = <MobileModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    timeCheck={this.props.timeCheck.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></MobileModule>
                                break;
                            case "mail":
                                moduleDom = <MobileModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    timeCheck={this.props.timeCheck.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></MobileModule>
                                break;
                            case "area":
                                moduleDom = <MobileModule item={m}
                                    updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    showCascader={this.props.showCascader.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></MobileModule>
                                break;
                            case "date":
                                moduleDom = <DateModule item={m}
                                    updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    showCascader={this.props.showCascader.bind(this)}
                                    questionChange={this.props.questionChange.bind(this)}></DateModule>
                                break;
                            case "text":
                                moduleDom = <TextModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    ></TextModule>
                                break;
                            case "textarea":
                                moduleDom = <TextareaModule item={m} updateResult={this.props.updateResult}
                                    updateMIndex={this.props.updateMIndex.bind(this)}
                                    ></TextareaModule>
                                break;
                            default:
                                break;
                        }
                        return <div className="w2_module_box" key={m.id}>{moduleDom}</div>;
                    })
                }
            </div>
        )
    }
}

export default withRouter(Page)