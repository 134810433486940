import React from "react";

export default class WrapLine extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div className="wrapLine">{this.props.text}</div>
        )
    }
}