import React from "react";
import { Checkbox, Button, ButtonGroup, Icon, BlockLoading, Dialog, Pagination } from 'zent';
import Title from "./title";
import { couponlist, presentList } from "@/api/index";
import star1 from "@/assets/images/icons/star.png";

/**
 * @description 是否必选组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    state = {
        openJuan: false,
        openCode: false,
        page: 1,
        pageSize: 20,
        total: 0,
        list: [],
        addIndex: 0,
        addTitle: "",
        visible: false,
        loading: false
    }
    stateChange(e) {
        this.props.setValue(this.props.item.key, e)
    }
    async addOption(index) {
        this.state.page = 1;
        this.setState({
            page: this.state.page
        })
        this.setState({ visible: true });
        this.getList(index);
    }

    async getList(index) {
        this.state.addIndex = index;
        this.setState({
            addIndex: index
        })
        let title = "请选择优惠券";
        if (index === 1) {
            title = "请选择优惠码";
        } else if (index === 3) {
            title = "请选择赠品";
        }
        this.setState({
            loading: true,
            addTitle: title
        })
        if (index === 0) {
            await this.getCouponlist(index + 1);
        } else if (index === 1) {
            await this.getCouponlist(index + 1);
        } else if (index === 3) {
            await this.getPresentList();
        }
        this.setState({
            loading: false
        })
    }


    onPageChange = async (options) => {
        this.state.page = options.current

        this.setState({
            page: this.state.page
        });
        this.getList(this.state.addIndex);
    };

    setValue(key, value) {
        this.props.item.options[2].score.value = value;
        this.props.setValue(this.props.item.key, this.props.item.value);

    }

    selectThis(id, text) {
        let index = this.state.addIndex;
        this.props.item.options[index].selectId = id;
        this.props.item.options[index].selectText = text;
        this.props.item.options[index].open = false;
        this.props.setValue(this.props.item.key, this.props.item.value);
        this.setState({
            visible: false
        })
    }

    delPrize(index) {
        this.props.item.options[index].selectId = "";
        this.props.item.options[index].selectText = "";
        this.props.item.options[index].dist = true;
        this.props.setValue(this.props.item.key, this.props.item.value);
    }

    async getCouponlist(type) {
        await couponlist({
            type: type,
            page: this.state.page
        }).then(res => {
            if (res.data.code == -1) {
                this.state.list = []
                this.setState({
                    list: []
                })
            } else {
                this.state.list = res.data.data || []
                this.setState({
                    list: res.data.data || []
                })
            }
            this.setState({
                total: res.data.total
            })
        });
    }

    async getPresentList() {
        await presentList({}).then(res => {
            if (res.data.code == -1) {
                this.state.list = []
                this.setState({
                    list: []
                })
            } else {
                this.state.list = res.data || []
                this.setState({
                    list: res.data || []
                })
            }
            this.setState({
                total: res.data.total
            })
        })
    }

    componentDidMount() {
    }
    setVisible = visible => {
        this.setState({ visible });
    };
    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }

    render() {
        return (
            <div className={`w1_form_item choose_prize_wrapper ${!this.props.item.notsameline && "sameline"}`}>
                <div className="name_line">
                    {this.props.item.required && <img src={star1}></img>}
                    <span>{this.props.item.title}</span>
                </div>
                <Checkbox.Group value={this.props.item.value} onChange={this.stateChange.bind(this)}>
                    {
                        this.props.item.options.map((one, index) => {
                            if (one.key == "3") {
                                return (
                                    <div key={one.key} className="prize_score">
                                        <Title setValue={this.setValue.bind(this)} item={one.score}></Title>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={one.key} className={`choose_prize_checkbox ${one.selectId ? "fill" : ""} ${one.dist ? "dist" : ''}`}>
                                        <Checkbox value={one.key} >{one.value}</Checkbox>
                                        {this.props.item.value.includes(one.key) && <ButtonGroup className="add_btn">
                                            {(!one.selectId) && <Button icon="plus" onClick={this.addOption.bind(this, index)}>{one.btn}</Button>}
                                            {(one.selectId) && <div className="prize_content_wrapper">
                                                <div className="prize_content">{one.selectText}</div>
                                                <div className="prize_del" onClick={this.delPrize.bind(this, index)}><Icon type="close-circle" /></div>
                                            </div>}
                                            {one.msg && <div className="require">{one.msg}</div>}
                                            {/* {(one.open) && <ul className={`prize_select`}>
                                                {one.list.map((one) => {
                                                    return <li key={one.id} onClick={this.selectThis.bind(this, one.id, one.title, index)}>{decodeURIComponent(one.title)}</li>
                                                })}
                                            </ul>} */}
                                        </ButtonGroup>}
                                    </div>
                                )
                            }

                        })
                    }

                </Checkbox.Group>
                <Dialog
                    visible={this.state.visible}
                    onClose={() => this.setVisible(false)}
                    footer={
                        <Button onClick={() => this.setVisible(false)}>关闭</Button>
                    }
                    title={this.state.addTitle}
                >
                    <div>
                        {!this.state.loading && <ul className={`prize_select`}>
                            {this.state.list.map(one => {
                                return <li key={one.id} onClick={this.selectThis.bind(this, one.id, one.title)}>{decodeURIComponent(one.title)}</li>
                            })
                            }
                        </ul>}
                        {this.state.total === 0 && !this.state.loading && <p>暂无数据</p>}
                        <BlockLoading loading={this.state.loading} icon="circle" iconSize={64} iconText="加载中" />
                        {this.state.addIndex !== 3 && <Pagination
                            current={this.state.page}
                            pageSize={this.state.pageSize}
                            total={this.state.total}
                            onChange={this.onPageChange.bind(this)}
                        />}
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default Item