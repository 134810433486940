import React from "react";
import DatePicker from 'react-mobile-datepicker';

class Item extends React.Component {
    state = {
        activeIndex: 0,
        show: false,
        max: new Date(),
        min: new Date(),
        value: new Date(),
        dateConfig: {
            'year': {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },
            'month': {
                format: 'MM',
                caption: 'Mon',
                step: 1,
            },
            'date': {
                format: 'DD',
                caption: 'Day',
                step: 1,
            }
        }
    }
    ref = React.createRef();

    handleClick = () => {
        this.setState({ show: true });
    }

    handleCancel = () => {
        document.querySelector(".datepicker.default").style.bottom = "-352px";
        setTimeout(() => {
            this.setState({
                show: false
            })
        },500);
    }

    handleSelect = (value) => {
        // this.setState({ value, show: false });
        this.getDateResult(value)
    }

    showCascader() {
        this.init();
        this.setState({
            show: true
        });
        setTimeout(() => {
            document.querySelector(".datepicker.default").style.bottom = "0px";
        },0);
    }
    getDateResult(value) {
        let date = new Date(value);
        this.handleCancel();
        this.props.getDateResult(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2,0) + "-" + date.getDate().toString().padStart(2, 0));
    }
    init() {
        this.state.activeIndex = 0;
        let dateValue = this.props.dateValue;
        let dateRange = this.props.dateRange;
        let min = dateRange[0] || "1950-01-01";
        let max = dateRange[1] || "2050-12-31";
        let defaultDate = this.props.defaultDate;
        
        if(dateValue){
            defaultDate = dateValue;
        } else{
            if(!defaultDate){
                let now = new Date();
                defaultDate = now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();
                if(now.getTime() < new Date(min).getTime()){
                    defaultDate = min;
                }
                if(now.getTime() > new Date(max).getTime()){
                    defaultDate = max;
                }
            }
        }
        min = min.split("-");
        max = max.split("-");
        defaultDate = defaultDate.split("-")
        this.setState({
            activeIndex: 0,
            min: new Date(min[0], min[1] - 1, min[2]),
            max: new Date(max[0], max[1] - 1, max[2]),
            value: new Date(defaultDate[0], defaultDate[1] - 1, defaultDate[2]),
        })
    }
    componentDidMount() {

    }
    render() {
        return (
            <DatePicker
                isOpen={this.state.show}
                customHeader={<div>选择日期</div>}
                min={this.state.min}
                max={this.state.max}
                value={this.state.value}
                dateConfig={this.state.dateConfig}
                onSelect={this.handleSelect.bind(this)}
                onCancel={this.handleCancel.bind(this)} />
        )
    }
}

export default Item;