import React from "react";
// import { CombinedDateRangePicker, Radio } from 'zent';
import Title from "./formItem/title";
import DateRange from "./formItem/dateRange";

// const RadioGroup = Radio.Group;
class Base extends React.Component {
    constructor(props) {
        super(props);

    }

    titleRef = React.createRef();
    dateRangeRef = React.createRef();

    state = {
        focusInAddMsg: <div className="notice">
            <p>选择“关注后参与”有诱导关注风险，请谨慎使用。若页面被封，请先将活动改为“无须关注公众号，即可参与”，然后在页面中申请恢复。
                查看</p>
            <div className="notice_link" onClick={this.goNotice.bind(this)}>《微信公众平台关于诱导关注的公告》</div>
        </div>,
        titleDist: false,
    }
    goNotice() {
        console.log(this);
    }

    titleChange(key, value) {
        this.props.baseData[key].value = value;
        this.props.updateBase(this.props.baseData);
    }
    setBaseDateRange(key,val){
        this.props.baseData.dateRange.value = val;
        this.props.updateBase(this.props.baseData);
    }
    loginInAddChange(e) {
        this.props.baseData.loginInAdd.value = e.target.value;
        this.props.updateBase(this.props.baseData);
    }
    focusInAddChange(e) {
        this.props.baseData.focusInAdd.value = e.target.value;
        this.props.updateBase(this.props.baseData);
    }
    render() {
        return (
            <div className="w1_base">
                <Title setValue={this.titleChange.bind(this)} item={this.props.baseData.title} ref={this.titleRef}></Title>
                <DateRange setValue={this.titleChange.bind(this)} item={this.props.baseData.dateRange} ref={this.dateRangeRef}></DateRange>
                {/* <div className="w1_form_item">
                    <div className="name_line">
                        <span>登录参与</span>
                    </div>
                    <RadioGroup onChange={this.loginInAddChange.bind(this)} value={this.props.baseData.loginInAdd.value}>
                        <Radio value="0">无须登录，可匿名参与</Radio>
                        <Radio value="1">登录后参与</Radio>
                    </RadioGroup>
                </div>
                <div className={`w1_form_item`}>
                    <div className="name_line">
                        <span>关注参与</span>
                    </div>
                    <RadioGroup onChange={this.focusInAddChange.bind(this)} value={this.props.baseData.focusInAdd.value}>
                        <Radio value="0">无须关注公众号，即可参与</Radio>
                        <Radio value="1">关注后参与</Radio>
                    </RadioGroup>
                    {this.props.baseData.focusInAdd.value == 1 && this.state.focusInAddMsg}
                </div> */}
            </div>
        )
    }
}

export default Base