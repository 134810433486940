import React from "react";

class Item extends React.Component {
    state = {
    }
    ref = React.createRef();

    componentDidMount() {
    }
    render() {
        return (
            <div className={`preview_module_wrapper text_preview ${this.props.item.formData.content.full == 1 ? 'full' : ""}`}
                ref={this.ref}
                style={{ background: this.props.item.formData.content.background_color }}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {this.props.item.formData.content.content && <pre className="preview_pre">
                    <code dangerouslySetInnerHTML={{ __html: this.props.item.formData.content.content }}></code>
                </pre>}
                {!this.props.item.formData.content.content &&
                    <div className="com-item active com-item--rich_text com-item-deletable" draggable="false">
                        <div className="cap-richtext cap-richtext--fullscreen">
                            <p>点此编辑『富文本』内容 ——&gt;</p>
                            <p>你可以对文字进行<strong>加粗</strong>、<em>斜体</em>、<span style={{ "textDecoration": "underline" }}>下划线</span>、
                                <span style={{ "textDecoration": "line-through" }}>删除线</span>、文字
                                <span style={{ "color": "rgb(0, 176, 240)" }}>颜色</span>、
                                <span style={{ "backgroundColor": "rgb(255, 192, 0)", "color": "rgb(255, 255, 255)" }}>背景色</span>、以及字号
                                <span style={{ "fontSize": "20px" }}>大</span><span style={{ "fontSize": "14px" }}>小</span>等简单排版操作。</p>
                            <p>还可以在这里加入表格了</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td width="93" valign="top">中奖客户</td>
                                        <td width="93" valign="top">发放奖品</td>
                                        <td width="93" valign="top">备注</td>
                                    </tr>
                                    <tr><td width="93" valign="top">猪猪</td>
                                        <td width="93" valign="top">内测码</td>
                                        <td width="93" valign="top">
                                            <em><span style={{ "color": "rgb(255, 0, 0)" }}>已经发放</span></em>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="93" valign="top">大麦</td>
                                        <td width="93" valign="top">积分</td>
                                        <td width="93" valign="top">
                                            <a href="#!" target="_blank" draggable="false">领取地址</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                <span>也可在这里插入图片、并对图片加上超级链接，方便用户点击。</span>
                            </p>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Item;