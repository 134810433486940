import React from "react";
import Base from "./base"
import Single from "./single"
import CheckboxModule from "./checkbox"
import InputModule from "./input"
import ImageModule from "./image"
import PhoneModule from "./phone"
import DateModule from "./date"
import TextModule from "./text"
import TextareaModule from "./textarea"
import End from "./end"
class AllModulesCom extends React.Component {
    constructor(props) {
        super(props)
    }
    baseRef = React.createRef();
    endRef = React.createRef();
    moduleRef = React.createRef();
    render() {
        let pages = this.props.allData.pages;
        let modules = [];
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].modules) {
                modules = modules.concat(pages[i].modules);
            }
        }

        let one = null;
        if (this.props.activeModule && this.props.allData.aMid === this.props.activeModule.id) {
            one = this.props.activeModule;
        } else {
            one = this.props.allData.base;
        }
        // modules.push(base);
        let showBase = true;
        modules.map(one => {
            if (this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) {
                showBase = false;
                return false;
            }
        })
        // return modules.map((one, index) => {
        let component = "";

        switch (one.type) {
            case "radio":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <Single activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        ref={this.moduleRef}
                    ></Single></div>
                break;
            case "checkbox":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <CheckboxModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></CheckboxModule></div>
                break;
            case "input":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <InputModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></InputModule></div>
                break;
            case "image":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <ImageModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></ImageModule></div>
                break;
            case "mobile":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <PhoneModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></PhoneModule></div>
                break;
            case "mail":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <PhoneModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></PhoneModule></div>
                break;
            case "date":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <DateModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></DateModule></div>
                break;
            case "area":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <PhoneModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></PhoneModule></div>
                break;
            case "text":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <TextModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></TextModule></div>
                break;
            case "textarea":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <TextareaModule activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        allData={this.props.allData}
                        ref={this.moduleRef}
                    ></TextareaModule></div>
                break;
            case "end":
                component = <div className={(this.props.allData.aMid === one.id && this.props.allData.aPid === one.pid) ? "active_module" : "hidden_module"} key={one.pid + "-" + one.id}>
                    <End activeModule={one}
                        updateRequire={this.props.updateRequire.bind(this)}
                        ref={this.endRef}
                    ></End></div>
                break;
            default:
                if (this.props.allData.aPid !== one.pid) {

                }
                component = <div className={showBase ? "active_module" : "hidden_module"} key={"base"}>
                    <Base baseData={one} updateBase={this.props.updateBase.bind(this)} ref={this.baseRef}></Base>
                </div>
                break;

        }
        return component;
        // })

    }
}
export default AllModulesCom