import React from "react";
import { getDetails } from "@/api/index";
import "./index.css";
import iaskPaperBg from "@/assets/images/paper/iask-paper-bg.jpg";
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {Icon,FullScreenLoading} from "zent";

import First from "./components/first";
import Question1 from "./components/question1";

class Answer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      questionNum: -1,
      direction: '',
      activeQuestion: {},
      questionType: "card",
      questionList: [],
      loading: false,
      transitionName: 'flideUp'
    }
  }
  getList(id) {
    this.setState({
      loading: true
    })
    getDetails({ id: id }).then(res => {
      console.log(res);
      if (res.data.code === 1) {
        let list = [];
        let pages = res.data.msg.pages;
        console.log(pages);
        for (let index = 0; index < pages.length; index++) {
          let module = pages[index].module;
          for (let j = 0; j < module.length; j++) {
            module[j].type = "card"
            list = list.concat(module[j]);
          }
        }
        this.setState({
          questionList: list
        })
        console.log(list);
      }
      this.setState({
        loading: false
      })
    }).catch(err => {
      this.setState({
        loading: false
      })
    })
  }

  answer(direction) {
    direction = direction || 'down';
    let questionNum = 0;
    if (direction === 'down') {
      this.setState({
        transitionName: 'flideUp'
      })
      questionNum = this.state.questionNum + 1;
    } else if (direction === 'up') {
      this.setState({
        transitionName: 'flideDown'
      })
      questionNum = this.state.questionNum - 1;
    }
    this.setState({
      questionNum: questionNum,
      activeQuestion: this.state.questionList[questionNum]
    })
  }

  componentDidMount() {
    let params = this.props.match.params;
    let id = params.id.substr(13)
    this.getList(id);
  }
  render() {
    return (
      <div className="iask-paper-wrapper" style={{ backgroundImage: "url(" + iaskPaperBg + ")" }}>
        <div className='iask-paper-list'>
        {/* <div className={`iask-paper-list ${this.state.direction == 'down' ? 'slideDown' : ''} ${this.state.direction == 'down2' ? 'slideDown2' : ''} ${this.state.direction == 'up' ? 'slideup' : ''}`}> */}
          <CSSTransitionGroup transitionName={this.state.transitionName}
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}>
            {this.state.questionNum === -1 && <First answer={this.answer.bind(this)} key="abc"></First>}
            {this.state.questionNum >= 0 && this.state.activeQuestion.type === "card" && <Question1 question={this.state.activeQuestion} key={this.state.activeQuestion.id}></Question1>}
          </CSSTransitionGroup>
        </div>
        <div className="iask-paper-under-line">
          <div className="iask-paper-under-line-left"></div>
          {this.state.questionNum >= 0 && <div className="iask-paper-under-line-right">
            <div className={`iask-paper-up ${this.state.questionNum > 0 ? '': 'disabled'}`} onClick={this.answer.bind(this,'up')}><Icon type="up-circle-o" /></div>
            <div className={`iask-paper-down ${this.state.questionNum === this.state.questionList.length - 1 ? 'disabled': ''}`} onClick={this.answer.bind(this,'down')}><Icon type="down-circle-o" /></div>
          </div>}
        </div>
        <FullScreenLoading loading={this.state.loading} icon="circle"/>
      </div>
    )
  }
}

export default Answer;