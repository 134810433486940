import React from "react";
import { Radio, Checkbox, Icon, Pop, Input, ImageUpload } from 'zent';
import quan1 from "@/assets/images/other/quan.png";
import ma1 from "@/assets/images/other/ma.png";
import score1 from "@/assets/images/other/score.png";
import success1 from "@/assets/images/other/success.png";
const RadioGroup = Radio.Group;
const CheckGroup = Checkbox.Group;


function removeCell(props) {
    if (props.mdStatus === "deleteInvalid" || props.mdStatus === "endDeleteInvalid" || props.mdStatus === "lastOne" || props.freezeOptionNum) {
        if (props.freezeOptionNum) {
            return ""
        } else {
            return (
                <Pop trigger="hover" position="top-left"
                    content={props.mdStatus === "deleteInvalid"
                        ? `此题设置了跳转，不能删除`
                        : `第${props.jumpFrom}题${props.jumpFromOption
                            ? `的第${props.jumpFromOption}个选项`
                            : ""}设置了跳转到此题，不能删除。`}>
                    <div className={`preview_del can_not_delete`}><Icon type="remove-o" /></div>
                </Pop>
            )
        }
    } else {
        return <div className={`preview_del`}><Icon type="remove-o" /></div>
    }
}
/**
 * 展示页模块
 */

/**
 * @description 单选题
 * @class SingleQuestion
 */
class SingleQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                {/* 选项 */}
                <div className="option_group_wrapper">
                    <RadioGroup onChange={this.onChange}>
                        {
                            this.props.item.formData.options.map(one => {
                                return <div className="option_wrapper" key={one.id}>
                                    <div className="option_title">
                                        <Radio value="0">{one.value ? one.value : (one.image ? "" : "选项")}
                                            {/* 选项跳转提示 */}
                                            <span className="jump_tips">
                                                {(one.jump.value.key != "0" && one.jump.value.key !== "-1") ? "（跳转到第" + one.jump.value.key + "题）" : ""}
                                                {one.jump.value.key === "-1" ? "（结束答题）" : ""}
                                            </span>
                                        </Radio>
                                        {one.fileList && one.fileList[0] &&
                                            <div className="thumbSrc_wrapper">
                                                <img src={one.fileList[0].thumbSrc}></img>
                                            </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                        {
                            this.props.item.formData.otherOption ? <div className="option_wrapper">
                                <div className="option_title other_radio_wrapper">
                                    <Radio value="0">
                                        <div className="other_wrapper">
                                            <span className="other_name">其他</span>
                                            <div className="other_content">{this.props.item.formData.otherOption.value}</div>
                                        </div>
                                    </Radio>
                                </div>
                            </div> : ""
                        }
                    </RadioGroup>
                </div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}


/**
 * @description 成功结束页
 * @class SuccessModule
 */
class SuccessModule extends React.Component {
    constructor(props) {
        super(props)
    }

    state = {
        choosePrizeList: [
            {
                id: "1",
                name: "优惠券",
                src: quan1
            },
            {
                id: "2",
                name: "优惠码",
                src: ma1
            },
            {
                id: "3",
                name: "积分",
                src: score1
            },
            {
                id: "4",
                name: "赠品",
                src: score1
            },
        ]
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        this.props.mouseDownHandler(module, ref, this.props.index, event);
    }

    render() {
        return (
            <div className="end_wrapper preview_module_wrapper"
                onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                <div className="end_inner">
                    <img src={success1}></img>
                    <p className="end_success_title">{this.props.item.formData.title.value || '提交成功'}</p>
                    <div className="end_btns">
                        {this.props.item.formData.seeResult.value === '1' && <div className="scan_result end_btn">查看结果</div>}
                        <div className="end_btn">{this.props.item.formData.custom.value || '进店逛逛'}</div>
                    </div>
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
                {this.props.item.formData.prize.value == 1 && <div className="prize_preview">
                    <p className="pp_title">恭喜你获得以下奖励</p>
                    <div className={`pp_list has_${(this.props.item.formData.choosePrize.value.length + (this.props.item.formData.choosePrize.options[2].score.value > 0 ? 1: 0))}`}>
                        {this.state.choosePrizeList.map((one, index) => {
                            return (
                                ((index != 2 && this.props.item.formData.choosePrize.value.includes(one.id))
                                    || (index == 2 && this.props.item.formData.choosePrize.options[2].score.value > 0))
                                && <div className="pp_one" key={one.id}>
                                    <div className="pp_left">
                                        <img className="pp_l_img" src={one.src}></img>
                                        <div className="pp_l_r">
                                            <div className="pp_name">{one.name}</div>
                                            {index != 2 && <div className="pp_content">{this.props.item.formData.choosePrize.options[index].selectText}</div>}
                                            {index == 2 && <div className="pp_content">{this.props.item.formData.choosePrize.options[2].score.value + "积分"}</div>}
                                        </div>
                                    </div>
                                    <div className="preview_scan">查看</div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
            </div>
        )
    }
}

// 多选题可选几项的组件
function GetCheckboxTips(props) {
    let text = "", formData = props.formData;
    if (formData.check_max.value.key == 0) {
        if (formData.check_min.value.key == 0) {
            text = "多选题";
        } else {
            text = "最少选择" + formData.check_min.value.key + "项";
        }
    } else {
        if (formData.check_min.value.key == 0) {
            text = "最多选择" + formData.check_max.value.key + "项";
        } else if (formData.check_min.value.key == formData.check_max.value.key) {
            text = "请选择" + formData.check_min.value.key + "项";
        } else {
            text = "请选择" + formData.check_min.value.key + "-" + formData.check_max.value.key + "项";
        }
    }
    return "(" + text + ")"
}
/**
 * @description 多选题
 * @class CheckboxQuestion
 */
class CheckboxQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }

    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                        <span className="checkbox_tips"><GetCheckboxTips formData={this.props.item.formData}></GetCheckboxTips></span>
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                {/* 选项 */}
                <div className="option_group_wrapper">
                    <CheckGroup onChange={this.onChange}>
                        {
                            this.props.item.formData.options.map(one => {
                                return <div className="option_wrapper" key={one.id}>
                                    <div className="option_title">
                                        <Checkbox value="0">{one.value ? one.value : (one.image ? "" : "选项")}
                                        </Checkbox>
                                        {one.fileList && one.fileList[0] &&
                                            <div className="thumbSrc_wrapper">
                                                <img src={one.fileList[0].thumbSrc}></img>
                                            </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                        {
                            this.props.item.formData.otherOption ? <div className="option_wrapper">
                                <div className="option_title other_radio_wrapper">
                                    <Checkbox value="0">
                                        <div className="other_wrapper">
                                            <span className="other_name">其他</span>
                                            <div className="other_content">{this.props.item.formData.otherOption.value}</div>
                                        </div>
                                    </Checkbox>
                                </div>
                            </div> : ""
                        }
                    </CheckGroup>
                </div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

/**
 * @description 填空题
 * @class InputQuestion
 */
class InputQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper">{this.props.item.formData.multi.value == "1" ? <Input /> : <Input type="textarea" />}</div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class ImageQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除*/}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper">
                    <ImageUpload />
                    <div className="image_title_des">请选择图片，仅支持jpg、gif、png，最多8张，单张图片不超过3MB</div>
                </div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class PhoneQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 不能删除的状态 deleteInvalid,endDeleteInvalid,lastOne*/}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "请输入手机号"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper"><Input className="mobile_input" /></div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class EmailQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "请输入邮箱"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper"><Input className="mail_input" /></div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class DateQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "请选择日期"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper"><Input className="date_input" /></div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class AreaQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                // onClick={this.props.activeModule.bind(this, this.props.item.id)}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                {removeCell(this.props.item)}
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "请选择城市"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper"><Input className="area_input" /></div>
                {/* 题目跳转提示 */}
                <div className="jump_tips">
                    {(this.props.item.formData.jump.value.key !== "0" && this.props.item.formData.jump.value.key !== "-1") ? "（跳转到第" + this.props.item.formData.jump.value.key + "题）" : ""}
                    {this.props.item.formData.jump.value.key === "-1" && "（结束答题）"}
                </div>
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class TextQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className={`preview_module_wrapper text_preview 
            ${this.props.item.formData.style_type == 1 ?
                    (this.props.item.formData.title.pos === "center" ? "center" : "left") :
                    (this.props.item.formData.desc.pos === "center" ?
                        "center" :
                        (this.props.item.formData.desc.pos === "right" ? "right" : "left"))}`}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}
                style={{ background: this.props.item.formData.title.background }}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                <div className={`preview_del`}><Icon type="remove-o" /></div>
                <div className="title_wrapper">
                    {/* 标题 */}
                    <div className="preview_text_name_line" style={{
                        color: this.props.item.formData.title.color,
                        fontWeight: this.props.item.formData.title.fontWeight,
                        fontSize: this.props.item.formData.title.fontSize + "px"
                    }}>
                        {this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题文本"}
                    </div>
                    {/* 题目描述 */}
                    <div className="preview_text_line"
                        style={{
                            color: this.props.item.formData.desc.color,
                            fontWeight: this.props.item.formData.desc.fontWeight,
                            fontSize: this.props.item.formData.desc.fontSize + "px"
                        }}>
                        {this.props.item.formData.style_type == "1" && this.props.item.formData.desc.value}
                        {
                            this.props.item.formData.style_type != "1" && (
                                <div className="wx_text_line">
                                    <span>{this.props.item.formData.date.value}</span>
                                    <span>{this.props.item.formData.author.value}</span>
                                    <span className="link_name">{this.props.item.formData.link_title.value}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
                {this.props.item.formData.more.show && this.props.item.formData.style_type == "1" && <div className={`show_more show_more_style_${this.props.item.formData.more.style}`}>
                    <p>查看更多</p><span>&gt;</span>
                </div>}
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}

class TextAreaQuestion extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
    }
    ref = React.createRef();

    mouseDownHandler(module, ref, event) {
        event.persist();
        let delBtn = ref.current.querySelector(".preview_del")
        if (event.target === delBtn || event.target.parentNode === delBtn) {
            if (this.props.item.mdStatus === "deleteInvalid" || this.props.item.mdStatus === "lastOne") {
                return false;
            }
            this.props.removeModule(this.props.item.id);
        } else {
            this.props.mouseDownHandler(module, ref, this.props.index, event);
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className={`preview_module_wrapper text_preview ${this.props.item.formData.content.full == 1 ? 'full' : ""}`}
                onMouseDown={this.mouseDownHandler.bind(this, this.props.item, this.ref)}
                ref={this.ref}
                style={{ background: this.props.item.formData.content.background_color }}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 删除 */}
                <div className={`preview_del`}><Icon type="remove-o" /></div>
                {this.props.item.formData.content.content && <pre className="preview_pre">
                    <code dangerouslySetInnerHTML={{ __html: this.props.item.formData.content.content }}></code>
                </pre>}
                {!this.props.item.formData.content.content &&
                    <div className="com-item active com-item--rich_text com-item-deletable" draggable="false">
                        <div className="cap-richtext cap-richtext--fullscreen">
                            <p>点此编辑『富文本』内容 ——&gt;</p>
                            <p>你可以对文字进行<strong>加粗</strong>、<em>斜体</em>、<span style={{ "textDecoration": "underline" }}>下划线</span>、
                                <span style={{ "textDecoration": "line-through" }}>删除线</span>、文字
                                <span style={{ "color": "rgb(0, 176, 240)" }}>颜色</span>、
                                <span style={{ "backgroundColor": "rgb(255, 192, 0)", "color": "rgb(255, 255, 255)" }}>背景色</span>、以及字号
                                <span style={{ "fontSize": "20px" }}>大</span><span style={{ "fontSize": "14px" }}>小</span>等简单排版操作。</p>
                            <p>还可以在这里加入表格了</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td width="93" valign="top">中奖客户</td>
                                        <td width="93" valign="top">发放奖品</td>
                                        <td width="93" valign="top">备注</td>
                                    </tr>
                                    <tr><td width="93" valign="top">猪猪</td>
                                        <td width="93" valign="top">内测码</td>
                                        <td width="93" valign="top">
                                            <em><span style={{ "color": "rgb(255, 0, 0)" }}>已经发放</span></em>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="93" valign="top">大麦</td>
                                        <td width="93" valign="top">积分</td>
                                        <td width="93" valign="top">
                                            <a href="#!" target="_blank" draggable="false">领取地址</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                <span>也可在这里插入图片、并对图片加上超级链接，方便用户点击。</span>
                            </p>
                        </div>
                    </div>
                }
                {/* 遮罩 */}
                <div className={["preview_mask", (this.props.aMid == this.props.item.id && "active")].join(" ")}></div>
            </div>
        )
    }
}
export {
    SingleQuestion,
    CheckboxQuestion,
    InputQuestion,
    ImageQuestion,
    PhoneQuestion,
    EmailQuestion,
    DateQuestion,
    AreaQuestion,
    TextQuestion,
    TextAreaQuestion,
    SuccessModule
}