import React from "react";
import Level from "./formItem/level";
import Title from "./formItem/title";
import CheckRequire from "./formItem/checkRequire";
import GiveObjects from "./formItem/giveObjects";
import JoinTimes from "./formItem/joinTimes";
import ChoosePrize from "./formItem/choosePrize";
class Base extends React.Component {
    constructor(props) {
        super(props);

    }
    joinTimesRef = React.createRef();
    giveObjectsRef = React.createRef();
    choosePrizeRef = React.createRef();
    urlRef = React.createRef();
    setValue(key, value, index) {
        this.props.activeModule.formData[key].value = value;
        if (key === "checkRequire") {
            var result = value === "1" ? true : false
            this.props.activeModule.required = result;
            this.props.activeModule.formData.options.map(one => {
                one.required = result;
                return one;
            })
        }
        this.props.updateRequire();
    }
    render() {
        return (
            <div className="w1_base">
                {/* 标题 */}
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.title}></Title>
                {/* 查看结果 */}
                <div className="single_line">查看结果</div>
                <Level setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.seeResult}></Level>
                {/* 自定义按钮 */}
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.custom}></Title>
                {/* 跳转链接 */}
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.url} ref={this.urlRef}></Title>
                {/* 参与次数 */}
                <JoinTimes setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.joinTimes} ref={this.joinTimesRef}></JoinTimes>
                {/* 参与奖励 */}
                {/* <div className="single_line">参与奖励</div> */}
                <CheckRequire setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.prize}></CheckRequire>
                {/* 指定客户群 */}
                {this.props.activeModule.formData.prize.value == "1"
                    && <GiveObjects setValue={this.setValue.bind(this)}
                        item={this.props.activeModule.formData.giveObjects}
                        ref={this.giveObjectsRef}></GiveObjects>}
                {/* 选择奖励 */}
                {this.props.activeModule.formData.prize.value == "1" 
                && <ChoosePrize setValue={this.setValue.bind(this)} 
                item={this.props.activeModule.formData.choosePrize}
                ref={this.choosePrizeRef}></ChoosePrize>}
            </div>
        )
    }
}

export default Base