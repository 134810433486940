import React from "react";
import { Radio,Tooltip } from 'zent';
import star1 from "@/assets/images/icons/star.png";
import tip1 from "@/assets/images/icons/tip.png";
const RadioGroup = Radio.Group;

/**
 * @description 是否有权限查看
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    stateChange(e) {
        e.persist();
        this.props.setValue(this.props.item.key, e.target.value)
    }
    stateBlur(){
        if(this.props.item.required){
            this.dist = true;
            this.setState({ value: 1 });
        }
    }

    render() {
        return (
            <div className={`w1_form_item sameline ${this.props.item.value? "fill": ""} ${this.dist? "dist": ''}`}>
                <div className="name_line">
                    <div className="power_title">
                        {this.props.item.required && <img src={star1}></img>}
                        <span>{this.props.item.title}</span>
                        <Tooltip centerArrow trigger="hover" title={this.props.item.tips} position="top-left" style={{"background":"#fff","color": "#333", "width": "200px"}}>
                            <div className="power_tip">
                                <img src={tip1}></img>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <RadioGroup onChange={this.stateChange.bind(this)} value={this.props.item.value}>
                    {this.props.item.options.map((one, index) => {
                        return <Radio value={one.key} key={index}>{one.value}</Radio>
                    })}
                </RadioGroup>
                {this.props.item.msg}
            </div>
        )
    }
}

export default Item