import React from 'react';
import "./index.css";
// import bannerImg from "@/assets/images/banner_01.jpg";
import logo from "@/assets/images/logo.png";
import card1 from "@/assets/images/card/card1.png";
import card2 from "@/assets/images/card/card2.png";
import card3 from "@/assets/images/card/card3.png";
import c1 from "@/assets/images/cos/c1.png";
import c2 from "@/assets/images/cos/c2.png";
import c3 from "@/assets/images/cos/c3.png";
import c4 from "@/assets/images/cos/c4.png";
import c5 from "@/assets/images/cos/c5.png";
import c6 from "@/assets/images/cos/c6.png";
import c7 from "@/assets/images/cos/c7.png";

import bannerBg from "@/assets/images/banner/bg.png";
import tag1 from "@/assets/images/banner/tag1.png";
import tag2 from "@/assets/images/banner/tag2.png";
import tag3 from "@/assets/images/banner/tag3.png";
import circle2 from "@/assets/images/banner/circle2.png";
import circle3 from "@/assets/images/banner/circle3.png";
import grid from "@/assets/images/banner/grid.png";
import iask from "@/assets/images/banner/iask.png";
import world from "@/assets/images/banner/world.png";
import square1 from "@/assets/images/banner/square1.png";
import square2 from "@/assets/images/banner/square2.png";
import paper from "@/assets/images/banner/paper.png";

import UserInfo from '@/mixins/getUserInfo';
import store from "@/redux/user"
import { Notify } from '_zent@9.12.1@zent';

export default class Home extends React.Component {
  consoleDesk() {
    console.log(store.userStore.getState());
    if(store.userStore.getState()){
      this.props.history.push("/list");
    } else {
      Notify.info("请先登录");
    }
  }
  state = {
    homeUrl: '',
    background: 'rgba(6,25,57,0)',
  }

  mixins = [UserInfo]

  scrollHandler(e) {
    let scrollTop = e.target.scrollingElement.scrollTop;
    if (scrollTop <= 954) {
      let opacity = scrollTop / 954;
      this.setState({
        background: "rgba(6,25,57," + opacity + "0)"
      })
    }
  }


  

  componentDidMount() {
    this.setState({
      homeUrl: "https://www.dongyi.shop"
    })
    window.addEventListener("scroll", this.scrollHandler.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollHandler);
  }
  render() {
    return (
      <div className="iask-home-wrapper">
        <header className="iask-home-header" style={{ background: this.state.background }}>
          <div className="iask-header-center">
            <div className="iask-header-center-left">
              <a href={this.state.homeUrl} className="iask-logo">
                <img src={logo} alt="iask-logo"></img>
              </a>
              <div className="iask-service">
                <a href="#!">产品服务</a>
                <a href="#!">解决方案</a>
              </div>
            </div>
            <div className="iask-header-center-right">
              <div className="iask-manage-center" onClick={this.consoleDesk.bind(this)}>管理中心</div>
              {/* <div className="iask-login" onClick={this.login.bind(this)}>登录</div>
              <div className="iask-register" onClick={this.register.bind(this)}>注册</div> */}
              <div style={{color: "#2381db"}}><UserInfo></UserInfo></div>
            </div>
          </div>
        </header>
        <div className="iask-banner">
          <div className="iask-banner-center">
            <img src={bannerBg} alt="iask-banner" draggable="false" className="iask-banner-bg"/>
            <div className="iask-layers">
                <img src={grid} className="iask-banner-item iask-banner-item1" draggable="false"/>
                <img src={square2} className="iask-banner-item iask-banner-item2" draggable="false"/>
                <img src={square1} className="iask-banner-item iask-banner-item3" draggable="false"/>
                <img src={circle3} className="iask-banner-item iask-banner-item4" draggable="false"/>
                <img src={circle2} className="iask-banner-item iask-banner-item5" draggable="false"/>
                <img src={iask} className="iask-banner-item iask-banner-item6" draggable="false"/>
                <img src={world} className="iask-banner-item iask-banner-item7" draggable="false"/>
                <img src={paper} className="iask-banner-item iask-banner-item8" draggable="false"/>
                <img src={tag1} className="iask-banner-item iask-banner-item9" draggable="false"/>
                <img src={tag2} className="iask-banner-item iask-banner-item10" draggable="false"/>
                <img src={tag3} className="iask-banner-item iask-banner-item11" draggable="false"/>
            </div>
          </div>
        </div>
        <div className="iask-part">
          <div className="iask-part-center">
            <div className="iask-part-title">特点</div>
            <div className="iask-part-sub-title">我们为您提供更丰富的体验，和更深度的服务。</div>
            <ul className="iask-card-ul">
              <li>
                <div className="iask-card-li-title">丰富的体验</div>
                <p>经典列表式表单</p>
                <p>动画表单</p>
                <p>对话式表单</p>
                <img className="iask-card-img" src={card1} alt="丰富的体验"></img>
              </li>
              <li>
                <div className="iask-card-li-title">完善的接口</div>
                <p>用户对接</p>
                <p>行为链路对接</p>
                <p>数据同步</p>
                <img className="iask-card-img" src={card2} alt="完善的接口"></img>
              </li>
              <li>
                <div className="iask-card-li-title">私有化部署</div>
                <p>程序一键部署</p>
                <p>数据私有化</p>
                <p>所有域名与标识自定义</p>
                <img className="iask-card-img" src={card3} alt="私有化部署"></img>
              </li>
            </ul>
          </div>
        </div>
        <div className="iask-part">
          <div className="iask-part-center">
            <div className="iask-part-title">我们的客户</div>
            <div className="iask-clients">
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c1} alt="our-client-1"></img>
              </span>
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c2} alt="our-client-2"></img>
              </span>
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c3} alt="our-client-3"></img>
              </span>
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c4} alt="our-client-4"></img>
              </span>
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c5} alt="our-client-5"></img>
              </span>
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c6} alt="our-client-6"></img>
              </span>
              <span href="#!" target="_blank" rel="noopener noreferrer">
                <img className="iask-client-img" src={c7} alt="our-client-7"></img>
              </span>
            </div>
          </div>
        </div>
        <div className="iask-footer">
          <div className="iask-footer-center">
            <p>冬一互动</p>
            <p>2021 iask.world, Inc. All rights reserved.  <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" style={{color: '#fff'}}>沪ICP备2020031663号-1</a></p>
          </div>
        </div>
        
        {/* <Dialog
          visible={this.state.loginVisible}
          onClose={() => this.setVisible(false)}
        >
          <LoginComponent></LoginComponent>
        </Dialog> */}
      </div>
    )
  }
}