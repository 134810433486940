import React from "react";
import { Icon } from "zent";

class Item extends React.Component {
    goPrev(){
        this.props.updatePIndex();
    }
    goNext(){
        this.props.checkCurrentPageQuestions("next");
    }
    submit(){
        this.props.checkCurrentPageQuestions("submit");
    }
    render() {
        return (
            <div className="w2_preview_end">
                <div className="w2_end_btns">
                    {this.props.pIndex > 0 && <div className="w2_end_btn page_up" onClick={this.goPrev.bind(this)}>上一页</div>}
                    {this.props.pIndex < (this.props.pLength - 1) && this.props.jumpLast != -1 && <div className="w2_end_btn" onClick={this.goNext.bind(this)}>下一页</div>}
                    {(this.props.pLength == 1 || this.props.pIndex == (this.props.pLength - 1) || this.props.jumpLast == -1) && <div className="w2_end_btn" onClick={this.submit.bind(this)}>提交</div>}
                </div>
                <div className="w2_end_ico">
                    <div className="w2_end_ico_bottom">冬一科技提供技术支持</div>
                </div>
            </div>
        )
    }
}

export default Item