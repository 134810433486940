import React from "react";
import {Link} from "react-router-dom";

class Item extends React.Component {
    state = {
    }
    ref = React.createRef();
    render() {
        return (
            <div className={`preview_module_wrapper text_preview 
            ${this.props.item.formData.style_type == 1 ?
                    (this.props.item.formData.title.pos === "center" ? "center" : "left") :
                    (this.props.item.formData.desc.pos === "center" ?
                        "center" :
                        (this.props.item.formData.desc.pos === "right" ? "right" : "left"))}`}
                ref={this.ref}
                style={{ background: this.props.item.formData.title.background }}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                <div className="title_wrapper">
                    {/* 标题 */}
                    <div className="preview_text_name_line" style={{
                        color: this.props.item.formData.title.color,
                        fontWeight: this.props.item.formData.title.fontWeight,
                        fontSize: this.props.item.formData.title.fontSize + "px"
                    }}>
                        {this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题文本"}
                    </div>
                    {/* 题目描述 */}
                    <div className="preview_text_line"
                        style={{
                            color: this.props.item.formData.desc.color,
                            fontWeight: this.props.item.formData.desc.fontWeight,
                            fontSize: this.props.item.formData.desc.fontSize + "px"
                        }}>
                        {this.props.item.formData.style_type == "1" && this.props.item.formData.desc.value}
                        {
                            this.props.item.formData.style_type != "1" && (
                                <div className="wx_text_line">
                                    <span>{this.props.item.formData.date.value}</span>
                                    <span>{this.props.item.formData.author.value}</span>
                                    <Link to={this.props.item.formData.wxUrl.value} className="link_name">{this.props.item.formData.link_title.value}</Link>
                                </div>
                            )
                        }
                    </div>
                </div>
                {this.props.item.formData.more.show && this.props.item.formData.style_type == "1" && <div className={`show_more show_more_style_${this.props.item.formData.more.style}`}>
                    <Link to={this.props.item.formData.moreUrl.value} style={{color: "inherit",display: "flex"}}><p>查看更多</p><span>&gt;</span></Link>
                </div>}
            </div>
        )
    }
}

export default Item;