import React, { Component } from 'react';
import './App.css';
// 引入路由内置组件
import { HashRouter } from 'react-router-dom'
// 引入加载路由视图得函数组件
import RouterView from './router/index'
// 引入路由配置表
import config from './router/config'
class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          {/* 加载/展示路由视图组件 */}
          <RouterView routes={config} />
        </HashRouter>
      </div>
    );
  }
}

export default App;
