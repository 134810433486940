//detail.js
import React from 'react';
import "./index.css"
import { Input, Select, Button, FullScreenLoading,Sweetalert } from 'zent';
import WjGrid from "@/pages/grid/grid";
import UserInfo from '@/mixins/getUserInfo';

const options = [
    {
        key: '0',
        text: '全部状态',
    },
    {
        key: '1',
        text: '未开始',
    },
    {
        key: '2',
        text: '进行中',
    },
    {
        key: '3',
        text: '已结束',
    },
    {
        key: '-1',
        text: '暂停中',
    },
];
export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.newPaper = this.newPaper.bind(this);
    }
    inputRef = React.createRef();
    gridRef = React.createRef();
    mixins = [UserInfo]

    state = {
        newName: "新建问卷",
        data: {},
        search_txt: '',
        status: {
            key: '0',
            text: '全部状态',
        },
        loading:false,
        updateVersion: '1.0.1',
        updateTime: '2021/10/10',
        updateText: <div>
            <p>1. 优化了设置跳题后中间的题不能删除的问题;</p>
            <p>2. 解决了某些情况下设置了跳题后仍然能够添加分页的bug;</p>
        </div>
    }
    setNewName(name) {
        this.setState({
            newName: name
        })
    }
    newPaper() {
        this.props.history.push("/newpaper")
    }

    onChangeStatus = e => {
        this.setState({ status: e });
        setTimeout(() => {
            this.gridRef.current.getData();
        });
    }
    searchPress = () => {
        this.setState({ search_txt: this.inputRef.current.input.value });
        setTimeout(() => {
            this.gridRef.current.getData();
        },0)
    }

    onIconClick = () => {
        this.searchPress();
    }

    getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return false;
    }

    updateVersionSet(){
        let updateVersion = localStorage.getItem("wj_update_version");
        if(!updateVersion && updateVersion !== this.state.updateVersion){
            localStorage.setItem("wj_update_version", this.state.updateVersion);
            if(new Date(this.state.updateTime).getTime() + 3 * 24 * 60 *60 * 1000 > new Date().getTime()){
                Sweetalert.alert({
                    title: "本次更新内容",
                    content: this.state.updateText,
                    parentComponent: this
                });
            }
        }
    }

    backHome(){
        this.props.history.push("/");
    }

    componentDidMount() {
    }
    render() {
        return (
            <div className="wj_wrapper_container">
                <FullScreenLoading loading={this.state.loading} />
                <div className="wj_wrapper">
                    <div className="wj_title_1 ffpm">
                        <span>问卷调查</span>
                        <div className="iask-right-bar">
                            <span className="iask-back-home" onClick={this.backHome.bind(this)}>返回首页</span>
                            <UserInfo></UserInfo>
                        </div>
                    </div>
                    <div className="wj_search">
                        <Button type="primary" onClick={this.newPaper}>新建问卷</Button>
                        <div className="wj_search_right">
                            <Select options={options} placeholder="全部状态" onChange={this.onChangeStatus} value={this.state.status} className="wj_select" />
                            <Input
                                icon="search"
                                onIconClick={this.onIconClick}
                                placeholder="搜索问卷名称"
                                onPressEnter={this.searchPress}
                                ref={this.inputRef}
                            />
                        </div>
                    </div>
                    <div className="wj_table_wrapper" id="wj_table_wrapper">
                        <WjGrid ref={this.gridRef}
                            newName={this.state.newName}
                            data={this.state.data}
                            setNewName={this.setNewName.bind(this)}
                            history={this.props.history}
                            search_txt={this.state.search_txt}
                            status={this.state.status}
                        ></WjGrid>
                    </div>
                </div>
            </div>
        )
    }
}