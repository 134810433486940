import request from "@/utils/request";

// 登录
export function login(data) {
  return request({
    url: "/api/user/login",
    method: "post",
    data: data
  })
}

// 退出登录
export function logout(data) {
  return request({
    url: "/api/user/logout",
    method: "post",
    data: data
  })
}

// 注册
export function register(data) {
  return request({
    url: "/api/user/register",
    method: "post",
    data: data
  })
}

// 获取验证码
export function getSmsCode(data) {
  return request({
    url: "/api/sms/send",
    method: "post",
    data: data
  })
}

// 校验验证码
export function checkSmsCode(data) {
  return request({
    url: "/api/sms/check",
    method: "post",
    data: data
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/api/user/index",
    method: "post",
    data: data
  })
}
