import global from '@/utils/config';
import React from 'react';
const dataFactory = {
    reverseOrderData: function (data, callback) {
        let reverseData = JSON.parse(JSON.stringify(data));
        let base = {
            title: {
                required: true,
                key: "title",
                value: data.name,
                title: "问卷名称",
                notsameline: true,
                placeholder: "20个字以内",
                msg: "请输入20个字以内的问卷名称"
            },
            dateRange: {
                required: true,
                key: "dateRange",
                notsameline: true,
                value: [data.begin_time.substring(0, 10), data.end_time.substring(0, 10)],
                msg: "请选择有效期",
                title: "有效期"
            },
            loginInAdd: {
                required: false,
                value: data.is_login
            },
            focusInAdd: {
                required: false,
                value: data.is_follow,
            }
        };
        reverseData.base = base;
        let editType = data.editType;

        if (editType == 2) {
            reverseData.id = 0;
        }
        let pages = [];
        for (let i = 0; i < data.pages.length; i++) {
            const page = data.pages[i];
            let newPage = {
                id: page.id,
                weigh: page.weigh,
                modules: []
            };
            if (editType != 2) {
                newPage.originId = page.id;
                newPage.qid = page.qid;
            } else {
                newPage.qid = 0;
            }
            for (let j = 0; j < page.module.length; j++) {
                const mo = page.module[j];
                let nm = {};
                nm = JSON.parse(JSON.stringify(mo));
                if (editType != 2) {
                    nm.originId = nm.id;
                }

                switch (mo.type) {
                    case "radio":
                    case "checkbox":
                        if (mo.type === "radio") {
                            nm.title = "单选题";
                            nm.formData = JSON.parse(JSON.stringify(global.radio));
                        }
                        if (mo.type === "checkbox") {
                            nm.title = "多选题";
                            nm.formData = JSON.parse(JSON.stringify(global.checkbox));
                            nm.formData.check_max.value.key = mo.check_max;
                            nm.formData.check_min.value.key = mo.check_min;
                            nm.formData.value = [];
                        }

                        nm.formData.options = [];
                        for (let k = 0; k < mo.list.length; k++) {
                            const op = mo.list[k];
                            let newO = null;
                            if (op.type == 0) {
                                newO = Object.assign(JSON.parse(JSON.stringify(global.option)), op);
                                op.image && (newO.fileList = [{
                                    thumbSrc: op.image
                                }])
                                if (mo.type === "radio") {
                                    newO.jump = JSON.parse(JSON.stringify(global.option.jump));
                                    newO.jump.value.key = op.jump + "";
                                    newO.jump.value.text = "";
                                }
                                if (editType != 2) {
                                    newO.originId = newO.id;
                                }
                                newO.tags = JSON.parse(JSON.stringify(global.option.tags));
                                newO.tags.value = JSON.parse(op.tags);
                                newO.tags.value.map(t => {
                                    t.key = t.id;
                                    return t;
                                })
                                nm.formData.options.push(newO);
                            } else {
                                newO = Object.assign(JSON.parse(JSON.stringify(global.otherOption)), op);
                                if (editType != 2) {
                                    newO.originId = newO.id;
                                }
                                newO.tags = JSON.parse(JSON.stringify(global.option.tags));
                                newO.tags.value = JSON.parse(op.tags);
                                newO.tags.value.map(t => {
                                    t.key = t.id;
                                    return t;
                                })
                                nm.formData.otherOption = newO;
                                delete nm.formData.otherOption.jump;
                            }
                        }
                        break;
                    case "input":
                        nm.title = "填空题";
                        nm.formData = JSON.parse(JSON.stringify(global.input));
                        nm.formData.multi.value = mo.multi;
                        nm.formData.data_type.value.key = mo.data_type;
                        break;
                    case "image":
                        nm.title = "图片题";
                        nm.formData = JSON.parse(JSON.stringify(global.image));
                        nm.formData.button_txt.value = mo.button_txt;
                        break;
                    case "mobile":
                        nm.title = "手机号";
                        nm.formData = JSON.parse(JSON.stringify(global.mobile));
                        break;
                    case "mail":
                        nm.title = "邮箱";
                        nm.formData = JSON.parse(JSON.stringify(global.mail));
                        break;
                    case "area":
                        nm.title = "省/市/区";
                        nm.formData = JSON.parse(JSON.stringify(global.area));
                        break;
                    case "date":
                        nm.title = "日期";
                        nm.formData = JSON.parse(JSON.stringify(global.date));
                        nm.formData.defaultDate.value = mo.default_date;
                        if (mo.begin_date && mo.end_date) {
                            nm.formData.dateRange.value = [mo.begin_date, mo.end_date]
                        }
                        break;
                    case "text":
                        nm.title = "标题文本";
                        nm.formData = JSON.parse(JSON.stringify(global.text));
                        nm.formData.style_type = mo.style_type;
                        nm.formData.title.value = mo.title;
                        nm.formData.title.fontSize = mo.title_size;
                        nm.formData.title.fontWeight = (mo.title_bold == 1 ? "bold" : "normal");
                        nm.formData.title.color = mo.title_color;
                        nm.formData.title.pos = mo.pos;
                        nm.formData.title.background = mo.background_color;
                        nm.formData.title.line = (mo.line == 1);

                        nm.formData.desc.value = mo.des;
                        nm.formData.desc.fontSize = mo.des_size;
                        nm.formData.desc.fontWeight = (mo.des_bold == 1 ? "bold" : "normal");
                        nm.formData.desc.color = mo.des_color;
                        nm.formData.desc.pos = mo.wx_style;

                        nm.formData.more.show = (mo.more == 1);
                        nm.formData.more.style = mo.more_style;
                        nm.formData.more.value = mo.more_text;
                        nm.formData.moreUrl.value = mo.url;
                        nm.formData.wxUrl.value = mo.wx_url;
                        nm.formData.author.value = mo.author;
                        nm.formData.date.value = mo.date;
                        nm.formData.link_title.value = mo.link_title;
                        break;
                    case "textarea":
                        nm.title = "富文本";
                        nm.formData = JSON.parse(JSON.stringify(global.textarea));
                        nm.formData.content.background_color = mo.background_color;
                        nm.formData.content.content = mo.content;
                        nm.formData.content.full = mo.full;
                        break;
                    default:
                        break;
                }
                if (mo.type !== "text" && mo.type !== "textarea") {
                    nm.formData.title.value = mo.title;
                    nm.formData.desc.value = mo.des;
                    nm.formData.checkRequire.value = mo.required;
                    nm.formData.level.value = mo.level;
                    nm.formData.jump.value.key = mo.jump;
                    nm.formData.jump.value.text = "";
                    if (mo.type !== "radio" && mo.type !== "checkbox") {
                        nm.formData.tags = JSON.parse(JSON.stringify(global.input.tags));
                        if (mo.tags == "null" || mo.tags == "[]" || mo.tags == [] || /"*(null|\[\])"*/.test(mo.tags)) {
                            nm.formData.tags.value = [];
                        } else {
                            nm.formData.tags.value = JSON.parse(mo.tags) || [];
                        }
                    }
                }
                newPage.modules.push(nm);
            }
            pages.push(newPage);
        }
        let end = {};
        end.id = global.maxPid;
        if (editType != 2) {
            end.originId = data.end.id;
            end.qid = data.id;
        } else {
            end.qid = 0;
        }
        end.tab = <span>结束页</span>;
        end.weigh = pages.length;
        end.modules = [];
        let endModule = {
            id: global.maxPid,
            pid: global.maxPid,
            required: true,
            title: "提交成功",
            type: "end"
        };

        let endFormData = JSON.parse(JSON.stringify(global.end));
        endFormData.title.value = data.end.title;
        endFormData.url.value = data.end.url;
        endFormData.custom.value = data.end.custom;
        endFormData.seeResult.value = data.end.seeResult;
        endFormData.joinTimes.value = data.end.joinTimes;
        endFormData.joinTimes.day = data.end.day;
        endFormData.joinTimes.number = data.end.number;

        endFormData.prize.value = data.end.prize;
        endFormData.giveObjects.value = data.end.giveObjects;
        endFormData.giveObjects.special.value = JSON.parse(JSON.stringify(data.end.giveTags)) || [];
        endFormData.choosePrize.value = [];
        if (data.end.coupons && data.end.coupons.length && data.end.coupons[0].id) {
            endFormData.choosePrize.value.push("1");
            endFormData.choosePrize.options[0].selectId = data.end.coupons[0].id;
            endFormData.choosePrize.options[0].selectText = data.end.coupons[0].title;
        }
        if (data.end.promoCode && data.end.promoCode.length && data.end.promoCode[0].id) {
            endFormData.choosePrize.value.push("2")
            endFormData.choosePrize.options[1].selectId = data.end.promoCode[0].id;
            endFormData.choosePrize.options[1].selectText = data.end.promoCode[0].title;
        }
        if (data.end.present && data.end.present.length && data.end.present[0].id) {
            endFormData.choosePrize.value.push("4")
            endFormData.choosePrize.options[3].selectId = data.end.present[0].id;
            endFormData.choosePrize.options[3].selectText = data.end.present[0].title;
        }
        endFormData.choosePrize.options[2].score.value = data.end.point;
        endModule.formData = endFormData;
        end.modules.push(endModule);
        end.hasSuccess = true;

        pages.push(end);
        reverseData.pages = pages;

        reverseData.aMid = "-1";
        reverseData.aMIndex = 0;
        reverseData.aPid = 1;
        reverseData.aPIndex = 0;
        callback && callback(reverseData);
    },
    putDataInOrder(data) {
        let orderData = JSON.parse(JSON.stringify(data));
        let endPage = orderData.pages[orderData.pages.length - 1];

        let base = orderData.base;
        orderData.name = base.title.value;
        orderData.begin_time = base.dateRange.value[0];
        orderData.end_time = base.dateRange.value[1];
        orderData.is_follow = base.focusInAdd.value;
        orderData.is_login = base.loginInAdd.value;
        delete orderData.base;

        orderData.pages.map((p, index) => {
            if (index === orderData.pages.length - 1) {
                return false;
            }
            delete p.content;
            delete p.tab;
            if (p.originId) {
                p.id = p.originId;
            } else {
                p.id = 0
            }
            if (p.modules && p.modules.length) {
                p.modules.map(m => {
                    let formData = m.formData;
                    if (m.type === "radio" || m.type === "checkbox") {
                        formData.options && (m.list = formData.options.map(o => {
                            let option = {};
                            option = JSON.parse(JSON.stringify(o));
                            // 单选题的选项有跳题，多选没有
                            if (m.type === "radio") {
                                option.jump = option.jump.value.key;
                            } else {
                                option.jump = '';
                            }
                            if (option.originId) {
                                option.id = option.originId;
                            } else {
                                option.id = 0;
                            }
                            option.tags = option.tags.value;
                            // option.fileList && option.fileList.length && (option.image = option.fileList[0].thumbSrc);
                            if(!option.image){
                                option.image = "";
                            }
                            delete option.key;
                            delete option.placeholder;
                            delete option.width;
                            delete option.required;
                            delete option.fileList;
                            return option;
                        }));
                        if (formData.otherOption) {
                            let otherOption = {
                                tags: formData.otherOption.tags.value,
                                value: formData.otherOption.value,
                                image: '',
                                jump: '',
                                type: 1
                            }
                            if (m.id) {
                                otherOption.mid = m.id;
                            } else {
                                otherOption.mid = 0;
                            }
                            if (formData.otherOption.originId) {
                                otherOption.id = formData.otherOption.originId;
                            } else {
                                otherOption.id = 0;
                            }
                            m.list.push(otherOption);
                        }
                        // 多选有最多最少
                        if (m.type === "checkbox") {
                            m.check_max = formData.check_max.value.key;
                            m.check_min = formData.check_min.value.key;
                        }
                    } else if (m.type === "input") {
                        //  填空题
                        formData.multi && (m.multi = formData.multi.value);
                        formData.data_type && (m.data_type = formData.data_type.value.key);
                    } else if (m.type === "image") {
                        formData.button_txt && (m.button_txt = formData.button_txt.value);
                        m.data_type = ''
                    } else if (m.type === "date") {
                        m.default_date = (formData.defaultDate && formData.defaultDate.value);
                        if (formData.dateRange && formData.dateRange.value[0] && formData.dateRange.value[1]) {
                            m.begin_date = formData.dateRange.value[0];
                            m.end_date = formData.dateRange.value[1];
                        } else {
                            m.begin_date = '';
                            m.end_date = '';
                        }
                    } else if (m.type === "text") {
                        m.style_type = formData.style_type;
                        m.title = formData.title.value;
                        m.des = formData.desc.value;
                        m.pos = formData.title.pos;
                        m.title_size = formData.title.fontSize;
                        m.des_size = formData.desc.fontSize;
                        m.title_bold = (formData.title.fontWeight == "bold" ? 1 : 0);
                        m.des_bold = (formData.desc.fontWeight == "bold" ? 1 : 0);
                        m.title_color = formData.title.color;
                        m.des_color = formData.desc.color;
                        m.background_color = formData.title.background;
                        m.line = (formData.title.line ? 1 : 0);
                        m.more = (formData.more.show ? 1 : 0);
                        m.more_style = formData.more.style;
                        m.more_text = formData.more.value || "查看更多";
                        m.url = formData.moreUrl.value;
                        m.date = formData.date.value;
                        m.author = formData.author.value;
                        m.link_title = formData.link_title.value;
                        m.wx_style = formData.desc.pos;
                        m.wx_url = formData.wxUrl.value;
                    } else if (m.type === "textarea") {
                        m.background_color = formData.content.background_color;
                        m.full = formData.content.full;
                        m.content = formData.content.content;
                    }
                    if (m.type !== "radio" && m.type !== "checkbox") {
                        formData.tags && (m.tags = formData.tags.value);
                    }
                    if (!m.tags || /"*(null|\[\])"*/.test(m.tags)) {
                        m.tags = [];
                    }

                    // 所有题共有的属性
                    if (m.type != "text" && m.type != "textarea") {
                        formData.title && (m.title = formData.title.value);
                        formData.desc && (m.des = formData.desc.value);
                        formData.jump && (m.jump = formData.jump.value.key);
                        formData.level && (m.level = formData.level.value);
                        formData.checkRequire && (m.required = formData.checkRequire.value);
                    }
                    if (m.originId) {
                        m.id = m.originId
                    } else {
                        m.id = 0;
                    }
                    if (p.id) {
                        m.pid = p.id;
                    } else {
                        m.pid = 0;
                    }
                    delete m.formData;
                    return m;
                })
            } else {
                p.modules = [];
            }
            p.module = JSON.parse(JSON.stringify(p.modules));
            delete p.modules;
            return p;
        })
        orderData.pages = orderData.pages.slice(0, orderData.pages.length - 1);


        let endModule = endPage.modules && endPage.modules[0] && endPage.modules[0].formData;
        if (endModule) {
            let url = endModule.url.value || "";
            if (url.indexOf("page") === 0) {
                url = "/" + url;
            }
            let end = {
                "id": endPage.originId || 0,
                "qid": endPage.originId ? endPage.qid : 0,
                "title": (endModule.title.value || "提交成功"),
                "url": url,
                "seeResult": endModule.seeResult.value,
                "seeResultTitle": (endModule.seeResult.text || "查看结果"),
                "custom": (endModule.custom.value || "进店逛逛"),
                "joinTimes": endModule.joinTimes.value,
                "day": endModule.joinTimes.day,
                "number": endModule.joinTimes.number,
                "prize": endModule.prize.value,
                "giveObjects": endModule.giveObjects.value,
                "giveTags": endModule.giveObjects.special.value,
                "point": endModule.choosePrize.options[2].score.value,
                "choosePrize": endModule.choosePrize.value
            }
            if (endModule.choosePrize.options[0].selectId) {
                end.coupons = [
                    {
                        "id": endModule.choosePrize.options[0].selectId,
                        "title": endModule.choosePrize.options[0].selectText
                    },
                ]
            } else {
                end.coupons = [];
            }
            if (endModule.choosePrize.options[1].selectId) {
                end.promoCode = [
                    {
                        "id": endModule.choosePrize.options[1].selectId,
                        "title": endModule.choosePrize.options[1].selectText
                    },
                ]
            } else {
                end.promoCode = [];
            }
            if (endModule.choosePrize.options[3].selectId) {
                end.present = [
                    {
                        "id": endModule.choosePrize.options[3].selectId,
                        "title": endModule.choosePrize.options[3].selectText
                    },
                ]
            } else {
                end.present = [];
            }
            orderData.end = end;
        }
        return orderData;
    },
    formatSaveData(data, callback) {
        let orderData = {
            "list": []
        };
        orderData.qid = data.id;
        orderData.authority_id = '';
        orderData.yz_open_id = '';
        let pages = data.pages;
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            for (let j = 0; j < page.modules.length; j++) {
                const module = page.modules[j];
                const type = module.type;
                let listItem = {
                    mid: module.id,
                    type: type
                }
                if(type === 'text' || type === 'textarea'){
                    continue;
                }
                const value = module.formData.value;
                if (type == "mail" || type == "mobile" || type == "area" || type == "date" || type == "input") {
                    if (value) {
                        listItem.value = value;
                        orderData.list.push(listItem);
                    }
                } else if (type == "image") {
                    let urlList = [];
                    if (value.length) {
                        for (let k = 0; k < value.length; k++) {
                            urlList.push(value[k].url)
                        }
                        listItem.value = urlList;
                        orderData.list.push(listItem);
                    }
                } else if (type == "radio") {
                    if (value) {
                        listItem.value = this.getOneOptionData(value, module.formData);
                        orderData.list.push(listItem);
                    }
                } else if (type == "checkbox") {
                    if (value.length) {
                        let valueArray = [];
                        for (let k = 0; k < value.length; k++) {
                            let one = this.getOneOptionData(value[k],module.formData);
                            valueArray.push(one);
                        }
                        listItem.value = valueArray
                        orderData.list.push(listItem);
                    }
                }
            }

        }
        // orderData.list = JSON.stringify(orderData.list);
        callback && callback(orderData);
    },
    getOneOptionData(id, formData) {
        let one = {
            id: id,
        }
        if(formData.otherOption){
            let oId = formData.otherOption.id
            if (id == oId) {
                one.type = "other";
                if(formData.otherValue){
                    one.text = formData.otherValue;
                }
            } else {
                one.type = "normal";
            }
        } else {
            one.type = "normal";
        }
        
        return one;
    }
}
export default dataFactory;