import React from "react";
import { Radio,Pop } from 'zent';
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;

/**
 * @description 是否有权限查看
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    stateChange(e) {
        e.persist();
        this.props.setAttr(this.props.key1, this.props.key2 ,e.target.value)
    }

    render() {
        return (
            <div className={`w1_form_item sameline text_line`}>
                <div className="name_line">
                    <span>{this.props.title}</span>
                    <span className="line_value">{this.props.options[this.props.options.findIndex(f => { return f.key == this.props.value })].value}</span>
                </div>
                <RadioGroup onChange={this.stateChange.bind(this)} value={this.props.value}>
                    {this.props.options.map((one) => {
                        return <Pop trigger={"hover"} position="top-center" content={one.value} key={one.key}>
                            {this.props.key2 === "fontSize" && <RadioButton value={one.key} style={{ fontSize: one.key + "px", fontWeight: "bold", width: "50px" }}>{one.text}</RadioButton>}
                            {this.props.key2 === "fontWeight" && <RadioButton value={one.key} style={{ fontSize: "16px", fontWeight: one.key, width: "50px" }}>{one.text}</RadioButton>}
                            {this.props.key2 === "pos" && <RadioButton value={one.key} style={{ fontSize: "16px", fontWeight: "bold", width: "50px" }}>{one.text}</RadioButton>}
                        </Pop>
                    })}
                </RadioGroup>
            </div>
        )
    }
}

export default Item