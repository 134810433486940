import React from "react";
import { Radio, previewImage } from 'zent';
const RadioGroup = Radio.Group;

class Item extends React.Component {
    state = {
    }
    ref = React.createRef();
    otherRef = React.createRef();
    onChange = e => {
        this.props.item.formData.value = e.target.value;

        let options = this.props.item.formData.options;
        let jump = "";
        for (let i = 0; i < options.length; i++) {
            const key = options[i].jump.value.key;
            if (key && key != "0") {
                if (options[i].id == e.target.value) {
                    jump = key;
                }
            }
        }
        let questionJumpKey = this.props.item.formData.jump.value.key;
        if (questionJumpKey) {
            if (!jump) {
                jump = questionJumpKey;
            }
        }
        if (jump) {
            this.props.questionChange(this.props.item, jump)
        } else {
            this.props.updateResult();
        }
        if (this.props.item.formData.otherOption && this.props.item.formData.otherOption.id === e.target.value) {
            setTimeout(() => {
                this.otherRef.current.focus();
            }, 0);
        }
    };
    handlePreview = (index, e) => {
        let imgArr = [];
        this.props.item.formData.options[index].fileList.map(one => {
            imgArr.push(one.thumbSrc);
            return one;
        })
        previewImage({
            images: imgArr,
            index: 0,
            parentComponent: this,
            scaleRatio: 3
        });
    }
    otherBlur() {
        this.props.item.formData.otherOption.value = this.otherRef.current.innerText;
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                ref={this.ref}>
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                {/* 选项 */}
                <div className="option_group_wrapper">
                    <RadioGroup onChange={this.onChange.bind(this)} value={this.props.item.formData.value}>
                        {
                            this.props.item.formData.options.map((one, index) => {
                                return <div className="option_wrapper" key={one.id}>
                                    <div className="option_title">
                                        <Radio value={one.id}>{one.value ? one.value : (one.image ? "" : "选项")}
                                        </Radio>
                                        {one.fileList && one.fileList[0] &&
                                            <div className="thumbSrc_wrapper">
                                                <img src={one.fileList[0].thumbSrc} onClick={this.handlePreview.bind(this, index)} alt="单选选项图片"></img>
                                            </div>
                                        }
                                    </div>

                                </div>
                            })
                        }
                        {
                            this.props.item.formData.otherOption ? <div className="option_wrapper">
                                <div className="option_title other_radio_wrapper">
                                    <Radio value={this.props.item.formData.otherOption.id}>
                                        <div className="other_wrapper">
                                            <span className="other_name">其他</span>
                                        </div>
                                    </Radio>
                                    <div className="other_div" ref={this.otherRef} style={{
                                        display: (this.props.item.formData.otherOption.id === this.props.item.formData.value) ? "block" : "none"
                                    }} 
                                    onBlur={this.otherBlur.bind(this)}
                                    >{this.props.item.formData.otherOption.value}</div>
                                    <div className="only_line" style={{
                                        display: (this.props.item.formData.otherOption.id === this.props.item.formData.value) ? "none" : "block"
                                    }}></div>
                                </div>
                            </div> : ""
                        }
                    </RadioGroup>
                </div>
            </div>
        )
    }
}

export default Item;