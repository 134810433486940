import React from "react";
import { Checkbox, previewImage } from 'zent';
const CheckGroup = Checkbox.Group;
// 多选题可选几项的组件
function GetCheckboxTips(props) {
    let text = "", formData = props.formData;
    if (formData.check_max.value.key == 0) {
        if (formData.check_min.value.key == 0) {
            text = "多选题";
        } else {
            text = "最少选择" + formData.check_min.value.key + "项";
        }
    } else {
        if (formData.check_min.value.key == 0) {
            text = "最多选择" + formData.check_max.value.key + "项";
        } else if (formData.check_min.value.key == formData.check_max.value.key) {
            text = "请选择" + formData.check_min.value.key + "项";
        } else {
            text = "请选择" + formData.check_min.value.key + "-" + formData.check_max.value.key + "项";
        }
    }
    return "(" + text + ")"
}
class Item extends React.Component {
    state = {
    }
    ref = React.createRef();
    otherRef = React.createRef();
    onChange = e => {
        this.props.item.formData.value = e;
        this.props.questionChange(this.props.item);
    };
    handlePreview = (index, e) => {
        let imgArr = [];
        this.props.item.formData.options[index].fileList.map(one => {
            imgArr.push(one.thumbSrc)
            return imgArr;
        })
        previewImage({
            images: imgArr,
            index: 0,
            parentComponent: this,
            scaleRatio: 3
        });
    }
    otherClick(){
        setTimeout(() => {
            this.otherRef.current.focus();
        },0)
    }
    otherBlur() {
        this.props.item.formData.otherOption.value = this.otherRef.current.innerText;
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                ref={this.ref}>
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                        <span className="checkbox_tips"><GetCheckboxTips formData={this.props.item.formData}></GetCheckboxTips></span>
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                {/* 选项 */}
                <div className="option_group_wrapper">
                    <CheckGroup onChange={this.onChange.bind(this)} value={this.props.item.formData.value}>
                        {
                            this.props.item.formData.options.map((one, index) => {
                                return <div className="option_wrapper" key={one.id}>
                                    <div className="option_title">
                                        <Checkbox value={one.id}>{one.value ? one.value : (one.image ? "" : "选项")}
                                        </Checkbox>
                                        {one.fileList && one.fileList[0] &&
                                            <div className="thumbSrc_wrapper">
                                                <img src={one.fileList[0].thumbSrc} onClick={this.handlePreview.bind(this, index)} alt="多选题选项图片"></img>
                                            </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                        {
                            this.props.item.formData.otherOption ? <div className="option_wrapper">
                                <div className="option_title other_radio_wrapper">
                                    <Checkbox value={this.props.item.formData.otherOption.id} onClick={this.otherClick.bind(this)}>
                                        <div className="other_wrapper">
                                            <span className="other_name">其他</span>
                                        </div>
                                    </Checkbox>
                                    <div className="other_div" ref={this.otherRef} style={{
                                        display: (this.props.item.formData.value && this.props.item.formData.value.includes(this.props.item.formData.otherOption.id)) ? "block" : "none"
                                    }} 
                                    onBlur={this.otherBlur.bind(this)}
                                    >{this.props.item.formData.otherOption.value}</div>
                                    <div className="only_line" style={{
                                        display: (this.props.item.formData.value && this.props.item.formData.value.includes(this.props.item.formData.otherOption.id)) ? "none" : "block"
                                    }}></div>
                                </div>
                            </div> : ""
                        }
                    </CheckGroup>
                </div>
                {
                    (this.props.item.formData.value && !(this.props.item.formData.value.length == 0 && this.props.item.formData.checkRequire.value == 0) && (this.props.item.check_min > 0 && this.props.item.check_max > 0)
                    && (this.props.item.formData.value.length < this.props.item.check_min || this.props.item.formData.value.length > this.props.item.check_max))
                    && <div className="preview_regex_check_tips">{"请选择" + this.props.item.check_min + "-" + this.props.item.check_max + "项，当前已选" + this.props.item.formData.value.length + "项"}</div>}
                {
                    this.props.item.formData.value && !(this.props.item.formData.value.length == 0 && this.props.item.formData.checkRequire.value == 0) && (this.props.item.check_min > 0 && this.props.item.check_max <= 0)
                    && (this.props.item.formData.value.length < this.props.item.check_min)
                    && <div className="preview_regex_check_tips">{"最少可选" + this.props.item.check_min + "项，当前已选" + this.props.item.formData.value.length + "项"}</div>}
                {
                    this.props.item.formData.value && !(this.props.item.formData.value.length == 0 && this.props.item.formData.checkRequire.value == 0) && this.props.item.check_min <= 0 && this.props.item.check_max > 0
                    && (this.props.item.formData.value.length > this.props.item.check_max)
                    && <div className="preview_regex_check_tips">{"最多可选" + this.props.item.check_min + "-" + this.props.item.check_max + "项，当前已选" + this.props.item.formData.value.length + "项"}</div>}
            </div>
        )
    }
}

export default Item;