import React from "react";
import { Tabs, Icon, Notify } from 'zent';
import "./center.css";
import global from "@/utils/config";
import CenterLeft from "./centerLeft";
import { SingleQuestion, CheckboxQuestion, InputQuestion, ImageQuestion, PhoneQuestion, EmailQuestion, DateQuestion, AreaQuestion, TextQuestion, TextAreaQuestion, SuccessModule } from "./previewModule";
import getPageNum from "@/mixins/getPageNum";
import calcJumpNum from "@/mixins/calcJumpNum";

import set1 from "@/assets/images/icons/set.png";
import arrow1 from "@/assets/images/other/arrow.png";
import center1 from "@/assets/images/other/center.png";
import canNotPutByLimit1 from "@/assets/images/other/canNotPutByLimit.png";
import canNotMoveModule1 from "@/assets/images/other/canNotMoveModule.png";
import canNotMoveEnd1 from "@/assets/images/other/canNotMoveEnd.png";
import canNotPut1 from "@/assets/images/other/canNotPut.png";
import canNotDrag1 from "@/assets/images/other/canNotDrag.png";
import putArea1 from "@/assets/images/other/putArea.png";

const TabPanel = Tabs.TabPanel;
const wrapperWidth = 375;

const maxPageId = global.maxPid;

class Center extends React.Component {
    constructor(props) {
        super(props);
    }
    mixins = [getPageNum]
    putArea = React.createRef();
    canNotPut = React.createRef();
    phoneArea = React.createRef();
    centerLeftRef = React.createRef();
    // 一个页面的内容
    pageContent = function (content) {
        return <div className="page_content_wrapper"><div className="page_content">{content}</div></div>
    };
    state = {
        incremMId: 1, // 自增ID
        moveDom: null,
        moveDomRect: null,
        previewMoveStatus: 0,
        moveActiveType: "radio",
        editType: "normal",
        isEndModule: false,
        firstFlag: true,
        contentHeight: 0,
        scrollBtnsShow: false,
        scrollCenterShow: false,
        scrollUpShow: false,
        scrollDownShow: false,
        pageContentDom: null
    };

    // 获取当前移动的按钮
    setBtn(aBtn, addIndex, moveModuleFlag) {
        if (!aBtn.type) {
            aBtn.type = "radio";
        }
        this.props.allData.aMIndex = addIndex;
        this.updatePages(addIndex, aBtn, moveModuleFlag);
    }

    changeCanStatus(status, otherType) {
        if (this.putArea && this.putArea.current) {
            this.putArea.current.style.display = "none";
            this.canNotPut.current.style.display = "none";
            if (status === 1) {
                // 显示可放置区
                this.putArea.current.style.display = "flex";
                this.canNotPut.current.style.display = "none";
            } else if (status === 2 || status === 3) {
                this.canNotPut.current.style.display = "flex";
                this.putArea.current.style.display = "none";
                if (this.props.editType == 1) {
                    otherType = "canNotMoveModule";
                }
            }
        }
        if (otherType && otherType !== "normal") {
            this.setState({
                editType: otherType
            })
        } else {
            this.setState({
                editType: "normal"
            })
        }
        if (status == 0) {
            this.setState({
                moveActiveType: null
            })
        }
    }

    onTabAdd = () => {
        let panels = this.props.allData.pages;

        var pageIndex = 0;
        if (this.props.allData.aPid == maxPageId) {
            pageIndex = panels.length - 2;
        } else {
            pageIndex = this.props.allData.pages.findIndex(p => {
                return p.id == this.props.allData.aPid
            })
        }

        let addFlag = true;
        let canNotAddMsg = "";
        if (this.props.allData.freezePageNum > pageIndex + 1) {
            addFlag = false;
            canNotAddMsg = "受限编辑模式，此处不能添加分页";
        } else {
            for (let i = 0; i < panels.length - 1; i++) {
                if (panels[i].modules) {
                    for (let j = 0; j < panels[i].modules.length; j++) {
                        const module = panels[i].modules[j];
                        if (i <= pageIndex) {
                            if (module.mdStatus === "jumpToEndLastOne" || module.mdStatus === "toEnd") {
                                addFlag = false;
                                break;
                            }
                        } else {
                            if (module.mdStatus === "deleteInvalid" || module.mdStatus === "lastOne" || module.mdStatus === "canDeleteLastOne" || module.mdStatus === "jumpToEndLastOne") {
                                addFlag = false;
                                break;
                            } else if (module.mdStatus === "endDeleteInvalid" && module.jumpFromPage <= pageIndex) {
                                addFlag = false;
                                break;
                            }
                        }
                    }
                }
                if (!addFlag) {
                    canNotAddMsg = "由于设置了跳题，此处不能添加分页!";
                    break;
                }
            }
        }
        if (!addFlag) {
            Notify.warn(canNotAddMsg);
            return
        }

        panels.splice(pageIndex + 1, 0, {
            content: this.pageContent(),
        });
        getPageNum.calcPageNum(this.props.allData);
        this.props.allData.pages = panels;
        this.props.allData.aPIndex = pageIndex + 1;
        this.props.allData.aPid = this.props.allData.pages[this.props.allData.aPIndex].id;
        this.updatePages();
    };

    onTabDel = id => {
        let index = - 1;
        this.props.allData.pages.map((p, i) => {
            if (p.id === id) {
                index = i;
                return false;
            }
        })
        var pages = [...this.props.allData.pages];
        let canDel = true;
        let canNotDelMsg = "";
        if (pages[index].freezeModuleNum) {
            canDel = false;
            canNotDelMsg = `受限编辑模式，此页不能删除`;
        } else {
            for (let i = 0; i < pages[index].modules.length; i++) {
                let one = pages[index].modules[i];
                if (one.mdStatus === "deleteInvalid") {
                    canDel = false;
                    canNotDelMsg = `此页第${one.num}题设置了跳转，不能删除`;
                    break;
                }
                if (one.mdStatus === "lastOne") {
                    canDel = false;
                    canNotDelMsg = `第${one.jumpFromPage}页的第${one.jumpFrom}题设置了跳转到此页面的第${one.num}题，不能删除`;
                    break;
                }
            }
        }
        if (!canDel) {
            Notify.warn(canNotDelMsg)
            return false;
        }
        pages.splice(index, 1);
        this.props.allData.pages = [...pages];
        getPageNum.calcPageNum(this.props.allData);
        this.props.allData.aPid = this.props.allData.pages[index].id;
        this.updatePages();
    };

    setBase() {
        this.props.allData.aMid = "-1";
        this.updatePages();
    }

    onTabChange = (id, mid) => {
        this.props.allData.aPid = id;
        let pIndex = -1;
        if (this.props.allData.aPid === maxPageId) {
            pIndex = this.props.allData.pages.length - 1;
            this.props.allData.aMid = maxPageId;
        } else {
            if (mid) {
                this.props.allData.aMid = mid;
            } else {
                this.props.allData.aMid = "-1";
            }
            pIndex = this.props.allData.pages.findIndex(p => {
                return p.id === this.props.allData.aPid;
            });
        }
        this.props.allData.aPIndex = pIndex;
        this.updatePages();
    };

    renderPanels() {
        let panels = this.props.allData.pages;
        return panels.map(p => {
            return (
                <TabPanel {...p} key={p.id}>
                    {p.content}
                </TabPanel>
            );
        }).slice(0);
    }

    updatePreviewMoveStatus(status) {
        // 调试移动透明dom注释可见
        this.state.moveDom && this.state.moveDom.parentNode && this.state.moveDom.parentNode.removeChild(this.state.moveDom);
        this.setState({
            previewMoveStatus: status
        })
    }
    mouseDownHandler(module, mRef, index, event) {
        if(module.type === "textarea" && !this.props.textareaValid){
            Notify.error("富文本模块暂不可用，请联系管理员");
            return false;
        }
        if (event.target.className.indexOf("preview_del") >= 0) {
            return false;
        }
        this.props.allData.aMid = module.id;
        if ((module.mdStatus === "moveValid" || module.type === "text" || module.type === "textarea") && !module.freezeOptionNum) {
            let moveDom = this.state.moveDom = mRef.current.cloneNode(true);
            this.state.moveIndex = index;
            this.state.moveModule = module;
            moveDom.removeChild(moveDom.querySelector(".preview_tooltip"));
            if (moveDom.querySelector(".preview_del")) {
                moveDom.removeChild(moveDom.querySelector(".preview_del"));
            }

            this.state.moveDomRect = mRef.current.getBoundingClientRect();
            this.state.disLeft = event.pageX - this.state.moveDomRect.left;
            this.state.disTop = event.pageY - this.state.moveDomRect.top;
            moveDom.style.cssText = "position:fixed;opacity:0.5;left:" + this.state.moveDomRect.left + "px;top:" + this.state.moveDomRect.top + "px;width:" + wrapperWidth + "px;background:#fff;transition:initial;";
            this.phoneArea.current.appendChild(moveDom);

            this.setState({
                previewMoveStatus: 1,
                moveActiveType: module.type
            })
        } else {
            this.setState({
                previewMoveStatus: 3,
                isEndModule: (module.type == "end")
            })
        }
        this.updatePages();
    }

    activeModule(id) {
        this.props.allData.aMid = id;
        this.updatePages();
    }

    removeModule(id) {
        let pages = this.props.allData.pages;
        let currentPage = pages[this.props.allData.aPIndex];
        let currentModule = currentPage.modules[this.props.allData.aMIndex];
        let index = currentPage.modules.findIndex(m => {
            return m.id === id;
        })
        let deleteOne = currentPage.modules.splice(index, 1)[0];
        let deleteCurrentModule = false;
        if (this.props.allData.aMid === deleteOne.id) {
            deleteCurrentModule = true;
        }
        // 如果删除的不是当前选中的题，重新计算当前选中的题的跳题的text文本
        if (!deleteCurrentModule && (currentModule.type !== "textarea" && currentModule.type !== "text")) {
            for (let i = 0, iLen = pages.length - 1; i < iLen; i++) {
                const modules = pages[i].modules;
                let hasChangeJumpText = false;
                for (let j = 0, jLen = modules.length; j < jLen; j++) {
                    const m = modules[j];
                    if ((m.num + '') === currentModule.formData.jump.value.key) {
                        currentModule.formData.jump.value.text = (m.num - 1) + '.' + m.formData.title.value;
                        hasChangeJumpText = true;
                        break;
                    }
                }
                if (hasChangeJumpText) break;
            }
        }
        // 重新计算所有跳题的题号
        pages.map(p => {
            p.modules.map((m) => {
                if (m.formData.jump && m.formData.jump.value.key > 0) {
                    if (m.formData.jump.value.key > deleteOne.num) {
                        m.formData.jump.value.key = (m.formData.jump.value.key - 1) + "";
                    }
                }

                if (m.type === "radio") {
                    m.formData.options.map(o => {
                        if (o.jump && o.jump.value.key > 0) {
                            o.jump.value.key = (o.jump.value.key - 1) + "";
                        }
                    })
                }

            })
        });

        // 当前页中，如果删除的题是当前选中的题，下面还有题就默认选中下一道，没有就选中上一道
        if (deleteCurrentModule) {
            let nextModule = currentPage.modules[index];
            if (nextModule) {
                this.props.allData.aMid = nextModule.id;
            } else {
                let prevModule = currentPage.modules[index - 1];
                if (prevModule) {
                    this.props.allData.aMid = prevModule.id;
                }
            }
        }
        this.updatePages();
    }

    /**
     * @description 更新数据
     * @param {*} index 插入的位置上
     * @param {*} previewModule 插入的预览模型
     * @param {*} moveIndex 要移动的模型索引
     * @memberof Center
     */
    updatePages(index, aBtn, moveModuleFlag) {
        aBtn = Object.assign({}, aBtn);
        aBtn.pid = this.props.allData.aPid;
        let pages = this.props.allData.pages;
        let moduleNum = 0;
        pages.map((page, pIndex) => {
            // page如果没有数据就初始化
            if(!page.qid){
                page.qid = this.props.allData.id;
            }
            page.weigh = pIndex;
            if(!page.modules){
                page.modules = [];
            }
            for (let i = 0; i < page.modules.length; i++) {
                const element = page.modules[i];
                if (element.id > this.state.incremMId && element.id != maxPageId) {
                    this.state.incremMId = element.id;
                }
            }
            // 如果为当前页面
            if (page.id === this.props.allData.aPid) {
                if (page.id === maxPageId) {
                    //结束页
                    if (!page.hasSuccess) {
                        page.hasSuccess = true;
                        // ++this.state.incremMId;
                        // 新插入
                        let end = {
                            id: maxPageId,
                            pid: maxPageId,
                            required: true,
                            title: "提交成功",
                            type: "end",
                            formData: JSON.parse(JSON.stringify(global["end"]))
                        }
                        this.props.allData.aMid = maxPageId;
                        page.modules.push(end)
                    }
                }
                if (aBtn && aBtn.type) {
                    // 插入或移动
                    if (moveModuleFlag) {
                        // 移动
                        if (this.state.moveIndex > index) {
                            // 从下往上移动
                            page.modules.splice(this.state.moveIndex, 1);
                            page.modules.splice(index, 0, this.state.moveModule);
                        } else if (this.state.moveIndex < index) {
                            // 从上往下移
                            page.modules.splice(index, 0, this.state.moveModule);
                            page.modules.splice(this.state.moveIndex, 1);
                        }
                    } else {
                        ++this.state.incremMId;
                        // 新插入
                        aBtn.id = this.state.incremMId;
                        if (aBtn.type === "radio" || aBtn.type === "checkbox") {
                            global[aBtn.type].options = [];
                            global.option.id++;
                            global[aBtn.type].options.push(JSON.parse(JSON.stringify(global.option)))

                            global.option.id++;
                            global[aBtn.type].options.push(JSON.parse(JSON.stringify(global.option)))
                        }
                        aBtn.formData = JSON.parse(JSON.stringify(global[aBtn.type]));
                        page.modules.splice(index, 0, aBtn);
                        // 更改激活的mid
                        this.props.allData.aMid = this.state.incremMId;
                    }
                } else {
                    // 删除
                    if (index || index === 0) {
                        page.modules.splice(index, 1);
                    }
                }
                page.modules = page.modules.slice(0);


            }
            return page;
        })
        for (let k = 0; k < pages.length; k++) {
            let modules = pages[k].modules;
            for (let i = 0, len = modules.length; i < len; i++) {
                modules[i].jumpFromPage = "";
                modules[i].jumpFrom = "";
                modules[i].jumpFromOption = "";
            }
        }

        calcJumpNum(this.props.allData);
        for (let k = 0, pLen = pages.length; k < pLen; k++) {
            let page = pages[k];
            // 最后渲染中心预览卡片
            page.content = this.pageContent(page.modules.map((m, index) => {
                if (m.type !== "text" && m.type !== "textarea") {
                    m.num = ++moduleNum;
                }
                m.weigh = index;
                let module = null;
                if (m.id === this.props.allData.aMid) {
                    this.props.allData.aMIndex = index;
                }
                switch (m.type) {
                    case "radio":
                        module = <SingleQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}></SingleQuestion>
                        break;
                    case "checkbox":
                        module = <CheckboxQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}></CheckboxQuestion>
                        break;
                    case "input":
                        module = <InputQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></InputQuestion>
                        break;
                    case "image":
                        module = <ImageQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></ImageQuestion>
                        break;
                    case "mobile":
                        module = <PhoneQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></PhoneQuestion>
                        break;
                    case "mail":
                        module = <EmailQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></EmailQuestion>
                        break;
                    case "date":
                        module = <DateQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></DateQuestion>
                        break;
                    case "area":
                        module = <AreaQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            editType={this.props.editType}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></AreaQuestion>
                        break;
                    case "text":
                        module = <TextQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></TextQuestion>
                        break;
                    case "textarea":
                        module = <TextAreaQuestion item={m} aMid={this.props.allData.aMid}
                            removeModule={this.removeModule.bind(this)}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            previewMoveStatus={this.state.previewMoveStatus}
                            textareaValid={this.props.textareaValid}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}
                        ></TextAreaQuestion>
                        break;
                    case "end":
                        module = <SuccessModule item={m} aMid={this.props.allData.aMid}
                            activeModule={this.activeModule.bind(this)} key={index}
                            index={index}
                            previewMoveStatus={this.state.previewMoveStatus}
                            mouseDownHandler={this.mouseDownHandler.bind(this)}></SuccessModule>
                        break;
                    default:
                        break;
                }
                return module;
            }));
        }
        this.props.allData.pages = pages.slice(0);

        this.props.showModule(this.props.allData.pages[this.props.allData.aPIndex].modules[this.props.allData.aMIndex]);
        this.updateNoSaveData();
        setTimeout(()=>{
            this.setState({
                pageContentDom: this.getPageContent()
            })
            this.updateContentHeight();
        },0)
    }
    updateNoSaveData() {
        if (this.state.firstFlag) {
            this.setState({
                firstFlag: false
            })
        } else {
            let yz_wj_all_data = JSON.parse(JSON.stringify(this.props.allData));
            yz_wj_all_data.pages.map((p) => {
                delete p.tab;
                delete p.content;
                return p;
            })
            localStorage.setItem("yz_wj_all_data", JSON.stringify(yz_wj_all_data));
        }
    }

    updateContentHeight(){
        let pageContent = this.state.pageContentDom;
        if(!pageContent){
            return
        }
        let height = pageContent.getBoundingClientRect().height;
        this.setState({
            contentHeight: height
        })
        
        if(height > 6000){
            this.setState({
                scrollCenterShow: true
            })
        } else {
            this.setState({
                scrollCenterShow: false
            })
        }

        this.updateStateByScrollHeight();
        if(height >= 3000 && (this.state.scrollUpShow || this.state.scrollCenterShow || this.state.scrollDownShow)){
            this.setState({
                scrollBtnsShow: true
            })
        } else {
            this.setState({
                scrollBtnsShow: false
            })
        }
    }

    getPageContent(){
        let centerPhone = document.getElementById("centerPhone");
        let zentTabs = centerPhone.querySelectorAll(".zent-tabs-panel")
        let pageContent = null;
        for (let index = 0; index < zentTabs.length; index++) {
            if(zentTabs[index].style.display !== "none"){
                pageContent = zentTabs[index].querySelector('.page_content');
                break;
            }
        }
        return pageContent;
    }

    updateStateByScrollHeight(){
        let scrollHeight = 0;
        if(this.state.pageContentDom){
            scrollHeight = this.state.pageContentDom.parentNode.scrollTop;
        }
        
        if(scrollHeight > 2000){
            this.setState({
                scrollUpShow: true
            })
        } else {
            this.setState({
                scrollUpShow: false
            })
        }
        if(this.state.contentHeight - scrollHeight < 2000){
            this.setState({
                scrollDownShow: false
            })
        } else {
            this.setState({
                scrollDownShow: true
            })
        }
        if(this.state.scrollUpShow || this.state.scrollCenterShow || this.state.scrollDownShow){
            this.setState({
                scrollBtnsShow: true
            })
        } else {
            this.setState({
                scrollBtnsShow: false
            })
        }
    }
    scrollUp(){
        this.state.pageContentDom.parentNode.scrollTop = 0;
        this.updateStateByScrollHeight();
    }
    scrollCenter(){
        this.state.pageContentDom.parentNode.scrollTop = this.state.contentHeight / 2;
        this.updateStateByScrollHeight();
    }
    scrollDown(){
        this.state.pageContentDom.parentNode.scrollTop = this.state.contentHeight + 700;
        this.updateStateByScrollHeight();
    }

    componentDidMount() {
        this.centerLeftRef.current.setDomRect();
        // let getHeightTimer = setInterval(() => {
        //     if(document.getElementById("pageContent")){
        //         clearInterval(getHeightTimer);
        //         this.updateContentHeight();
        //     }
        // }, 1000)
    }
    render() {
        const panels = this.renderPanels();
        return (
            <div className="w1_center">
                <CenterLeft rect={this.state.rect}
                    ref={this.centerLeftRef}
                    putArea={this.putArea}
                    canNotPut={this.canNotPut}
                    phoneArea={this.phoneArea}
                    setBtn={this.setBtn.bind(this)}
                    updateStateByScrollHeight={this.updateStateByScrollHeight.bind(this)}
                    changeCanStatus={this.changeCanStatus.bind(this)}
                    previewMoveStatus={this.state.previewMoveStatus}
                    moveDom={this.state.moveDom}
                    moveDomRect={this.state.moveDomRect}
                    moveActiveType={this.state.moveActiveType}
                    disLeft={this.state.disLeft}
                    disTop={this.state.disTop}
                    activePage={this.props.allData.pages[this.props.allData.aPIndex]}
                    textareaValid={this.props.textareaValid}
                    updatePreviewMoveStatus={this.updatePreviewMoveStatus.bind(this)}></CenterLeft>
                <div className="w1_center_right" >
                    {this.props.hasNoSaveData && <div className="no_save_data_tips">
                        <div className="close_no_save_data_tips" onClick={this.props.closeNoSaveData.bind(this)}>×</div>
                        <div className="no_save_data_left">
                            <Icon type="info-circle" className="no_save_icon" />
                            <div className="no_save_data_content">你有一个未保存的草稿，你想要
                                <span className="no_save_data_btn" onClick={this.props.useNoSaveData.bind(this)}>使用草稿</span>或
                                <span className="no_save_data_btn" onClick={this.props.giveUpNoSaveData.bind(this)}>放弃草稿</span>?</div>
                        </div>
                    </div>}
                    <div className={`w1_center_phone  ${this.props.hasNoSaveData ? 'has_no_save_data' : ''}`} ref={this.phoneArea} id="centerPhone">
                        <div className="w1_nav">
                            <div className="w1_time">9:41 AM</div>
                            <div className="w1_title">{this.props.allData.base.title.value || "微页面标题"}</div>
                        </div>
                        <Tabs
                            candel
                            type="card"
                            activeId={this.props.allData.aPid}
                            onChange={this.onTabChange}
                            onDelete={this.onTabDel}
                            overflowMode="anchor"
                            navExtraContent={<Icon type="plus" className="zent-tabs-add-btn" onClick={this.onTabAdd} />}
                        >
                            {panels}
                        </Tabs>
                        <div className="put_area" ref={this.putArea}>
                            <img src={putArea1}></img>
                        </div>
                        <div className="can_not_put" ref={this.canNotPut}>
                            {
                                this.state.isEndModule ? <img src={canNotMoveEnd1}></img>
                                    : <React.Fragment>{this.state.editType == "normal" && <img src={(this.state.previewMoveStatus == 3 || this.state.previewMoveStatus == 4)
                                        ? canNotDrag1
                                        : canNotPut1}></img>}
                                        {this.state.editType == "canNotMoveModule" && <img src={canNotMoveModule1}></img>}
                                        {this.state.editType == "limitEdit" && <img src={require(canNotPutByLimit1)}></img>}
                                    </React.Fragment>
                            }

                        </div>
                    </div>
                    <div className={`juan_set ${this.props.hasNoSaveData ? 'has_no_data' : ''}`} onClick={this.setBase.bind(this)}><img src={set1}></img>问卷设置</div>
                    {this.state.scrollBtnsShow && <div className="scroll_btns">
                        {this.state.scrollUpShow && <div className="scroll_item scroll_up" onClick={this.scrollUp.bind(this)}><img src={arrow1} title="向上"></img></div>}
                        {this.state.scrollCenterShow && <div className="scroll_item scroll_center" onClick={this.scrollCenter.bind(this)}><img src={center1} title="中间"></img></div>}
                        {this.state.scrollDownShow && <div className="scroll_item scroll_down" onClick={this.scrollDown.bind(this)}><img src={arrow1} title="向下"></img></div>}
                    </div>}
                </div>
            </div>
        )
    }
}

export default Center