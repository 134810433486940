import React from "react";
import { setStatus, deleteQuestion } from "@/api/index"

import { Pop, Notify, Tag } from 'zent';
import { Dialog, Button, CopyButton, Input, Link } from 'zent';
import { LayoutRow as Row, LayoutCol as Col, LayoutGrid as Grid } from 'zent';
import { Alert } from 'zent';
import "./controls.css"
import { VerticalTabs, ImageUpload } from 'zent';
import { delData, getShareConfig, saveShareConfig, upload } from "@/api/index";

// import { Link } from "react-router-dom";
const { TabPanel } = VerticalTabs;

/**
 * - 未开始0：推广 | 编辑 | 复制
    - 进行中1：暂停回收 | 统计数据 | 推广 | 编辑 | 复制
    - 暂停中2：开始回收 | 统计数据  | 编辑 | 复制（暂停时不能推广）
    - 已结束3：统计数据 | 复制 | 删除
备注：如回收问卷数为0，则不展示“统计数据”操作；
 */
class ControlBtns extends React.Component {

    constructor(props) {
        super(props)
        this.recycle = this.recycle.bind(this);
        this.stopRecycle = this.stopRecycle.bind(this);
        this.statisticalData = this.statisticalData.bind(this);
        this.popularize = this.popularize.bind(this);
        this.copy = this.copy.bind(this);
        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
    }
    state = {
        pop_visible: false,
        pop_content: '暂停...',
        pop_btn_txt: '',
        action: '',
        action_id: 0,
        action_status: 0,
        warning_box_w1_v: 'block',
        warning_box_w2_v: 'none',
        visible: false,
        box1_cls: 'layout-demo-cell edit_cell_2',
        box2_cls: 'layout-demo-cell edit_cell_1',
        box3_cls: 'layout-demo-cell edit_cell_1',
        edit_type: 'keep',

        promote_visible: false,
        p_activeId: '1',
        p_img: '',
        copy_url: '',

        share_title: '默认填充问卷标题',
        share_description: '',
        share_image: ''
    }
    onTabChange = id => {
        this.setState({
            p_activeId: id,
        });
    };
    closePop = () => {
        this.setState({ pop_visible: false,recycle_pop_visible: false });
    }
    confirmHandler = () => {
        if (this.state.action == 'stop') {
            setStatus({ status: this.state.action_status, id: this.state.action_id }).then(res => {
                this.props.refresh();
                this.setState({ pop_visible: false });

                Notify.success('操作成功，问卷已暂停')
            })
        } else if (this.state.action == 'delete') {
            deleteQuestion({ id: this.state.action_id }).then(res => {
                this.props.refresh();
                this.setState({ pop_visible: false });

                Notify.success('操作成功，问卷已删除')
            });
        }
    };

    clk_keep_box() {
        this.setState({
            warning_box_w1_v: 'block',
            warning_box_w2_v: 'none',
            box1_cls: 'layout-demo-cell edit_cell_2',
            box2_cls: 'layout-demo-cell edit_cell_1',
            box3_cls: 'layout-demo-cell edit_cell_1',
            edit_type: 'keep'
        });
    }
    clk_delete_box() {
        this.setState({
            warning_box_w1_v: 'none',
            warning_box_w2_v: 'block',
            box1_cls: 'layout-demo-cell edit_cell_1',
            box2_cls: 'layout-demo-cell edit_cell_2',
            box3_cls: 'layout-demo-cell edit_cell_1',
            edit_type: 'delete'
        });
    }
    clk_copy_box() {
        this.setState({
            warning_box_w1_v: 'none',
            warning_box_w2_v: 'none',
            box1_cls: 'layout-demo-cell edit_cell_1',
            box2_cls: 'layout-demo-cell edit_cell_1',
            box3_cls: 'layout-demo-cell edit_cell_2',
            edit_type: 'copy'
        });
    }

    recycle() {
        // 回收
        setStatus({ status: 0, id: this.props.id }).then(res => {
            this.props.refresh();
            Notify.success('操作成功，问卷已开始回收');
        });
    }
    stopRecycle() {
        this.setState({
            pop_content: '暂停回收期间，问卷链接关闭且不能填写。确定暂停回收？',
            pop_btn_txt: '暂停回收',
            action: 'stop',
            action_id: this.props.id,
            action_status: -1,
            pop_visible: false,
            recycle_pop_visible: true
        });
    }
    statisticalData() {
        this.props.history.push("/data/" + this.props.id);
        // 统计数据
    }
    popularize() {
        // 推广
        let timeNum = new Date().getTime();
        let url = 'https://' + process.env.APP_BASE_URL + "/#/preview/" + timeNum + this.props.id + "/YW5zd2VybW9kZQ%3D%3D"
        console.log(url);
        let input = document.createElement("input");
        input.value = url;
        document.documentElement.appendChild(input);
        const range = document.createRange(input);
        range.selectNode(input);
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            selection.removeAllRanges();
            selection.addRange(range);
            input.select();
            document.execCommand('copy');
            Notify.success("推广链接复制成功")
        } else {
            Notify.success("推广链接复制失败")
        }
        input.parentNode.removeChild(input);
    }
    copy() {
        this.props.history.push("/editpaper/" + this.props.id + "/2");
    }
    edit() {
        if (this.props.num > 0) {
            this.setState({ visible: true });
        } else {
            this.props.history.push("/editpaper/" + this.props.id + "/0");
        }
    }
    edit_next() {
        if (this.state.edit_type == 'keep') {
            this.props.history.push("/editpaper/" + this.props.id + "/1");
        } else if (this.state.edit_type == 'delete') {
            delData({
                qid: this.props.id
            }).then(() => {
                this.props.history.push("/editpaper/" + this.props.id + "/0");
            });
        } else if (this.state.edit_type == 'copy') {
            this.props.history.push("/editpaper/" + this.props.id + "/2");
        }
    }
    delete() {
        this.setState({
            pop_content: '删除后，问卷数据无法回复，确定伤处？',
            pop_btn_txt: '删除',
            action: 'delete',
            action_id: this.props.id,
            action_status: 0,
            pop_visible: true,
            recycle_pop_visible: false,
            TabActiveId: 1
        });
    }
    down_qr() {
        var image = new Image()
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function () {
            var canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height

            var context = canvas.getContext('2d')
            context.drawImage(image, 0, 0, image.width, image.height)
            var url = canvas.toDataURL('image/png')
            var a = document.createElement('a')
            // 创建一个单击事件
            var event = new MouseEvent('click')
            a.download = '下载图片'
            a.href = url

            // 触发a的单击事件
            a.dispatchEvent(event)
        }

        image.src = this.state.p_img;
    }
    changeShareTitle(e) {
        this.setState({ share_title: e.target.value });
    }
    changeShareDescription(e) {
        this.setState({ share_description: e.target.value });
    }
    onUpload = (file, report) => {
        var that = this;
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("authority_id", localStorage.getItem("wj_authority_id"));
            upload(formData).then(res => {
                if (res.data.success) {
                    that.setState({
                        'share_image': res.data.data.image_url
                    })
                    resolve();
                } else {
                    reject();
                    // Notify.warn(res.data.gw_err_resp.err_msg + "，换张图片试试！")
                }
            })
        });
    };
    onUploadChange = files => {
    };
    saveShareConfig() {
        var that = this;
        saveShareConfig({
            qid: that.props.id,
            title: that.state.share_title,
            description: that.state.share_description,
            image: that.state.share_image
        }).then((res) => {
            if (res.data.status === 'finish') {
                Notify.success('操作成功')
            } else {
                Notify.success('操作失败，请重试！')
            }
        });
    };
    render() {
        return (
            <div className="wd_controls">
                <Dialog
                    className="dialog_cus"
                    visible={this.state.promote_visible}
                    onClose={() => this.setState({ promote_visible: false })}
                    closeBtn={true}
                    title="提示"
                >
                    <div>
                        <VerticalTabs
                            activeId={this.state.p_activeId}
                            onChange={this.onTabChange}
                        >
                            <TabPanel tab={<span>小程序</span>} id="1">
                                <div className="pm_box">
                                    <div className="pm_left">
                                        <div className="pm_left_qrbox">
                                            <img className="qr_img" src={this.state.p_img}></img>
                                            <a className="qr_no_img" href="https://file.yzcdn.cn/mall-cloud/jc.d9d0f90c0293758708d2544232c1d255.png" target="_blank">无法显示推广码，请点这里</a>
                                        </div>
                                    </div>
                                    <div className="pm_right">
                                        <span>问卷链接：</span>
                                        <div className="copy_box">
                                            <Input placeholder={this.state.copy_url} />
                                            <CopyButton text={this.state.copy_url} />
                                        </div>
                                        <div className="downloadQrcode" onClick={this.down_qr.bind(this)}>下载二维码</div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel tab={<span>分享设置</span>} id="2">
                                <div className="pm_box">
                                    <div className="pm_tg_left">
                                        <div className="pm_tag">
                                            <Tag theme="blue" style={{ fontSize: 14 }} outline>微信好友</Tag>
                                        </div>
                                        <img className="pm_demo_img" src="https://file.yzcdn.cn/mall-cloud/demo.85f3ff378efd910e9f19f4795f0bba4e.png"></img>
                                    </div>
                                    <div className="pm_tg_right">
                                        <div className="pm_tg_right_row">
                                            <span>分享标题：</span>
                                            <Input type="textarea" value={this.state.share_title} autoSize onChange={this.changeShareTitle.bind(this)} />
                                        </div>
                                        <div className="pm_tg_right_row">
                                            <span>分享描述：</span>
                                            <Input placeholder="请填写分享描述" type="textarea" value={this.state.share_description} onChange={this.changeShareDescription.bind(this)} autoSize />
                                        </div>
                                        <div className="pm_tg_right_row">
                                            <span>分享图片：</span>
                                            <ImageUpload
                                                className="zent-image-upload-demo"
                                                maxSize={1024 * 600}
                                                tips="建议尺寸 640*640，单张图片不超过 5M"
                                                maxAmount={1}
                                                multiple
                                                sortable
                                                tips="建议尺寸 640*640，单张图片不超过 500k"
                                                onChange={this.onUploadChange}
                                                onUpload={this.onUpload}
                                                defaultFileList={[{ thumbSrc: this.state.share_image }]}
                                            // onError={this.onUploadError}
                                            />
                                        </div>
                                        <div style={{ width: '100%', textAlign: 'right', marginTop: '80px' }}>
                                            <Button type="primary" onClick={this.saveShareConfig.bind(this)}>保存</Button>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </VerticalTabs>

                    </div>
                </Dialog>


                <Dialog
                    className="dialog_cus"
                    visible={this.state.visible}
                    onClose={() => this.setState({ visible: false })}
                    closeBtn={false}
                    footer={
                        <div>
                            <Button onClick={() => this.setState({ visible: false })}>取消</Button>
                            <Button type="primary" onClick={this.edit_next.bind(this)}>下一步</Button>
                        </div>
                    }
                    title="提示"
                >
                    <div>
                        <span>请选择修改问卷模式</span>
                        <Grid className="layout-demo-basic">
                            <Row justify="center">
                                <Col span={8} offset={0}>
                                    <div className={this.state.box1_cls} onClick={this.clk_keep_box.bind(this)}>
                                        <div className="title_box">
                                            <span className="edit_title">保留答卷</span>
                                        </div>
                                        <div className="des_box">
                                            <span className="edit_des">修改受限制</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8} offset={0}>
                                    <div className={this.state.box2_cls} onClick={this.clk_delete_box.bind(this)}>
                                        <div className="title_box">
                                            <span className="edit_title">删除所有答卷</span>
                                        </div>
                                        <div className="des_box">
                                            <span className="edit_des">修改不受限制</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={8} offset={0}>
                                    <div className={this.state.box3_cls} onClick={this.clk_copy_box.bind(this)}>
                                        <div className="title_box">
                                            <span className="edit_title">复制问卷编辑</span>
                                        </div>
                                        <div className="des_box">
                                            <span className="edit_des">原问卷不受任何影响</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Grid>
                        <div className="warning_box w1" style={{ display: this.state.warning_box_w1_v }}>
                            <Alert type="warning">只能修改问卷细节，例如更改错别字、添加选项、增加跳题逻辑。<br></br>
                                不能对问卷做以下操作：<span className="red_txt">删除题目或选项、移动题目或选项</span></Alert>
                        </div>
                        <div className="warning_box w2" style={{ display: this.state.warning_box_w2_v }}>
                            <Alert type="warning">答卷<span className="red_txt">删除后不可恢复</span>，请谨慎选择。<br></br>
                                已有答卷：<span className="red_txt">{this.props.num} 份</span></Alert>
                        </div>
                    </div>
                </Dialog>
                {this.props.state == '-1' ? <span onClick={this.recycle.bind(this)}>开始回收</span> : ''}
                <Pop
                    visible={this.state.recycle_pop_visible}
                    confirmText={this.state.pop_btn_txt}
                    trigger="click"
                    content={
                        <span
                            style={{
                                maxWidth: 200,
                                wordBreak: 'break-all',
                                display: 'inline-block',
                            }}
                        >
                            {this.state.pop_content}
                        </span>
                    }
                    onConfirm={this.confirmHandler}
                    onCancel={this.closePop}
                >
                    {this.props.state == '2' ? <span onClick={this.stopRecycle.bind(this)}>暂停回收</span> : ''}
                </Pop>

                {this.props.num > 0 && this.props.state != '1' ? <span onClick={this.statisticalData.bind(this)}>统计数据</span> : ''}
                {this.props.state == '1' || this.props.state == '2' ? <span onClick={this.popularize.bind(this)}>推广</span> : ''}
                <span onClick={this.copy.bind(this)}>复制</span>
                {this.props.state != '3' ? <span onClick={this.edit.bind(this)}>编辑</span> : ''}
                <Pop
                    visible={this.state.pop_visible}
                    confirmText={this.state.pop_btn_txt}
                    trigger="click"
                    content={
                        <span
                            style={{
                                maxWidth: 200,
                                wordBreak: 'break-all',
                                display: 'inline-block',
                            }}
                        >
                            {this.state.pop_content}
                        </span>
                    }
                    onConfirm={this.confirmHandler}
                    onCancel={this.closePop}
                >
                    <span onClick={this.delete.bind(this)}>删除</span>
                </Pop>
            </div>
        )
    }
}

export default ControlBtns