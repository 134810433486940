import React from "react";
import { Input, ImageUpload, Notify } from 'zent';
import Jump from "./jump";
import Tag from "./tag";
import { upload } from "@/api/index";
import star1 from "@/assets/images/icons/star.png";
import del_option1 from "@/assets/images/icons/del_option.png";

/**
 * @description 选项组件
 */

let uploadFinish = false;
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }

    state = {
        value: null
    }
    uploadRef = React.createRef();
    stateChange(e) {
        e.persist();
        this.setValue("value", e.target.value)
    }
    stateBlur() {
        this.dist = true;
        this.setState({ value: 1 });
    }

    onUploadChange = files => {
        if (uploadFinish || files.length === 0) {
            if(files.length === 0){
                this.props.setImg("", this.props.index);
            }
            this.props.item.fileList = files;
        }
    };

    onBeforeUpload = files => {
        return true;
    }

    onUpload = (file, report) => {
        return new Promise((resolve, reject) => {
            let count = 0;
            let time = 100;
            const update = () => {
                if (count < 90) {
                    count += 10;
                    report(count);
                    setTimeout(update, time);
                }
            };
            setTimeout(update, time);
            uploadFinish = false;
            let formData = new FormData();
            formData.append("upfile", file);
            upload(formData).then(res => {
                console.log(res);
                if (res.data.msg === "上传成功") {
                    clearTimeout(update);
                    uploadFinish = true;
                    report(100);
                    this.props.setImg(res.data.code.fullurl, this.props.index);
                    resolve();
                } else {
                    reject();
                    Notify.warn(res.data.msg + "，换张图片试试！")
                }
            })

        });
    };

    delOption() {
        // this.props.item.options = this.props.item.options.slice(0);
        if (this.props.item.type == 0) {
            this.props.setValue("del", this.props.item, this.props.index);
        } else {
            this.props.setValue("delOther", this.props.item, this.props.index);
        }
        if(this.props.calcCheckNum){
            this.props.calcCheckNum();
        }
    }

    onUploadError = (type, data) => {
        if (type === 'overMaxAmount') {
            Notify.error(`最多可上传 ${data.maxAmount} 张图片`);
        } else if (type === 'overMaxSize') {
            Notify.error(`图片大小不能超过 ${data.formattedMaxSize}`);
        }
    };

    setValue(key, value) {
        if (key === "jump") {
            this.props.item.jump.value = value;
            this.props.setValue("option_jump", this.props.item.jump, this.props.index);
        } else if (key === "tags") {
            this.props.item.tags.value = value;
            this.props.setValue("option_tag", this.props.item.tags, this.props.index);
        } else {
            this.props.item[key] = value;
            this.props.setValue(this.props.item.key, value, this.props.index);
        }
    }
    componentDidUpdate() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className={`w1_form_item w1_option_item`}>
                <div className="option_inner_wrapper">
                    {((this.props.index > 1 && !this.props.freezeOptionNum) || (this.props.item.type == 1 && !this.props.freezeOtherOption))
                        ? <div className="option_del_btn" onClick={this.delOption.bind(this)}>
                            <img src={del_option1}></img></div> : ""}
                    <div className={`w1_option_top  
                            ${(this.props.item.value || (this.props.item.fileList && this.props.item.fileList.length)) ? "fill" : ""} 
                            ${this.dist ? "dist" : ''}`}>
                        <div className="name_line">
                            <div>
                                <img src={star1}></img>
                                <span>{this.props.item.title}{this.props.item.type == 1 ? "" : (this.props.index + 1)}</span>
                            </div>
                            {this.props.item.type != 1 && <Input placeholder={this.props.item.placeholder}
                                onChange={this.stateChange.bind(this)}
                                onBlur={this.stateBlur.bind(this)}
                                width={this.props.item.width}
                                value={this.props.item.value}
                                className="rfs0" />}
                        </div>
                        {this.props.item.type != 1 && <div className="upload_wrapper">
                            <div style={{ "width": "100%", height: "100%" }}></div>
                            <ImageUpload
                                ref={this.uploadRef}
                                className="zent-image-upload-demo rfs0"
                                width={this.props.item.width}
                                maxSize={5 * 1024 * 1024}
                                defaultFileList={this.props.item.fileList}
                                maxAmount={1}
                                multiple
                                sortable
                                tips="建议尺寸 640*640，最多 1 张，单张图片不超过 5M"
                                onChange={this.onUploadChange.bind(this)}
                                onUpload={this.onUpload.bind(this)}
                                onError={this.onUploadError.bind(this)}
                                beforeUpload={this.onBeforeUpload.bind(this)}
                            />
                        </div>}
                    </div>
                    {(this.props.item.jump
                        && (!this.props.optionJump && this.props.item.jump.value.key != "0")
                        || (this.props.optionJump))
                        ? <Jump setValue={this.setValue.bind(this)} item={this.props.item.jump}></Jump> : ""}
                    <Tag setValue={this.setValue.bind(this)} item={this.props.item.tags}></Tag>
                    {this.props.item.msg}
                </div>
            </div>
        )
    }
}

export default Item