import React from "react";
import { Input } from 'zent';

class Item extends React.Component {
    state = {
        title: "",
        errorNum: 0
    }
    ref = React.createRef();
    onChange = e => {
        e.persist();
        this.props.item.formData.value = e.target.value;
        this.props.questionChange(this.props.item);
        let checkResult = this.props.timeCheck(this.props.item);
        this.setState({
            errorNum: checkResult.errorNum
        })
    };
    onBlur(e){
        this.onChange(e);
    }
    getTitle() {
        let title = "";
        switch (this.props.item.type) {
            case "mobile":
                title = "请输入手机号";
                break;
            case "mail":
                title = "请输入邮箱"
                break;
            case "area":
                title = "请选择城市"
                break;
            default:
                break;
        }
        this.setState({
            title: title
        })
    }
    showCascader() {
        if (this.props.item.type === "area") {
            this.props.updateMIndex(this.props.item.id);
            setTimeout(() => {
                this.props.showCascader();
            },0)
        }
    }
    componentDidMount() {
        this.getTitle();
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                ref={this.ref}>
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.state.title}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper">{this.props.item.type == "area" ? <div className={`w2_${this.props.item.type}_input`}
                    onClick={this.showCascader.bind(this)}>{this.props.item.formData.value}</div>
                    : <Input className={this.props.item.type + "_input"}
                        onChange={this.onChange.bind(this)} onBlur={this.onBlur.bind(this)} value={this.props.item.formData.value} />}</div>

                {this.state.errorNum == this.props.item.num && <div className="preview_regex_check_tips">
                    {this.props.item.type === "mobile" ? "请输入正确的手机号如 13612300000" : ""}
                    {this.props.item.type === "mail" ? "请输入正确的邮箱地址如 tpdc@youzan.com" : ""}
                </div>}
            </div>
        )
    }
}

export default Item;