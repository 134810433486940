import React from "react";
import { Select } from 'zent';
import star1 from "@/assets/images/icons/star.png";

/**
 * @description 跳题组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    state = {
        options: [...this.props.item.defaultOptions, ...this.props.item.options],
    }
    stateChange(e) {
        if (!e) {
            e = this.props.item.defaultOptions[this.props.item.default];
        }
        
        this.props.setValue(this.props.item.key, e)
    }
    stateBlur() {
        if (this.props.item.required) {
            this.dist = true;
            this.setState({ value: 1 });
        }
    }
    componentDidMount() {
        let index = this.state.options.findIndex(one => {
            return one.key == (this.props.item.value && this.props.item.value.key)
        })
        if(index >= 0){
            this.props.item.value.text = this.state.options[index].text;
        }
    }

    render() {
        return (
            <div className={`w1_form_item sameline ${this.props.item.value ? "fill" : ""} ${this.dist ? "dist" : ''}`}>
                <div className="name_line">
                    {this.props.item.required && <img src={star1}></img>}
                    <span>{this.props.item.title}</span>
                </div>
                <Select options={this.props.item.defaultOptions.concat(this.props.item.options)}
                    className="rfs0"
                    width={this.props.item.width} clearable
                    placeholder={this.props.item.placeholder}
                    notFoundContent="没有找到匹配的选择"
                    value={this.props.item.value}
                    onChange={this.stateChange.bind(this)}
                    onBlur={this.stateBlur.bind(this)} />
                {this.props.item.msg && <div className="require">{this.props.item.msg}</div>}
            </div>
        )
    }
}

export default Item