let global = {
    authority_id: "92517845", // 用户id
    tagIncremId: 1, // tag标签自增id
    pageIncremId: 1,
    maxPid: Number.MAX_SAFE_INTEGER, // 最大页面id
    option: {
        id: 0,
        mid: 0,
        required: true,
        title: "选项",
        placeholder: "选项",
        key: "option",
        width: 200,
        value: "",
        fileList: [],
        image: '',
        type: 0,
        jump: {
            required: false,
            title: "选项跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 200,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 200,
            default: "0" //索引值
        }
    },
    otherOption: {
        id: 0,
        mid: 0,
        required: false,
        title: "其他 ____",
        key: "otherOption",
        width: 200,
        value: "",
        image: "",
        type: 1,
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 200,
            default: "0" //索引值
        }
    },
    radio: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "标题",
            // msg: "请输入20个字以内的问卷名称"
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        options: []
    },
    checkbox: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "标题",
            // msg: "请输入20个字以内的问卷名称"
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        options: [],
        check_max: {
            required: false,
            title: "最多可选",
            key: "check_max",
            value: { key: "0", text: "不限" },
            defaultOptions: [{ key: "0", text: "不限" }, { key: "1", text: "1项" }, { key: "2", text: "2项" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        check_min: {
            required: false,
            title: "最少可选",
            key: "check_min",
            value: { key: "0", text: "不限" },
            defaultOptions: [{ key: "0", text: "不限" }, { key: "1", text: "1项" }, { key: "2", text: "2项" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
    },
    input: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "标题",
            // msg: "请输入20个字以内的问卷名称"
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        multi: {
            required: false,
            title: "文本框高度",
            key: "multi",
            value: "1",
            options: [{ key: "1", value: "单行" }, { key: "0", value: "多行" }],
        },
        data_type: {
            required: false,
            title: "验证属性",
            key: "data_type",
            value: { key: "0", text: "不限" },
            defaultOptions: [{ key: "0", text: "不验证" }, { key: "1", text: "文本格式" }, { key: "2", text: "数字格式" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        value: ""
    },
    image: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "标题",
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        button_txt: {
            required: true,
            key: "button_txt",
            notsameline: true,
            title: "按钮文案",
            value: "",
            placeholder: "选择图片",
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
    },
    mobile: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "请输入手机号",
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        value: ""
    },
    mail: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "请输入邮箱",
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        value: ""
    },
    date: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "请输入日期",
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        defaultDate: {
            required: false,
            key: "defaultDate",
            title: "默认日期",
            notsameline: false,
            value: "",
            width: "182px",
        },
        dateRange: {
            required: false,
            key: "dateRange",
            title: "日期范围",
            min: "1950-01-01",
            max: "2050-12-31",
            value: [],
            width: "240px",
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        value: ""
    },
    area: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "标题",
            value: "",
            placeholder: "请选择城市",
        },
        desc: {
            required: false,
            title: "题目描述",
            key: "desc",
            value: "",
        },
        checkRequire: {
            required: false,
            title: "是否必填",
            key: "checkRequire",
            value: "1",
            options: [{ key: "1", value: "必填" }, { key: "0", value: "非必填" }],
        },
        jump: {
            required: false,
            title: "题目跳题",
            key: "jump",
            value: { key: "0", text: "不跳题，按顺序填写" },
            defaultOptions: [{ key: "0", text: "不跳题，按顺序填写" }, { key: "-1", text: "跳到末尾，结束作答" }],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        tags: {
            required: false,
            title: "同步打标签",
            key: "tags",
            value: [],
            placeholder: "请选择或输入搜索",
            defaultOptions: [],
            options: [],
            width: 182,
            default: "0" //索引值
        },
        level: {
            required: false,
            title: "是否设置查看权限",
            tips: "设置查看权限后，仅高级管理员才能查看结果。",
            key: "level",
            value: "0",
            options: [{ key: "1", value: "是" }, { key: "0", value: "否" }],
        },
        value: ""
    },
    text: {
        style_type: "1",
        title: {
            required: false,
            key: "title",
            notsameline: true,
            title: "标题内容",
            value: "",
            placeholder: "请输入标题",
            fontSize: "16",
            fontWeight: "bold",
            color: "#323233",
            defaultColor: "#323233",
            pos: "left", // style_type == 1 时
            background: "#ffffff",
            defaultBackground: "#ffffff",
            line: "false",
            msg: "标题不能为空"
        },
        desc: {
            required: false,
            title: "描述内容",
            key: "desc",
            value: "",
            fontSize: "12",
            fontWeight: "normal",
            color: "#969799",
            defaultColor: "#969799",
            pos: "left", // style_type == 2 时
        },
        more: {
            key: "more",
            show: false,
            style: "1",
            title: "按钮文案",
            value: "查看更多",
            placeholder: "查看更多"
        },
        moreUrl: {
            notsameline: true,
            key: "moreUrl",
            title: "跳转链接",
            value: "",
            placeholder: "请输入链接"
        },
        wxUrl: {
            notsameline: true,
            key: "wxUrl",
            title: "跳转链接",
            value: "",
            placeholder: "请输入链接"
        },
        author: {
            notsameline: true,
            key: "author",
            title: "作者",
            value: "",
            placeholder: "请输入作者",
        },
        date: {
            notsameline: true,
            required: false,
            key: "date",
            title: "日期",
            value: "",
        },
        link_title: {
            notsameline: true,
            required: true,
            key: "link_title",
            title: "链接标题",
            value: "",
            placeholder: "请链接标题",
            msg: "链接标题不能为空"
        }
    },
    textarea: {
        content: {
            full: 0,
            defaultBackground: "#F9F9F9",
            background_color: "#F9F9F9",
            content: ""
        }
    },
    base: {
        title: {
            required: true,
            key: "title",
            value: "",
            title: "问卷名称",
            notsameline: true,
            placeholder: "20个字以内",
            msg: "请输入20个字以内的问卷名称"
        },
        dateRange: {
            required: true,
            notsameline: true,
            key: "dateRange",
            title: "有效期",
            value: [],
            msg: "请选择有效期"
        },
        loginInAdd: {
            required: false,
            value: "0"
        },
        focusInAdd: {
            required: false,
            value: "0",
        }
    },
    end: {
        title: {
            required: true,
            key: "title",
            notsameline: true,
            title: "提示文案",
            value: "",
            placeholder: "提交成功",
            // msg: <div className="required">请输入20个字以内的问卷名称</div>
        },
        custom: {
            required: true,
            key: "custom",
            notsameline: true,
            title: "自定义按钮",
            value: "",
            placeholder: "进店逛逛",
            // msg: <div className="required">请输入20个字以内的问卷名称</div>
        },
        seeResult: {
            required: false,
            title: "参与者查看结果",
            tips: "允许查看结果时，答题后可以看到所有选择题的投票结果。",
            key: "seeResult",
            value: "0",
            options: [{ key: "1", value: "允许" }, { key: "0", value: "不允许" }],
            text: ""
        },
        joinTimes: {
            required: false,
            title: "参与次数",
            tips: "无论用户提交多少次问卷，参与奖励只发放一次。",
            key: "joinTimes",
            value: "1",
            day: 0,
            number: 0,
            options: [{ key: "1", value: "每人参与一次" }, { key: "2", value: "每人可参与多次（仅最后提交结果计入数据）" }, { key: "3" }],
            msg: "请输入正整数"
        },
        prize: {
            required: false,
            title: "参与奖励",
            key: "prize",
            value: "0",
            options: [{ key: "1", value: "发放" }, { key: "0", value: "不发放" }],
        },
        giveObjects: {
            required: false,
            title: "发放对象",
            notsameline: true,
            key: "giveObjects",
            value: "1",
            options: [{ key: "1", value: "所有提交答卷用户" }, { key: "2", value: "指定客户身份", append: "special" }],
            special: {
                required: false,
                key: "special",
                placeholder: "选择或输入搜索",
                defaultOptions: [],
                notsameline: true,
                value: [],
                options: [],
                width: 182,
                default: "0", //索引值
                msg: "选择客户分群"
            }
        },
        choosePrize: {
            required: false,
            title: "选择奖励",
            key: "choosePrize",
            value: [],
            notsameline: true,
            options: [
                { key: "1", value: "优惠券", msg: "请选择优惠券", btn: "请选择优惠券", selectId: "", selectText: "", list: [], open: false, dist: false },
                { key: "2", value: "优惠码", msg: "请选择优惠码", btn: "请选择优惠码", selectId: "", selectText: "", list: [], open: false, dist: false },
                {
                    key: "3", value: "积分", score: {
                        key: "score",
                        title: "积分",
                        color: "#323233",
                        value: 0
                    }
                },
                { key: "4", value: "赠品", msg: "请选择赠品", btn: "请选择赠品", selectId: "", selectText: "", list: [], open: false, dist: false },
            ],
        },
        url: {
            required: true,
            key: "url",
            notsameline: true,
            title: "跳转链接",
            value: "",
            placeholder: "填写转到的页面",
        },
    }

}



export default global