import React from "react";
import { Button, Breadcrumb } from "zent";
import { withRouter } from 'react-router-dom';
import global from "@/utils/config";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }
    goList() {
        this.props.history.push({ pathname: "/list" });
    }
    state = {
    };
    preview() {
        // this.props.changePreviewMode();
        this.props.save("preview");
        // global.momentData = this.props.allData;
        // localStorage.setItem("wenjuan_preview_data", JSON.stringify(this.props.allData));
        // this.props.history.push({ pathname: "/preview" });
    }
    componentDidUpdate() {
    }
    componentDidMount() {
    }
    save() {
        this.props.save();
    }
    render() {
        return (
            <div className="w1_header">
                <Breadcrumb>
                    <Breadcrumb.Item name="问卷调查" style={{color: "#1765d9", cursor:"pointer"}} onClick={this.goList.bind(this)} />
                    <Breadcrumb.Item name={this.props.mode === "new" ? (this.props.successState == 1 ? "新建成功" : "新增问卷")
                        : (this.props.successState == 1 ? "编辑成功" : "编辑问卷")} />
                </Breadcrumb>
                {this.props.successState == 1 ? "" : <div className="w1_header_r">
                    <Button onClick={this.preview.bind(this)}>预览</Button>
                    <Button type="primary" onClick={this.save.bind(this)}>保存</Button>
                </div>}
            </div>
        )
    }
}

export default withRouter(Header)