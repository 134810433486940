import React from "react";
import "./preview.css";
import { withRouter } from 'react-router-dom'
import { Button, Notify } from 'zent';
import { getDetails,saveAnswer } from "@/api/index";
import dataFactory from "@/utils/dataFactory";
import Page from "./page";
import EndModule from "./components/end";
import CascaderModule from "./components/cascader";
import DatePickerModule from "./components/datePicker";
import getPageNum from "@/mixins/getPageNum";
import calcJumpNum from "@/mixins/calcJumpNum";
import store from "@/redux/common.js"
import Vconsole from 'vconsole'

class Preview extends React.Component {
    areaCascader = React.createRef();
    dateCascader = React.createRef();
    state = {
        aPIndex: 0,
        aPid: 0,
        aMIndex: 0,
        allData: {
            pages: []
        },
        jumpMap: {},
        jumpLast: 0,
        areaObj: {},
        dateRange: [],
        dateValue: "",
        nextPageIndex: 1,
        pageIndexList: [],
        errorNum: 0,
        erweimaUrl: "",
        systemState: 'PC',
        paperMode: 'preview',
        hasAnswerFlag: false
    }
    shutdown() {
        // 关闭
        localStorage.removeItem("wenjuan_preview_data");
        // this.props.changePreviewMode();
        let params = this.props.match.params;
        let id = params.id;
        if (id || id == 0) {
            this.props.history.push({ pathname: "/editpaper/" + id + "/" + params.type})
        } else {
            this.props.history.push({ pathname: "/" })
        }
    }
    updateMIndex(mid) {
        // 更新moduleIndex，只有选择日期和城市的时候用得到
        let index = this.state.allData.pages[this.state.aPIndex].modules.findIndex(m => {
            return m.id === mid;
        })
        this.state.aMIndex = index;
        this.setState({
            aMIndex: index
        });
    }
    updatePIndex() {
        // 更新当前页码
        let pages = this.state.allData.pages;
        for (let i = this.state.aPIndex - 1; i >= 0; i--) {
            if (pages[i].show) {
                this.setState({
                    aPIndex: i
                })
                break;
            }
        }
    }
    setShow() {
        // 所有的module添加显示隐藏比较show
        const pages = this.state.allData.pages;
        let jumpMap = this.state.jumpMap;
        pages.map(p => {
            let show = false;
            p.modules.map(m => {
                m.show = true;
                for (const key in jumpMap) {
                    if (Object.prototype.hasOwnProperty.call(jumpMap, key)) {
                        const jumpItem = jumpMap[key];
                        if (jumpItem.valid != "-1") {
                            if (m.num > key && m.num < jumpItem.to) {
                                m.show = false;
                                break;
                            }
                        }
                    }
                }
                // 如果有模块显示并且不是文本和富文本，这个页面就显示（即文本和富文本不能做为判断页面存在的依据）
                if (m.show && m.type != "text" && m.type != "textarea") {
                    show = true;
                }
                return m
            })
            // 给page添加显示隐藏标记
            p.show = show;
            return p
        })
    }
    questionChange(item, radioJump) {
        this.updateMIndex(item.id);
        // 除了单选的跳题外，其他跳题
        let jump = "";
        if (item.type != "radio") {
            jump = item.formData.jump.value.key;
        } else {
            jump = radioJump;
        }
        this.updateResult();
        if (jump && jump != "0" && item.formData.value && item.formData.value.length) {
            this.updateJumpMap(item.num, jump, "increase");
        } else {
            this.updateJumpMap(item.num, jump, "decrease");
        }

    }
    updateJumpMap(current, to, type) {
        // 更新跳转的map
        let jumpFirst = 0, jumpLast = 0;
        if (type == "decrease") {
            // 删除
            delete this.state.jumpMap[current];
        } else {
            //  增加
            this.state.jumpMap[current] = {
                to: to,
                valid: 1,
                pIndex: this.state.aPIndex
            }
        }
        let jumpMap = this.state.jumpMap;
        if (to != 0) {
            jumpFirst = current;
            jumpLast = to;
        } else {
            for (const key in jumpMap) {
                if (Object.prototype.hasOwnProperty.call(jumpMap, key)) {
                    const jumpItem = jumpMap[key];
                    if (jumpItem.pIndex == this.state.aPIndex) {
                        if (jumpItem.to > jumpLast) {
                            jumpLast = jumpItem.to;
                            jumpFirst = key;
                        }
                    }
                }
            }
        }

        for (const key in jumpMap) {
            if (Object.prototype.hasOwnProperty.call(jumpMap, key)) {
                const jumpItem = jumpMap[key];
                if ((key > jumpFirst && key < jumpLast) || (jumpItem.to > jumpFirst && jumpItem.to < jumpLast)) {
                    jumpItem.valid = -1
                } else {
                    jumpItem.valid = 1
                }
            }
        }
        this.setState({
            jumpLast: jumpLast
        })
        this.setShow();
        if (jumpLast == -1) {
        } else {
            this.setState({
                jumpMap: jumpMap,
            })
            if (current != 0 && jumpLast != 0) {
                this.checkCurrentPageQuestions("jump");
            }
        }
    }

    getNextPageIndex() {
        let pages = this.state.allData.pages;
        let npi = 0
        for (let index = this.state.aPIndex + 1; index < pages.length - 1; index++) {
            if (pages[index].show) {
                npi = index;
                break;
            }
        }
        return npi;
    }
    timeCheck(module) {
        let noPassMsg = "";
        let errorNum = 0;
        const value = module.formData.value;
        if (module.formData.checkRequire.value == 1 && (value == "" || value.length == 0)) {
            errorNum = module.num;
            return { errorNum }
        }
        if (value == "" || value.length == 0) {
            return {};
        }
        if (module.type === "checkbox") {
            // 多选题
            if (module.check_min != 0 && module.check_min != -1 && value.length < module.check_min) {
                errorNum = module.num;
                noPassMsg = "第" + module.num + "题最少选择" + module.check_min + "项";
                return { noPassMsg, errorNum }
            }
            if (module.check_max != 0 && module.check_max != -1 && value.length > module.check_max) {
                errorNum = module.num;
                noPassMsg = "第" + module.num + "题最多选择" + module.check_max + "项";
                return { noPassMsg, errorNum }
            }
        } else if (module.type === "mobile") {
            const mobileRegex = /^1[3456789]\d{9}$/;
            if (!mobileRegex.test(value)) {
                errorNum = module.num;
                noPassMsg = "第" + module.num + "题请填写正确的手机号码";
                return { noPassMsg, errorNum }
            }
        } else if (module.type === "mail") {
            const mailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if (!mailRegex.test(value)) {
                errorNum = module.num;
                noPassMsg = "第" + module.num + "题请填写正确的邮箱地址";
                return { noPassMsg, errorNum }
            }
        } else if (module.type === "input") {
            if (module.data_type === "1") {
                // 文本格式
            } else if (module.data_type === "2") {
                // 数字格式
                const numRegex = /^\d+$/;
                if (!numRegex.test(value)) {
                    errorNum = module.num;
                    noPassMsg = "第" + module.num + "题请输入数字";
                    return { noPassMsg, errorNum }
                }
            }
        }
        return {};
    }

    checkCurrentPageQuestions(type) {
        let npi = this.getNextPageIndex();
        const pages = this.state.allData.pages;
        const currentModules = pages[this.state.aPIndex].modules;
        let pass = 2; // 0 不通过，即有必选题未作答， 1 可进入下一页，但还有未作答的非必选题， 2 都已做完, 3 非必选题做了但是不符合要求
        let requiredNum = -1;
        let noPassMsg = "";
        let errorNum = 0;
        for (let i = 0; i < currentModules.length; i++) {
            const module = currentModules[i];
            console.log(module);
            if (module.show && module.type !== "text" && module.type !== "textarea") {
                const value = module.formData.value;
                console.log(value);
                if (value && value != 0) {
                    let timeCheckValue = this.timeCheck(module)
                    if (timeCheckValue.errorNum) {
                        errorNum = timeCheckValue.errorNum;
                        noPassMsg = timeCheckValue.noPassMsg;
                        pass = 3;
                        break;
                    }
                } else {
                    // 如果没值，校验必填
                    if (module.formData.checkRequire.value == 1) {
                        pass = 0;
                        requiredNum = module.num;
                        break;
                    } else {
                        pass = 1;
                    }
                }
            }
        }
        this.setState({
            "errorNum": errorNum
        })
        if (requiredNum > 0) {
            noPassMsg = "请完成第" + requiredNum + "题";
        }
        console.log(pass);
        if (pass) {
            const currentModule = currentModules[this.state.aMIndex];
            // 存在下一页，并且不等于当前页，自动跳转
            if (pass == 3) {
                if(currentModule.type !== "mobile" && currentModule.type !== "mail"){
                    Notify.warn(noPassMsg);
                }
                return
            }
            if (npi && npi != this.state.aPIndex && ((currentModule && currentModule.type == "radio" && (!currentModule.formData.otherOption || currentModule.formData.value != currentModule.formData.otherOption.id)) || type === "next")) {
                const nextPage = pages[npi];
                this.state.aPid = nextPage.id;
                const nextModules = nextPage.modules;
                for (let index = 0; index < nextModules.length; index++) {
                    const nm = nextModules[index];
                    if (nm.num == this.state.jumpLast) {
                        this.state.aMIndex = index;
                        break;
                    }
                }
                this.setState({
                    aPid: this.state.aPid,
                    aPIndex: npi,
                    aMIndex: this.state.aMIndex,
                })
            }
            if (type === "submit") {
                if(this.state.paperMode === 'preview'){
                    Notify.warn("预览页不能提交答卷")
                } else {
                    // 提交答案
                    dataFactory.formatSaveData(this.state.allData, (orderData) => {
                        saveAnswer({data: encodeURIComponent(JSON.stringify(orderData))}).then(res => {
                            console.log(res);
                            let hasAnswers = localStorage.getItem("iask_answer");
                            if(hasAnswers){
                                hasAnswers = JSON.parse(hasAnswers);
                            } else {
                                hasAnswers = [];
                            }
                            hasAnswers.push(this.props.match.params.id)
                            
                            localStorage.setItem("iask_answer", JSON.stringify(hasAnswers))
                            Notify.success("答卷提交成功");
                        }).catch(err => {
                            console.log(err);
                        })
                    });
                }
            }
        } else {
            if (type === "next" || type === "submit") {
                //点击下页页引起的校验
                Notify.warn("请完成第" + requiredNum + "题");
            }
        }
    }
    updateResult() {
        this.setState({
            allData: this.state.allData
        })
    }
    getAreaResult(data) {
        this.setState({
            areaObj: JSON.parse(JSON.stringify(data))
        })
        let text = data.provinceName + "/" + data.cityName + "/" + data.areaName;
        this.state.allData.pages[this.state.aPIndex].modules[this.state.aMIndex].formData.value = text;
        this.state.allData.pages[this.state.aPIndex].modules[this.state.aMIndex].formData.areaObj = JSON.parse(JSON.stringify(data));
        this.updateResult();
    }
    getDateResult(data) {
        this.setState({
            dateValue: data
        });
        this.state.allData.pages[this.state.aPIndex].modules[this.state.aMIndex].formData.value = data;
        this.updateResult();
    }
    showCascader(type) {
        if (type == "date") {
            let dateFormData = this.state.allData.pages[this.state.aPIndex].modules[this.state.aMIndex].formData;
            let dateRange = dateFormData.dateRange.value;
            let defaultDate = dateFormData.defaultDate.value;
            if (dateRange.length) {
                this.setState({
                    dateRange: dateRange,
                })
            }
            if (defaultDate) {
                this.setState({
                    defaultDate: defaultDate,
                })
            }
            this.setState({
                dateValue: dateFormData.value
            })
            this.dateCascader.current.showCascader();
        } else {
            this.setState({
                areaObj: Object.assign({}, this.state.allData.pages[this.state.aPIndex].modules[this.state.aMIndex].formData.areaObj)
            })
            setTimeout(() => {
                this.areaCascader.current.showCascader();
            }, 0)
        }
    }
    // 数据返结构化
    reverseOrderData(data) {
        dataFactory.reverseOrderData(data, (reverseData) => {
            getPageNum.calcPageNum(reverseData);
            calcJumpNum(reverseData);
            reverseData.pages.map(p => {
                p.show = true;
                p.modules.map(m => {
                    m.show = true;
                    return m;
                })
                return p;
            })
            this.setState({
                allData: reverseData,
                nextPageIndex: reverseData.pages.length - 2
            })
        })
    }
    componentDidMount() {
        new Vconsole();
        let id = this.props.match.params.id;
        if(this.props.match.params.type === "YW5zd2VybW9kZQ%3D%3D"){
            this.setState({
                paperMode: 'answer'
            })
            id = id.substr(13);
        }
        console.log(id, "id");
        this.setState({
            systemState: store.systemStore.getState()
        })
        if (id || id == 0) {
            let hasAnswerFlag = false;
            let hasAnswers = localStorage.getItem("iask_answer");
            if(hasAnswers){
                hasAnswers = JSON.parse(hasAnswers);
                if(hasAnswers.includes(this.props.match.params.id)){
                    hasAnswerFlag = true;
                }
            }
            if(hasAnswerFlag){
                this.setState({
                    hasAnswerFlag: hasAnswerFlag
                })
                Notify.info("您已经答过此问卷");
            } else {
                getDetails({ id: id }).then(res => {
                    console.log(res);
                    if (res.data.code == 1) {
                        this.reverseOrderData(res.data.msg);
                    } else {
                        Notify.error("没有这个问卷", 1500, () => {
                            this.props.history.push({ pathname: "/" })
                        })
                    }
                })
            }
        } else {
            Notify.error("没有这个问卷", 1500, () => {
                this.props.history.push({ pathname: "/" })
            })
        }

        //https://wenjuan.isv-dev.youzan.com/qr?authority_id=92517845&qid=222
        let origin = "https://wenjuan.isv-dev.youzan.com";
        if(window.location.hostname != "localhost"){
            origin = window.location.origin;
        }
        this.setState({
            erweimaUrl: origin + "/qr?authority_id=" + localStorage.getItem("wj_authority_id") + "&qid=" + id
        })
    }
    render() {
        return (
            <div className={`w2_container ${this.state.systemState !== 'PC' ? 'inPhone': ''} ${this.state.paperMode === 'preview' ? '' : 'answer'}`}>
                <div className="w2_header">
                    <div className="w2_h_center">
                        <div className="w2_h_center_left">
                            <span>预览: </span>
                            <span className="phone_mode">手机版</span>
                        </div>
                        <Button onClick={this.shutdown.bind(this)}>关闭</Button>
                    </div>
                </div>
                <div className="w2_content">
                    <div className={`w2_phone_area`}>
                        <div className="preview_top_tips">此为预览页面，不能提交答卷</div>
                        <div className="w2_phone_wrapper">
                            <div className="w2_phone_content">
                                <Page state={this.state}
                                    timeCheck={this.timeCheck.bind(this)}
                                    updateResult={this.updateResult.bind(this)}
                                    questionChange={this.questionChange.bind(this)}
                                    updateMIndex={this.updateMIndex.bind(this)}
                                    modules={this.state.allData.pages[this.state.aPIndex] ? this.state.allData.pages[this.state.aPIndex].modules : []}
                                    paperMode={this.state.paperMode}
                                    showCascader={this.showCascader.bind(this)}></Page>
                            </div>
                        </div>
                        <EndModule pIndex={this.state.aPIndex}
                            jumpLast={this.state.jumpLast}
                            updatePIndex={this.updatePIndex.bind(this)}
                            checkCurrentPageQuestions={this.checkCurrentPageQuestions.bind(this)}
                            pLength={this.state.allData.pages && this.state.allData.pages.length - 1}></EndModule>
                        {/* 城市选择插件 */}
                        <CascaderModule
                            areaObj={this.state.areaObj}
                            ref={this.areaCascader}
                            getAreaResult={this.getAreaResult.bind(this)}
                        ></CascaderModule>
                        {/* 日期插件 */}
                        <DatePickerModule
                            dateValue={this.state.dateValue}
                            dateRange={this.state.dateRange}
                            defaultDate={this.state.defaultDate}
                            ref={this.dateCascader}
                            getDateResult={this.getDateResult.bind(this)}></DatePickerModule>
                    </div>
                    {/* {this.state.erweimaUrl && <div className="preview_in_mobile">
                        <p>微信扫一扫<br></br>在手机上预览</p>
                        <img src={this.state.erweimaUrl}></img>
                    </div>} */}
                </div>
            </div>
        )
    }
}

export default withRouter(Preview)