import React, {useState} from "react"
import { Link,withRouter } from "react-router-dom";
import { Button } from 'zent';
import Popularize from "../grid/popularize";
import success1 from "@/assets/images/icons/success.png";

function SuccessPage(props) {
    const [visible, setVisible] = useState(false);
    const [pimg, setPimg] = useState("");
    const [copyUrl, setCopyUrl] = useState("");
    
    function open(){
        setVisible(true);
        // 推广
        if (localStorage.getItem("wj_authority_id") == "92517845") {
            setPimg("https://wenjuan.isv-dev.youzan.com/qr?authority_id="+localStorage.getItem("wj_authority_id")+"&qid="+props.qid)
        } else {
            setPimg("https://wenjuan.isv.youzan.com/qr?authority_id="+localStorage.getItem("wj_authority_id")+"&qid="+props.qid)
        }
        setCopyUrl("/packages/wenjuan/login/index?id=" + props.qid)
    }
    function close(){
        setVisible(false);
    }
    function reNew(){
        if(props.mode === "edit"){
            props.history.replace("/newPaper");
        } else {
            window.location.reload();
        }
    }
    return (
        <div className="w1_container">
            <div className="w1_content success_wrapper">
                <div className="success_box">
                    <img src={success1}></img>
                    <p className="success_title">{props.mode === "edit" ? "问卷编辑成功" : "问卷新建成功"}</p>
                    <div className="success_tips">立即推广，回收答卷</div>
                    <Button type="primary" icon="share" className="success_share" onClick={open}>立即推广</Button>
                    <div className="go_on_btns">
                        <div className="go_new" onClick={reNew.bind(this)}>继续新建</div><span>|</span><Link to={{ pathname: "/list" }}>返回列表</Link>
                    </div>
                </div>
            </div>
            <Popularize onclose={close} visible={visible} pimg={pimg} copyUrl={copyUrl} qid={props.qid}></Popularize>
        </div>
    )
}

export default withRouter(SuccessPage)