import { createStore } from 'redux';

function saveUser(nickname = '', action) {
  switch (action.type) {
    case 'add':
      console.log(action);
      nickname = action.nickname;
      return nickname;
    default:
      return nickname;
  }
}



// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
let userStore = createStore(saveUser);
const store = {
  userStore
};

export default store;