import React from 'react';
import { Grid, FullScreenLoading, Notify } from 'zent';
import ControlBtns from './controls';
import WrapLine from "./wrapLine"
import "./grid.css";
import {juanList} from "@/api/index"

const columns = [
    {
        title: "问卷id",
        name: 'id',
        className: 'name',
        width: 200,
    },
    {
        title: <WrapLine text="问卷名称"></WrapLine>,
        name: 'name',
        className: 'name',
        width: 300,
    }, {
        title: '有效时间',
        name: 'time',
        width: 240
    }, {
        title: '创建时间',
        name: 'create_time',
        width: 240
    }, {
        title: '状态',
        name: 'stateName',
        width: 100,
        bodyRender: (data) => {
            if (data.state == 3) {
                return (
                    <div className="row_status">{data.stateName}</div>
                );
            } else {
                return (
                    <div>{data.stateName}</div>
                );
            }
        }
    }, {
        title: '回收问卷数',
        name: 'num',
        width: 100
    }, {
        title: '操作',
        name: 'control',
        textAlign: 'right'
    }
];
/**
 * - 未开始0：推广 | 编辑 | 复制
    - 进行中1：暂停回收 | 统计数据 | 推广 | 编辑 | 复制
    - 暂停中2：开始回收 | 统计数据  | 编辑 | 复制（暂停时不能推广）
    - 已结束3：统计数据 | 复制 | 删除
备注：如回收问卷数为0，则不展示“统计数据”操作；
 */

/* const stateDic = {
    '0': '未开始',
    '1': '进行中',
    '2': '暂停中',
    '3': '已结束'
}
const datasets = [];

for (let i = 0; i < 5; i++) {
    let state = i;
    state > 3 ? state -= 2 : '';
    let num = i * 2;
    let id = `id-${i}`
    datasets.push({
        id,
        name: <WrapLine text={`asdsssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss${i}`}></WrapLine>,
        time: <WrapLine text={'2020-01-21 12:00:00 至 2020-02-01 23:59:59'}></WrapLine>,
        state: state,
        stateName: stateDic[state],
        num: num,
        control: <ControlBtns state={state} num={num} id={id}></ControlBtns>
    })
} */


class WjGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            datasets:[]
        };
    }

    refresh = () => {
        this.getData();
    }

    onChange = ({ current, pageSize }) => {
        this.setState({
            current
        })
        this.getData();
    };

    setHeight() {
        let wj_table_wrapper = document.getElementById("wj_table_wrapper");
        let tableHeight = wj_table_wrapper.offsetHeight - 56 - 53 + "px";
        wj_table_wrapper.querySelector(".zent-grid-body").style.maxHeight = tableHeight;
        wj_table_wrapper.querySelector(".zent-grid-body-outer").style.height = tableHeight;
    }

    getData(){
        const stateDic = {
            '-1': '暂停中',
            '1': '未开始',
            '2': '进行中',
            '3': '已结束'
        }
        this.setState({
            loading: true
        })
        juanList({current: this.state.current, pageSize: this.state.pageSize,search: this.props.search_txt, status: this.props.status.key}).then(res => {
            let datasets = [];
            if(res.data.code === 1){
                let data = res.data.data;
                if(data.list){
                    data.list.map(one => {
                        datasets.push({
                            id: one.id,
                            name: <WrapLine text={one.name}></WrapLine>,
                            time: <WrapLine text={one.begin_time + " 至 " + one.end_time}></WrapLine>,
                            create_time: one.create_time,
                            state: one.status,
                            stateName: stateDic[one.status],
                            num: one.count,
                            control: <ControlBtns state={one.status} num={one.count} id={one.id} history={this.props.history} refresh={this.refresh}></ControlBtns>
                        })
                    });
                    this.setState({
                        datasets: datasets,
                        total: data.total
                    })
                }
            } else {
                Notify.info(res.data.msg)
            }
            this.setState({
                loading: false
            })
        }).catch(err => {
            Notify.info(err.message)
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount() {
        this.getData();
        this.setHeight();
        window.addEventListener("resize", this.setHeight)
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.setHeight);
    }

    render() {
        return (
            <div>
                <FullScreenLoading loading={this.state.loading} icon="circle"/>
                <Grid
                    columns={columns}
                    datasets={this.state.datasets}
                    scroll={{ y: 100 }}
                    rowClassName={(data, index) => `${data.id}-${index}`}
                    pageInfo={{
                        current: this.props.data.current,
                        pageSize: this.state.pageSize,
                        total: this.props.data.total,
                    }}
                    onChange={this.onChange}
                    ellipsis
                />
            </div>

        )
    }
}
export default WjGrid