import React from "react";
import { DatePicker } from 'zent';
import star1 from "@/assets/images/icons/star.png";

/**
 * @description 日期组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        dateRangeDist:false
    }
    onChangeRange = val => {
        this.props.setValue(this.props.item.key, val)
    };
    dateBlur(e) {
        this.setState({ dateRangeDist: true });
    }

    render() {
        return (
            <div className={`w1_form_item date_range ${!this.props.item.notsameline && "sameline"} ${this.props.item.value.length ? "fill" : ""} ${this.state.dateRangeDist ? "dist" : ''}`}>
                <div className="name_line">
                    <div>
                        {this.props.item.required && <img src={star1}></img>}
                        <span>{this.props.item.title}</span>
                    </div>
                </div>
                <DatePicker
                    className="zent-datepicker-demo"
                    value={this.props.item.value}
                    onChange={this.onChangeRange.bind(this)}
                    // dateSpan={30}
                    canClear={false}
                    width={this.props.item.width || "100%"}
                    onClose={this.dateBlur.bind(this)}
                />
                {this.props.item.msg && <div className="require">{this.props.item.msg}</div>}
            </div>
        )
    }
}

export default Item