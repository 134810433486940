import React from "react";
import { Icon, Notify } from "zent";
import global from "@/utils/config";
import single1 from "@/assets/images/btns/single.png";
import single2 from "@/assets/images/btns/single_select.png";
import checkbox1 from "@/assets/images/btns/checkbox.png";
import checkbox2 from "@/assets/images/btns/checkbox_select.png";
import input1 from "@/assets/images/btns/input.png";
import input2 from "@/assets/images/btns/input_select.png";
import image1 from "@/assets/images/btns/image.png";
import image2 from "@/assets/images/btns/image_select.png";
import phone1 from "@/assets/images/btns/phone.png";
import phone2 from "@/assets/images/btns/phone_select.png";
import email1 from "@/assets/images/btns/email.png";
import email2 from "@/assets/images/btns/email_select.png";
import date1 from "@/assets/images/btns/date.png";
import date2 from "@/assets/images/btns/date_select.png";
import area1 from "@/assets/images/btns/area.png";
import area2 from "@/assets/images/btns/area_select.png";
import title1 from "@/assets/images/btns/title.png";
import title2 from "@/assets/images/btns/title_select.png";
import textarea1 from "@/assets/images/btns/textarea.png";
import textarea2 from "@/assets/images/btns/textarea_select.png";

const headerHeight = 56;
let headHeight = 40 + 64;
const maxPageId = global.maxPid;
class Left extends React.Component {
    constructor(props) {
        super(props);
        this.muh = this.mouseUpHandler.bind(this);
        this.mmh = this.mouseMoveHandler.bind(this);
        this.mwh = this.mouseWheelHandler.bind(this);
        this.wrh = this.windowResizeHandler.bind(this);
        document.addEventListener("mouseup", this.muh);
        document.addEventListener("mousemove", this.mmh);
        if (document.addEventListener)//FF,火狐浏览器会识别该方法
            document.addEventListener('DOMMouseScroll', this.mwh, false);
        document.addEventListener("mousewheel", this.mwh);
        window.addEventListener("resize", this.wrh);
    }
    componentWillUnmount() {
        document.removeEventListener("mouseup", this.muh);
        document.removeEventListener("mousemove", this.mmh);
        document.removeEventListener('DOMMouseScroll', this.mwh);
        document.removeEventListener("mousewheel", this.mwh);
        window.removeEventListener("resize", this.wrh);
    }
    state = {
        moveStatus: 0, // 1 鼠标按下， 2 鼠标抬起
        activeDom: null, // 移动的module克隆
        disLeft: 0, // 点击点距左边距离
        disTop: 0, // 点击点距上边距离
        contentRect: {}, // 放置区的rect
        allRect: [], // 所有已添加模块的rect
        addIndex: -1, // 要添加到第几个索引之后
        canStatus: 0, // 是否在放置区， 0 不在，1在

        aBtn: null,
        regetFlag: true,
        freezeIndex: -1,
        storeRect: null,
        storeDirection: null
    }
    tools = [{
        title: "基础分类",
        height: "152px",
        children: [
            {
                title: "单选题",
                icon: single1,
                icon_select: single2,
                type: "radio",
                required: true
            },
            {
                title: "多选题",
                icon: checkbox1,
                icon_select: checkbox2,
                type: "checkbox",
                required: true
            },
            {
                title: "填空题",
                icon: input1,
                icon_select: input2,
                type: "input"
            },
            {
                title: "图片题",
                icon: image1,
                icon_select: image2,
                type: "image"
            }
        ]
    }, {
        title: "信息收集",
        height: "152px",
        children: [
            {
                title: "手机号",
                icon: phone1,
                icon_select: phone2,
                type: "mobile"
            },
            {
                title: "邮箱",
                icon: email1,
                icon_select: email2,
                type: "mail"
            },
            {
                title: "日期",
                icon: date1,
                icon_select: date2,
                type: "date"
            },
            {
                title: "省/市/区",
                icon: area1,
                icon_select: area2,
                type: "area"
            }
        ]
    }, {
        title: "微页面组件",
        height: "76px",
        children: [
            {
                title: "标题文本",
                icon: title1,
                icon_select: title2,
                type: "text"
            },
            {
                title: "富文本",
                icon: textarea1,
                icon_select: textarea2,
                type: "textarea"
            }
        ]
    }]

    decorationTools = [{
        title: "基本组件",
        height: "768px",
        children: [
            /* {
                title: "标题文本",
                icon: require("@/assets/images/decoration/title.png"),
                icon_select: require("@/assets/images/decoration/title_s.png"),
                type: "title"
            },
            {
                title: "商品",
                icon: require("@/assets/images/decoration/goods.png"),
                icon_select: require("@/assets/images/decoration/goods_s.png"),
                type: "title"
            },
            {
                title: "图片广告",
                icon: require("@/assets/images/decoration/img_ads.png"),
                icon_select: require("@/assets/images/decoration/img_ads_s.png"),
                type: "title"
            },
            {
                title: "图文导航",
                icon: require("@/assets/images/decoration/img_text.png"),
                icon_select: require("@/assets/images/decoration/img_text_s.png"),
                type: "title"
            },
            {
                title: "富文本",
                icon: require("@/assets/images/decoration/textarea.png"),
                icon_select: require("@/assets/images/decoration/textarea_s.png"),
                type: "title"
            },
            {
                title: "魔方",
                icon: require("@/assets/images/decoration/mofang.png"),
                icon_select: require("@/assets/images/decoration/mofang_s.png"),
                type: "title"
            },
            {
                title: "辅助空白",
                icon: require("@/assets/images/decoration/blank.png"),
                icon_select: require("@/assets/images/decoration/blank_s.png"),
                type: "title"
            },
            {
                title: "商品搜索",
                icon: require("@/assets/images/decoration/search.png"),
                icon_select: require("@/assets/images/decoration/search_s.png"),
                type: "title"
            },
            {
                title: "店铺信息",
                icon: require("@/assets/images/decoration/shop.png"),
                icon_select: require("@/assets/images/decoration/shop_s.png"),
                type: "title"
            },
            {
                title: "公告",
                icon: require("@/assets/images/decoration/notice.png"),
                icon_select: require("@/assets/images/decoration/notice_s.png"),
                type: "title"
            },
            {
                title: "视频",
                icon: require("@/assets/images/decoration/video.png"),
                icon_select: require("@/assets/images/decoration/video_s.png"),
                type: "title"
            },
            {
                title: "语音",
                icon: require("@/assets/images/decoration/voice.png"),
                icon_select: require("@/assets/images/decoration/voice_s.png"),
                type: "title"
            },
            {
                title: "自定义模块",
                icon: require("@/assets/images/decoration/custom.png"),
                icon_select: require("@/assets/images/decoration/custom_s.png"),
                type: "title"
            },
            {
                title: "关注公众号",
                icon: require("@/assets/images/decoration/focus.png"),
                icon_select: require("@/assets/images/decoration/focus_s.png"),
                type: "title"
            },
            {
                title: "知识专栏",
                icon: require("@/assets/images/decoration/knowledge_area.png"),
                icon_select: require("@/assets/images/decoration/knowledge_area_s.png"),
                type: "title"
            },
            {
                title: "知识付费会员",
                icon: require("@/assets/images/decoration/knowledge_pay.png"),
                icon_select: require("@/assets/images/decoration/knowledge_pay_s.png"),
                type: "title"
            } */
        ]
    }]

    toggle(index) {
        let manageDom = null;
        let list = this.tools;
        if (this.props.activePage.id === maxPageId) {
            list = this.decorationTools;
            manageDom = list[index].manage.current;
        } else {
            manageDom = list[index].manage.current;
        }
        if (getComputedStyle(manageDom).height !== "0px") {
            manageDom.style.height = "0px";
        } else {
            manageDom.style.height = manageDom.getAttribute("self-height");
        }
        this.setDomRect(list);
    }

    /**
     * @description 检测点和区域相交
     */
    pointInRect(event, rect) {
        let left = event.pageX, top = event.pageY;
        if (left >= rect.left && left <= rect.right && top >= rect.top && top <= rect.bottom) {
            return true;
        }
        return false;
    }

    /**
     * @description 获取当前激活页面的所有模块的rect
     */
    getAllRect() {
        let phoneArea = this.props.phoneArea.current;
        let allPanels = phoneArea.querySelectorAll(".zent-tabs-panel");
        allPanels = [...allPanels];
        let activePanel = allPanels[allPanels.findIndex(one => {
            return getComputedStyle(one).display === "block";
        })];
        let allModules = activePanel.querySelectorAll(".preview_module_wrapper");
        let rects = [];
        for (let i = 0, ilen = allModules.length; i < ilen; i++) {
            rects.push(allModules[i].getBoundingClientRect());
        }
        this.state.allRect = rects;
    }

    /**
     * @description 在哪里显示 可添加区
     */
    showPutArea(rect, direction) {
        let storeRect = this.state.storeRect || {};
        if (this.state.storeDirection == direction) {
            if (storeRect.top == rect.top && storeRect.height == rect.height) {
                return
            }
        } else {
            if (storeRect.top == rect.bottom || storeRect.bottom == rect.top) {
                return
            }
        }
        this.setState({
            storeRect: rect,
            storeDirection: direction
        })
        let putAreaDom = this.props.putArea.current;

        this.clearMtMb();
        const tabDoms = document.querySelectorAll(".zent-tabs-panel");
        // 有隐藏的tab，从之中找到显示的那个
        let activeTab = null;
        for (let index = 0; index < tabDoms.length; index++) {
            const tabDom = tabDoms[index];
            if (tabDom.style.display === "none") {
                continue;
            }
            activeTab = tabDom;
            if (activeTab) {
                break;
            }
        }
        let intersetionDom = activeTab.querySelectorAll(".preview_module_wrapper")[this.state.addIndex];
        let thisOffsetTop = document.querySelector(".w1_center_phone").offsetTop;
        let top = rect.top - thisOffsetTop - headerHeight;
        if (direction === "up") {
            putAreaDom.style.top = top + "px";
            let cArray = intersetionDom.className.split(" ")
            cArray.push("mt50");
            intersetionDom.className = [...new Set(cArray)].join(' ');
        } else {
            if (intersetionDom) {
                let cArray = intersetionDom.className.split(" ")
                cArray.push("mt50");
                intersetionDom.className = [...new Set(cArray)].join(' ');
            }
            putAreaDom.style.top = rect.height + top + "px";
        }
    }

    clearMtMb() {
        document.querySelectorAll(".preview_module_wrapper").forEach(pmw => {
            let classArray = pmw.className.split(" ");
            classArray = classArray.filter(c => {
                if (c == "mt50") {
                    return false
                }
                return c;
            })
            pmw.className = classArray.join(" ");
        })
    }

    // 展示不可放置区
    showCanNotPut(event) {
        let canNotPut = this.props.canNotPut.current;
        let thisOffsetTop = document.querySelector(".w1_center_phone").offsetTop;
        let top = event.pageY - thisOffsetTop - headerHeight;
        canNotPut.style.top = top - 24 + "px"
    }

    windowResizeHandler() {
        this.state.contentRect = document.querySelector('.zent-tabs-panel-wrapper').getBoundingClientRect();
        this.changeCenterStyle();
    }
    changeCenterStyle() {
        let centerWidth = document.querySelector(".w1_center_right").offsetWidth;
        if (centerWidth < 600) {
            document.querySelector(".w1_center_right").className = "w1_center_right small";
        } else {
            document.querySelector(".w1_center_right").className = "w1_center_right";
        }
    }
    mouseWheelHandler() {
        this.getAllRect();
        this.props.updateStateByScrollHeight();
    }
    mouseDownHandler(item, event) {
        event.persist();
        if (item.type === "textarea" && !this.props.textareaValid) {
            Notify.error("富文本模块暂不可用，请联系管理员");
            return false;
        }
        this.state.moveStatus = 1;

        this.state.aBtn = item;
        this.state.disLeft = event.pageX - item.boundLeft;
        this.state.disTop = event.pageY - item.boundTop;
        var positionX = event.pageX - this.state.disLeft + "px";
        var positionY = event.pageY - this.state.disTop + "px";
        let activePage = this.props.activePage;
        let modules = activePage.modules;
        if (activePage.freezeModuleNum) {
            for (let len = modules.length - 1, index = len; index >= 0; index--) {
                if (modules[index].freezeOptionNum) {
                    this.setState({
                        freezeIndex: index
                    })
                    break;
                }
            }
        }
        this.state.moveRef = React.createRef();
        this.getAllRect();
        this.setState({
            "activeDom": <div className="move_cell"
                ref={this.state.moveRef}
                style={{ left: positionX, top: positionY }}>
                <img src={item.icon_select}></img>
                <span>{item.title}</span>
            </div>
        })
    }

    moveInPhone(dom, event) {
        if (this.state.moveStatus === 1 || this.props.previewMoveStatus === 1) {
            let disLeft = this.state.disLeft, disTop = this.state.disTop;
            if (this.props.previewMoveStatus === 1) {
                disLeft = this.props.disLeft;
                disTop = this.props.disTop;
            }
            dom.style.left = event.pageX - disLeft + "px";
            dom.style.top = event.pageY - disTop + "px";
            if (this.pointInRect(event, this.state.contentRect)) {
                this.state.canStatus = 1;
                // console.log("进入手机屏幕区");
                let allRect = this.state.allRect;
                if (allRect.length === 0) {
                    this.props.putArea.current.style.top = headHeight + "px";
                    this.state.addIndex = 0;
                    this.props.changeCanStatus(1);
                } else {
                    for (let i = 0, len = allRect.length; i < len; i++) {
                        let thisRect = allRect[i];
                        if (this.props.previewMoveStatus === 1) {
                            if (thisRect.left === this.props.moveDomRect.left && thisRect.top === this.props.moveDomRect.top) {
                                continue;
                            }
                        }
                        if (this.pointInRect(event, thisRect)) {
                            if (
                                (this.state.aBtn
                                    && (this.state.aBtn.type === "text" || this.state.aBtn.type === "textarea"))
                                || (this.props.moveActiveType === "text" || this.props.moveActiveType === "textarea")
                            ) {
                                // 添加时候
                                this.props.changeCanStatus(1);
                                if (event.pageY < (thisRect.top + thisRect.bottom) / 2) {
                                    // 上边
                                    this.state.addIndex = i;
                                    this.showPutArea(thisRect, "up");
                                } else {
                                    this.state.addIndex = i + 1;
                                    // 下边
                                    this.showPutArea(thisRect, "down");
                                }
                            } else {
                                let modules = this.props.activePage.modules;
                                let activeModule = modules[i];
                                if (activeModule.mdStatus === "moveValid"
                                    || activeModule.mdStatus === "lastOne") {


                                    if (activeModule.mdStatus === "lastOne") {
                                        if (this.state.moveStatus === 1) {
                                            if (event.pageY < (thisRect.top + thisRect.bottom) / 2) {
                                                this.props.changeCanStatus(2);
                                                this.showCanNotPut(event);
                                            } else {
                                                this.state.addIndex = i + 1;
                                                if (this.state.addIndex <= this.state.freezeIndex) {
                                                    this.props.changeCanStatus(2, "limitEdit");
                                                    this.showCanNotPut(event);
                                                } else {
                                                    this.props.changeCanStatus(1);
                                                    // 下边
                                                    this.showPutArea(thisRect, "down");
                                                }
                                            }
                                        }
                                        if (this.props.previewMoveStatus === 1) {
                                            this.props.changeCanStatus(2);
                                            this.showCanNotPut(event);
                                        }
                                    } else {
                                        if (event.pageY < (thisRect.top + thisRect.bottom) / 2) {
                                            // 上边
                                            this.state.addIndex = i;
                                            if (this.state.addIndex <= this.state.freezeIndex) {
                                                this.props.changeCanStatus(2, "limitEdit");
                                                this.showCanNotPut(event);
                                            } else {
                                                this.props.changeCanStatus(1);
                                                this.showPutArea(thisRect, "up");
                                            }
                                        } else {
                                            this.state.addIndex = i + 1;
                                            if (this.state.addIndex <= this.state.freezeIndex) {
                                                this.props.changeCanStatus(2, "limitEdit");
                                                this.showCanNotPut(event);
                                            } else {
                                                // 下边
                                                this.props.changeCanStatus(1);
                                                this.showPutArea(thisRect, "down");
                                            }
                                        }
                                    }

                                } else {
                                    this.props.changeCanStatus(2);
                                    this.showCanNotPut(event);
                                }
                            }
                            break;
                        }
                    }
                }
            } else {
                if (this.state.canStatus === 1) {
                    this.state.canStatus = 0;
                    this.props.changeCanStatus(0);
                }
            }
        }
        if (this.props.previewMoveStatus === 3) {
            this.props.changeCanStatus(3);
            this.showCanNotPut(event);
        }
    }

    mouseMoveHandler(event) {
        let dom = null;
        if (this.props.previewMoveStatus === 1) {
            if (this.state.regetFlag) {
                this.state.regetFlag = false;
                this.getAllRect();
            }
            dom = this.props.moveDom;
        } else if (this.state.moveStatus === 1) {
            dom = this.state.moveRef.current;
        }
        this.moveInPhone(dom, event);
    }

    mouseUpHandler(event) {
        this.state.moveStatus = 2;
        let moveModuleFlag = false;
        if (this.props.previewMoveStatus === 1 || this.props.previewMoveStatus === 3) {
            this.state.regetFlag = true;
            moveModuleFlag = true;
            this.props.updatePreviewMoveStatus(2);
        }

        // 在放置区内
        if (this.state.canStatus === 1) {
            // 在可放置区内
            // if (this.pointInRect(event, this.props.putArea.current.getBoundingClientRect())) {
            if (this.props.putArea.current.style.display === "flex") {
                let aBtn = Object.assign({}, this.state.aBtn);
                delete aBtn.boundLeft;
                delete aBtn.boundTop;
                delete aBtn.icon;
                delete aBtn.icon_select;
                delete aBtn.self;
                if (moveModuleFlag) {
                    aBtn.type = this.props.moveActiveType;
                    this.props.setBtn(aBtn, this.state.addIndex, moveModuleFlag);
                } else {
                    this.props.setBtn(aBtn, this.state.addIndex);
                }
            }
            // }
        }

        this.clearMtMb();
        this.state.canStatus = 0;
        this.props.changeCanStatus(0);
        this.setState({
            "activeDom": null,
            "storeRect": null,
            "storeDirection": null
        })
    }

    setDomRect() {
        let list = this.tools;
        if (this.props.activePage.id === maxPageId) {
            list = this.decorationTools;
        }
        list.map((item) => {
            item.children.map((li) => {
                let rect = li.self.current.getBoundingClientRect();
                li.boundLeft = rect.left;
                li.boundTop = rect.top;
            })
        })
    }
    componentDidUpdate() {
    }
    componentDidMount() {
        this.changeCenterStyle();
        this.state.contentRect = document.querySelector('.zent-tabs-panel-wrapper').getBoundingClientRect();
        document.querySelector(".decoration_tools").style.display = "block";
        document.querySelector(".decoration_tools").style.display = "none";
        this.setDomRect();
    }

    updateState() {
        this.setState({
            aBtn: null
        })
    }
    getLis(list) {
        return list.map((item, index) => {
            item.manage = React.createRef();
            item.manageIcon = React.createRef();

            var lis = item.children.map((li, i) => {
                li.self = React.createRef();
                return <li key={"li" + i} className="tool_item"
                    onMouseDown={this.mouseDownHandler.bind(this, li)} ref={li.self}>
                    <img src={li.icon}></img>
                    <span>{li.title}</span>
                </li>
            })

            return <div className="w1_panel_item" key={"item" + index}>
                <div className="w1_panel_item_title" onClick={this.toggle.bind(this, index)}>
                    <Icon type="caret-down" ref={item.manageIcon} /><span>{item.title}</span>
                </div>
                <ul ref={item.manage} style={{ height: item.height }}>
                    {lis}
                </ul>
            </div>
        })
    }

    render() {
        return (
            <div className="w1_left">
                <div className="w1_panel">
                    <div className="w1_menu_wrapper">
                        <div style={{ display: (this.props.activePage.id === maxPageId ? "none" : "block") }} className="tools">{this.getLis(this.tools)}</div>
                        <div style={{ display: (this.props.activePage.id === maxPageId ? "block" : "none") }} className="decoration_tools">{this.getLis(this.decorationTools)}</div>
                        <div className="control_info"><Icon type="info-circle-o" />&nbsp;&nbsp;以上组件只支持拖拽不支持点击</div>
                    </div>
                </div>
                <div>{this.state.activeDom}</div>
            </div>
        )
    }
}

export default Left