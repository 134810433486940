import React from "react";
import { Checkbox } from 'zent';

/**
 * @description 单个判断
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    handleChange(e) {
        this.props.setAttr(this.props.key1, this.props.key2 ,e.target.checked)
    }

    render() {
        return (
            <div className={`w1_form_item sameline text_line`}>
                <div className="name_line">
                    <span>{this.props.title}</span>
                    <span className="line_value">{this.props.value ? (this.props.trueValue || "显示") : (this.props.falseValue || "不显示")}</span>
                </div>
                <Checkbox checked={this.props.value} onChange={this.handleChange.bind(this)}></Checkbox>
            </div>
        )
    }
}

export default Item