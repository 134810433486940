import React from "react";
import ColorLine from "./formItem/colorLine";
import UnderLine from "./formItem/underLine";
import Ueditor from "./formItem/ueditor";
class TextareaModule extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
    }

    editorRef = React.createRef();

    setAttr(key1, key2, value) {
        this.props.activeModule.formData[key1][key2] = value;
        this.props.updateRequire();
    }

    componentDidUpdate() {
    }

    componentDidMount() {
        let ueditor = document.body.querySelector("#ueditor");
        let iwindow = null;
        if(ueditor){
            let iframeholder = ueditor.querySelector(".edui-editor-iframeholder");
            if(iframeholder){
                iwindow = iframeholder.querySelector("iframe").contentWindow;
            }
        }
        let iframeStyle = document.createElement("style");
        iframeStyle.innerHTML = 'img{display:block;max-width:328px;}p{margin:0;}';
        setTimeout(function(){
            if(iwindow && iwindow.document){
                iwindow.document.head.appendChild(iframeStyle)
            }
        },0);
    }


    render() {
        return (
            <div className="w1_single w1_base">
                <ColorLine title="背景颜色" defaultColor={this.props.activeModule.formData.content.defaultBackground} key1="content" key2="background_color" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.content.background_color}></ColorLine>
                <UnderLine title="是否全屏显示" trueValue="全屏显示" falseValue="不全屏显示" key1="content" key2="full" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.content.full}></UnderLine>
                <Ueditor id="ueditor" key1="content" key2="content" setAttr={this.setAttr.bind(this)} value={this.props.activeModule.formData.content.content}></Ueditor>
            </div>
        )
    }
}

export default TextareaModule