// import React from "react";

const calcJumpFrom = function (allData, startNum, jumpKey, pStart, optionIndex) {
    let pages = allData.pages;
    let linNum = 0;

    for (let fi = 0; fi < pages.length - 1; fi++) {
        let modules = pages[fi].modules;
        for (let fj = 0; fj < modules.length; fj++) {
            const module = modules[fj];
            if(module.type === "text" || module.type === "textarea"){
                continue;
            }
            linNum++;
            if (linNum == jumpKey) {
                module.jumpFromPage = pStart + 1;
                module.jumpFrom = startNum;
                if (optionIndex || optionIndex == 0) {
                    module.jumpFromOption = optionIndex + 1;
                }
                break;
            }
        }
    }
}

/**
 * @description 
 * 基本状态 moveValid （随便搞）,moveInvalid （不能移动和删除）,deleteInvalid （设置跳题的题）,endDeleteInvalid (设置了被跳的题),canDeleteLastOne,lastOne （被跳的最后一题）,jumpToEndLastOne （设置了跳到结束的最后一题）, toEnd (结束答题)
 * 特殊状态（文本、富文本） moveCanNotAdd
 * 可以删除的状态 moveValid,moveInvalid,canDeleteLastOne
 * 不能删除的状态 deleteInvalid,endDeleteInvalid,lastOne,toEnd,jumpToEndLastOne
 * 可以移动的状态 moveValid
 * 不能移动的状态 moveInvalid,deleteInvalid,endDeleteInvalid,canDeleteLastOne,lastOne,jumpToEndLastOne, toEnd
 * 可以在后边添加的状态 moveValid,lastOne
 * 可以在前面添加的状态 moveValid
 * endDeleteInvalid 确定最后一个题时有用
 * @author lwd
 * @date 03/07/2021
 * @param {*} allData
 */

const calcJumpNum = function calcJumpNum(allData) {
    // 计算跳题，设计题号
    let pages = allData.pages;
    let moduleNum = 0;
    let startNum = 0;
    let optionsTotalLen = 0;
    let allModules = [];
    let jumpAreaArray = [];
    for (let k = 0, pLen = pages.length - 1; k < pLen; k++) {
        let newModules = []
        for (let i = 0; i < pages[k].modules.length; i++) {
            const m = pages[k].modules[i];
            if (m.type !== "text" && m.type !== "textarea") {
                optionsTotalLen++;
                m.num = ++moduleNum;
                newModules.push(m);
            }
        }
        allModules = allModules.concat(newModules);
    }


    for (let k = 0, pLen = pages.length - 1; k < pLen; k++) {
        let modules = pages[k].modules;
        for (let i = 0, len = modules.length; i < len; i++) {
            const m = modules[i];
            if (m.type === "text" || m.type === "textarea") {
                continue;
            }
            startNum += 1;
            if (!m.formData.jump) {
                continue
            }
            let jumpKey = m.formData.jump.value.key;
            if (jumpKey !== "0") {
                jumpAreaArray.push([startNum.toString(), jumpKey]);
                calcJumpFrom(allData, startNum, jumpKey, k);
            }
            if (startNum <= optionsTotalLen) {
                let jumpOptions = [];
                for (let j = startNum; j < optionsTotalLen; j++) {
                    jumpOptions.push({
                        key: (j + 1).toString(),
                        text: (j + 1) + "." + allModules[j].formData.title.value
                    })
                }
                m.formData.jump.options = jumpOptions;
                if (m.formData.options) {
                    for (let j = 0, oLen = m.formData.options.length; j < oLen; j++) {
                        let option = m.formData.options[j];
                        option.jump.options = jumpOptions;
                        let optionJumpKey = option.jump.value.key;
                        if (optionJumpKey !== "0") {
                            jumpAreaArray.push([startNum.toString(), optionJumpKey]);
                            calcJumpFrom(allData, startNum, optionJumpKey, k, j);
                        }
                    }
                }
            }
        }
    }

    // 计算哪些可以移动，哪些不可以删除和不可移动，
    //1.不可删除的也不可移动
    //2.不可移动的可删除
    //3.可移动的可删除
    let singleJumpNumArray = [];
    let startJumpArray = [];
    let endJumpNumArray = [];
    let toEndNumArray = [];
    jumpAreaArray.map(j => {
        startJumpArray.push(j[0])
        endJumpNumArray.push(j[1])
        if(j[1] == '-1'){
            toEndNumArray.push(j[0]);
        }
        singleJumpNumArray = singleJumpNumArray.concat(j);
    })
    startJumpArray = [...new Set(startJumpArray)];
    endJumpNumArray = [...new Set(endJumpNumArray)];
    singleJumpNumArray = [...new Set(singleJumpNumArray)];
    let max = 0;
    for (let i = 0; i < singleJumpNumArray.length; i++) {
        if (singleJumpNumArray[i] == "-1") {
            max = -1;
            break;
        }
        if (max < singleJumpNumArray[i]) {
            max = singleJumpNumArray[i];
        }
    }

    startNum = 0;
    for (let k = 0, pLen = pages.length - 1; k < pLen; k++) {
        let modules = pages[k].modules;
        for (let i = 0, len = modules.length; i < len; i++) {
            const m = modules[i];
            if (m.type === "text" || m.type === "textarea") {
                if(startNum < max || max == -1){
                    m.mdStatus = "moveCanNotAdd";
                } else{
                    m.mdStatus = "moveValid";
                }
                continue;
            }

            startNum += 1;
            if (max == "-1") {
                m.mdStatus = "moveInvalid";
                // 如果跳转结束的数组里面包含这个，状态码就为 endDeleteInvalid ，代表跳转题目结束标志，不能删除和移动，不能在后面添加
                if (endJumpNumArray.findIndex(n => {
                    return n === startNum.toString();
                }) >= 0) {
                    m.mdStatus = "endDeleteInvalid";
                }
                // 如果跳转开始数组里面包含这个，状态码就为 deleteInvalid，覆盖掉 endDeleteInvalid ，代表跳转题目开始
                if (startJumpArray.findIndex(n => {
                    return n === startNum.toString();
                }) >= 0) {
                    m.mdStatus = "deleteInvalid";
                }
                for (let j = 0; j < toEndNumArray.length; j++) {
                    if(m.num == toEndNumArray[j]){
                        m.mdStatus = "toEnd";
                    }
                }
                // 如果是最后一个题目，并且没有设置跳转或被跳转，则可以删除
                if (k === pLen - 1 && i === len - 1) {
                    if(m.mdStatus === "deleteInvalid" || m.mdStatus === "toEnd"){
                        m.mdStatus = "jumpToEndLastOne";
                    } else if(m.mdStatus === "endDeleteInvalid"){
                    } else {
                        m.mdStatus = "canDeleteLastOne";
                    }
                    break;
                }
            } else {
                m.mdStatus = "moveValid";
                for (let j = 0; j < jumpAreaArray.length; j++) {
                    if (startNum < jumpAreaArray[j][1]) {
                    // if (startNum > jumpAreaArray[j][0] && startNum < jumpAreaArray[j][1]) {
                        m.mdStatus = "moveInvalid";
                        break;
                    }
                }
                // 如果跳转结束的数组里面包含这个，状态码就为 endDeleteInvalid ，代表跳转题目结束标志
                if (endJumpNumArray.findIndex(n => {
                    return n === startNum.toString();
                }) >= 0) {
                    m.mdStatus = "endDeleteInvalid";
                }
                // 如果跳转开始数组里面包含这个，状态码就为 deleteInvalid，覆盖掉 endDeleteInvalid ，代表跳转题目开始
                if (startJumpArray.findIndex(n => {
                    return n === startNum.toString();
                }) >= 0) {
                    m.mdStatus = "deleteInvalid";
                }
                // 如果最后一块没有设置跳转，并且被设置了跳转，标记为 lastOne ，不能删除和移动，可以在后面添加
                if (startNum == max && m.mdStatus === "endDeleteInvalid") {
                    m.mdStatus = "lastOne";
                }
            }
        }
    }
}

export default calcJumpNum;