import React from "react";
import Title from "./formItem/title";
import Description from "./formItem/description";
import CheckRequire from "./formItem/checkRequire";
import Level from "./formItem/level";
import Jump from "./formItem/jump";
import Option from "./formItem/option";
import { Button, ButtonGroup, Pop } from 'zent';
import global from "@/utils/config";

class CheckboxModule extends React.Component {
    constructor(props) {
        super(props);

    }
    state = {
        optionsRefs: [],
        trigger: "hover"
    }
    titleRef = React.createRef();

    setImg(value, index) {
        this.props.activeModule.formData["options"][index].image = value;
        this.props.updateRequire();
    }
    setValue(key, value, index) {
        if (key === "option") {
            this.props.activeModule.formData["options"][index].value = value;
        } else if (key === "option_tag") {
            if (index == -1) {
                this.props.activeModule.formData["otherOption"].tags = value;
            } else {
                this.props.activeModule.formData["options"][index].tags = value;
            }
        } else if (key === "del") {
            //  删除
            this.props.activeModule.formData["options"].splice(index, 1);
        } else if (key === "delOther") {
            delete this.props.activeModule.formData.otherOption;
        } else {
            this.props.activeModule.formData[key].value = value;
        }
        if (key === "checkRequire") {
            var result = value === "1" ? true : false
            this.props.activeModule.required = result;
        }
        if(key === "check_min" || key === "check_max"){
            this.calcCheckNum(key);
        }
        this.props.updateRequire();
    }
    calcCheckNum(key){
        // 计算多选题可选项
        let pages = this.props.allData.pages;
        for (let i = 0; i < pages.length; i++) {
            const modules = pages[i].modules;
            for (let j = 0; j < modules.length; j++) {
                if(modules[j].type === "checkbox"){
                    let checkArray = [];
                    let len = modules[j].formData.options.length;
                    if(modules[j].formData.otherOption){
                        len ++;
                    }
                    for (let i = 2; i < len; i++) {
                        checkArray.push({
                            key: i + 1,
                            text: (i + 1) + "项"
                        })
                    }
                    modules[j].formData.check_max.options = checkArray;
                    modules[j].formData.check_min.options = checkArray;
                    let newOptions = modules[j].formData.check_max.defaultOptions.concat(checkArray);
                    if(modules[j].formData.check_max.value.key >= newOptions.length){
                        modules[j].formData.check_max.value = newOptions[0];
                    }
                    if(modules[j].formData.check_min.value.key >= newOptions.length){
                        modules[j].formData.check_min.value = newOptions[0];
                    }
                    if(modules[j].formData.check_min.value.key != 0 && modules[j].formData.check_max.value.key != 0){
                        if(modules[j].formData.check_min.value.key >= modules[j].formData.check_max.value.key){
                            if(key === "check_min"){
                                modules[j].formData.check_max.value = modules[j].formData.check_min.value;
                            }
                            if(key === "check_max"){
                                modules[j].formData.check_min.value = modules[j].formData.check_max.value;
                            }
                        }
                    }
                }
            }
        }
        
    }
    addOption() {
        global.option.id++;
        this.props.activeModule.formData.options.push(JSON.parse(JSON.stringify(global.option)));
        this.calcCheckNum();
        this.props.updateRequire();
    }
    addOtherOption() {
        this.props.activeModule.formData.otherOption = JSON.parse(JSON.stringify(global.otherOption));
        this.calcCheckNum();
        this.props.updateRequire();
    }

    componentDidUpdate() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="w1_checkbox w1_base">
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.title} ref={this.titleRef}></Title>
                <Description setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.desc}></Description>
                <CheckRequire setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.checkRequire}></CheckRequire>
                <Level setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.level}></Level>
                <Jump setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.jump}></Jump>
                {this.props.activeModule.formData.options
                    && (this.state.optionsRefs = [])
                    && this.props.activeModule.formData.options.map((one, index) => {
                        let optionRef = React.createRef();
                        this.state.optionsRefs.push(optionRef);
                        return <Option setValue={this.setValue.bind(this)}
                            setImg={this.setImg.bind(this)}
                            key={index}
                            item={one}
                            index={index}
                            calcCheckNum={this.calcCheckNum.bind(this)}
                            ref={optionRef}></Option>
                    })}
                <ButtonGroup className="add_btn first_btn">
                    <Button icon="plus" onClick={this.addOption.bind(this)}>添加选项</Button>
                </ButtonGroup>
                {this.props.activeModule.formData.otherOption ? <Option 
                setValue={this.setValue.bind(this)} 
                calcCheckNum={this.calcCheckNum.bind(this)}
                index={-1} 
                item={this.props.activeModule.formData.otherOption}></Option> : ""}
                <ButtonGroup className="add_btn">
                    {this.props.activeModule.formData.otherOption && <Pop trigger={"hover"} position="top-left" content="一道题仅能添加一个「其他」项" fixMouseEventsOnDisabledChildren>
                        <Button icon="plus" disabled={true} onClick={this.addOtherOption.bind(this)}>添加「其他」项</Button>
                    </Pop>}
                    {!this.props.activeModule.formData.otherOption &&
                        <Button icon="plus" onClick={this.addOtherOption.bind(this)}>添加「其他」项</Button>
                    }
                </ButtonGroup>
                <div style={{ height: "16px", width: "100%" }}></div>
                <Jump setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.check_min}></Jump>
                <Jump setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.check_max}></Jump>
            </div>
        )
    }
}

export default CheckboxModule