import React, { useState, useEffect } from "react";
import { Dialog, VerticalTabs, Input, CopyButton, Tag ,ImageUpload,Button,Notify} from 'zent';
import { getShareConfig, saveShareConfig,upload } from "@/api/index";
const { TabPanel, Divide } = VerticalTabs;
function Popularize(props) {
    const [activeId, setActiveId] = useState('1');
    const [shareTitle, setShareTitle] = useState('默认填充问卷标题');
    const [shareDescription, setShareDescription] = useState('');
    const [shareImage, setShareImage] = useState('');

    useEffect(() => {
        if(shareImage === ''){
            //读取分享设置
            getShareConfig({ qid: props.qid }).then((res) => {
                setShareTitle(res.data.share.title);
                setShareDescription(res.data.share.description);
                setShareImage(res.data.share.image);
            });
        }
    });
    function onTabChange(id) {
        setActiveId(id);
    };
    function down_qr() {
        var image = new Image()
        // 解决跨域 Canvas 污染问题
        image.setAttribute('crossOrigin', 'anonymous')
        image.onload = function () {
            var canvas = document.createElement('canvas')
            canvas.width = image.width
            canvas.height = image.height

            var context = canvas.getContext('2d')
            context.drawImage(image, 0, 0, image.width, image.height)
            var url = canvas.toDataURL('image/png')
            var a = document.createElement('a')
            // 创建一个单击事件
            var event = new MouseEvent('click')
            a.download = '下载图片'
            a.href = url

            // 触发a的单击事件
            a.dispatchEvent(event)
        }

        // image.src = "https://file.yzcdn.cn/mall-cloud/success.0ad3a5836ba99c8f085af0867e43094d.png";
        image.src = props.pimg;
    }
    function changeShareTitle(e) {
        setShareTitle(e.target.value);
    }
    function changeShareDescription(e) {
        setShareDescription(e.target.value);
    }
    let onUpload = (file, report) => {
        var that = this;
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append("file", file);
            formData.append("authority_id", localStorage.getItem("wj_authority_id"));
            upload(formData).then(res => {
                if (res.data.success) {
                    setShareImage(res.data.data.image_url);
                    resolve();
                } else {
                    reject();
                    // Notify.warn(res.data.gw_err_resp.err_msg + "，换张图片试试！")
                }
            })
        });
    };
    let onUploadChange = files => {
    };
    function save() {
        saveShareConfig({
            qid: props.qid,
            title: shareTitle,
            description: shareDescription,
            image: shareImage
        }).then((res) => {
            if (res.data.status === 'finish') {
                Notify.success('操作成功')
            } else {
                Notify.success('操作失败，请重试！')
            }
        });
    };
    return (
        <Dialog
            className="dialog_cus"
            closeBtn={true}
            onClose={props.onclose}
            title="提示"
            visible={props.visible}
        >
            <div>
                <VerticalTabs
                    activeId={activeId}
                    onChange={onTabChange.bind(this)}
                >
                    <TabPanel tab={<span>小程序</span>} id="1">
                        <div className="pm_box">
                            <div className="pm_left">
                                <div className="pm_left_qrbox">
                                    <img className="qr_img" src={props.pimg}></img>
                                    <a className="qr_no_img" href="https://file.yzcdn.cn/mall-cloud/jc.d9d0f90c0293758708d2544232c1d255.png" target="_blank">无法显示推广码，请点这里</a>
                                </div>
                            </div>
                            <div className="pm_right">
                                <span>问卷链接：</span>
                                <div className="copy_box">
                                    <Input placeholder={props.copyUrl} />
                                    <CopyButton text={props.copyUrl} />
                                </div>
                                <div className="downloadQrcode" onClick={down_qr.bind(this)}>下载二维码</div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel tab={<span>分享设置</span>} id="2">
                        <div className="pm_box">
                            <div className="pm_tg_left">
                                <div className="pm_tag">
                                    <Tag theme="blue" style={{ fontSize: 14 }} outline>微信好友</Tag>
                                </div>
                                <img className="pm_demo_img" src="https://file.yzcdn.cn/mall-cloud/demo.85f3ff378efd910e9f19f4795f0bba4e.png"></img>
                            </div>
                            <div className="pm_tg_right">
                                <div className="pm_tg_right_row">
                                    <span>分享标题：</span>
                                    <Input type="textarea" value={shareTitle} autoSize onChange={changeShareTitle} />
                                </div>
                                <div className="pm_tg_right_row">
                                    <span>分享描述：</span>
                                    <Input placeholder="请填写分享描述" type="textarea" value={shareDescription} onChange={changeShareDescription} autoSize />
                                </div>
                                <div className="pm_tg_right_row">
                                    <span>分享图片：</span>
                                    <ImageUpload
                                        className="zent-image-upload-demo"
                                        maxSize={1024 * 600}
                                        tips="建议尺寸 640*640，单张图片不超过 5M"
                                        maxAmount={1}
                                        multiple
                                        sortable
                                        tips="建议尺寸 640*640，单张图片不超过 500k"
                                        onChange={onUploadChange}
                                        onUpload={onUpload}
                                        defaultFileList={[{ thumbSrc: shareImage }]}
                                    // onError={this.onUploadError}
                                    />
                                </div>
                                <div style={{ width: '100%', textAlign: 'right', marginTop: '80px' }}>
                                    <Button type="primary" onClick={save}>保存</Button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </VerticalTabs>
            </div>
        </Dialog>
    )
}
export default Popularize