import React from "react";
import "./right.css"
import AllModulesCom from "./rightCom/allModulesCom"

class Right extends React.Component {
    constructor(props) {
        super(props);
        this.title = "基本信息设置";
    }

    allModulesComRef = React.createRef();
    state = {
        data: [],
        activeId: 0,
    }

    updateRequire(value) {
        this.props.updateRequire(value)
    }

    updateBase(data) {
        this.props.allData.base = data;
        this.props.updateRequire();
    }

    componentDidUpdate() {
    }

    componentDidMount() {
    }
    render() {
        return (
            <div className="w1_right">
                <div className="w1_right_title">
                    {(this.props.activeModule && this.props.allData.aMid !== "-1") ? this.props.activeModule.title : this.title}
                    {this.props.activeModule && this.props.activeModule.type === "image" && this.props.activeModule.id === this.props.allData.aMid && <div className="image_title_des">仅支持用户上传jpg、gif、png三种格式的图片文件，每张图片大小不超过3MB，数量不超过8个。</div>}
                    {this.props.activeModule && this.props.activeModule.type === "textarea" && this.props.activeModule.id === this.props.allData.aMid && <div className="image_title_des">小程序富文本展示以实际效果为准，左侧预览仅供参考</div>}
                </div>
                <div className="w1_right_content">
                    <div className="w1_right_hidden">
                        <div className="w1_right_scroll">
                            <AllModulesCom allData={this.props.allData} activeModule={this.props.activeModule} updateRequire={this.updateRequire.bind(this)}
                                updateBase={this.updateBase.bind(this)}
                                ref={this.allModulesComRef}></AllModulesCom>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Right