// 引入路由视图组件
import Index from '@/pages/index/index'
import List from '@/pages/list/index'
import NewPaper from '@/pages/newPaper/newPaper'
import SuccessPage from '@/pages/newPaper/success';
import Preivew from '../pages/preview/preivew';
import Data from '@/pages/data/data';
import Answer from '@/pages/data/answer';
import IaskPaper from '@/pages/paper/index';

// 路由配置表  数组
const routes = [
    {
        path: '/',
        component: Index,
        exact:true
    },
    {
        path: '/list',
        component: List,
        exact:true
    }
    ,{
        path: "/newpaper",
        component: NewPaper,
        exact:false
    }
    ,{
        path: "/editpaper/:id/:type",
        component: NewPaper,
        exact:false
    },{
        path: "/success",
        component: SuccessPage,
        exact:false
    },{
        path: "/preview/:id/:type",
        component: Preivew,
        exact:false
    },{
        path: "/data/:id",
        component: Data,
        exact:false
    },{
        path: "/answer/:aid",
        component: Answer,
        exact:false
    },{
        path: "/iaskpaper/:id/:type",
        component: IaskPaper,
        exact:false
    }
    // {
    //     path: '*',
    //     redirect: "/",
    //     component: Index,
    //     exact:true
    // },
];
// 抛出路由配置表
export default routes;