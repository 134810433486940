import React from "react";
import { getUserInfo, logout, getSmsCode, login, register,checkSmsCode } from "../api/user";
import { Notify, FullScreenLoading } from "zent";
import store from "@/redux/user"
import { withRouter } from "react-router-dom";
// const accountRegex = /[A-Za-z\d]{5,25}/g;
// 支持中英文、数字、减号或下划线,长度在5-16之间
// const accountRegex = /^[\u4e00-\u9fa5_a-zA-Z0-9-]{5,16}$/;
// const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,26}$/g;
// 强密码(必须包含大小写字母和数字的组合，可以使用特殊字符，长度在8-26之间)：
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,26}$/;
// 手机号码
const mobileRegex = /^1[3456789]\d{9}$/;

const codeRegex = /^\d{4}/;

class UserInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loginVisible: false,
      password: "",
      mobile: '',
      code: '', // 验证码
      passwordErrMsg: "",
      mobileErrMsg: "",
      codeErrMsg: "",
      passwordMsg: '必须包含大小写字母和数字的组合，8-26位',
      mobileMsg: '请输入正确的手机号码',
      codeMsg: '请输入正确的动态码',
      loginControl: 'login',
      getPasswordStep: 0,
      sendRequest: false,
      sending: false,
      lastSeconds: 60,
      loading: false,
      nickname: '',
      token: ''
    }
  }
  componentDidMount() {
    this.getUserInfo();
  }

  login() {
    this.setState({
      loginControl: 'login',
      loginVisible: true
    })
  }

  getUserInfo() {
    let token = localStorage.getItem("iask_token");
    if(token){
      getUserInfo({
        token: token
      }).then(res => {
        console.log(res);
        if (res.data.code == 1) {
          let userinfo = res.data.data.userinfo;
          this.setState({
            nickname: userinfo.nickname
          })
          store.userStore.dispatch({
            nickname: userinfo.nickname,
            type: 'add'
          })
        }
      })
    }
  }

  getNickName() {
    return this.state.nickname;
  }

  codeChange(e) {
    this.setState({
      code: e.target.value
    })
  }

  codeBlur(e) {
    this.checkCode(e.target.value);
  }

  mobileChange(e) {
    this.setState({
      mobile: e.target.value
    })
  }

  mobileBlur(e) {
    this.checkMobile(e.target.value);
  }

  passwordChange(e) {
    this.setState({
      password: e.target.value
    })
  }
  passwordBlur(e) {
    e.target.setAttribute('readonly', true);
    this.checkPassword(e.target.value);
  }

  removeAttributeReadonly(e) {
    e.target.removeAttribute('readonly', true);
  }

  checkPassword(val) {
    let password = val || this.state.password;
    console.log(password);
    if (passwordRegex.test(password)) {
      this.setState({
        passwordErrMsg: ""
      })
      return true;

    } else {
      if (password && password.trim()) {
        this.setState({
          passwordErrMsg: this.state.passwordMsg
        })
      } else {
        this.setState({
          passwordErrMsg: "请输入密码"
        })
      }
      return false;
    }
  }

  checkMobile(val) {
    let mobile = val || this.state.mobile;
    if (mobileRegex.test(mobile)) {
      this.setState({
        mobileErrMsg: ""
      })
      return true;

    } else {
      if (mobile && mobile.trim()) {
        this.setState({
          mobileErrMsg: this.state.mobileMsg
        })
      } else {
        this.setState({
          mobileErrMsg: "请输入手机号"
        })
      }
      return false;
    }
  }
  checkCode(val) {
    let code = val || this.state.code;
    if (codeRegex.test(code)) {
      this.setState({
        codeErrMsg: ""
      })
      return true;

    } else {
      if (code && code.trim()) {
        this.setState({
          codeErrMsg: this.state.codeMsg
        })
      } else {
        this.setState({
          codeErrMsg: "请输入动态码"
        })
      }
      return false;
    }
  }

  getPassword() {
    this.setState({
      loginVisible: true,
      loginControl: 'getPassword',
      getPasswordStep: 1
    })
  }

  getCode() {
    if (this.state.sending) {
      return
    }
    this.setState({
      sending: true,
      sendRequest: false
    })
    let lastSeconds = 60;
    let timer = setInterval(() => {
      lastSeconds -= 1;
      this.setState({
        lastSeconds: lastSeconds
      })
      if (lastSeconds <= 0) {
        this.setState({
          sending: false
        })
        clearInterval(timer);
      }
    }, 1000)
    getSmsCode({
      mobile: this.state.mobile,
      event: 'register'
    }).then(res => {
      console.log(res);
      if(res.data.code == 1){
        this.setState({
          sendRequest: true
        })
      } else {
        Notify.info(res.data.msg)
        this.setState({
          mobile: '',
          sending: false
        })
        clearInterval(timer);
      }
    }).catch(err => {
      Notify.info(err.message)
      this.setState({
        sending: false
      })
      clearInterval(timer);
    })
  }
  onSubmit() {
    if (this.state.loading) {
      return false;
    }
    if (this.state.loginControl === 'login') {
      if (process.env.NODE_ENV !== 'development') {
        if (!this.checkMobile()) {
          return
        }
        if (!this.checkPassword()) {
          return
        }
      }
      this.setState({
        loading: true
      })
      login({
        account: this.state.mobile,
        password: this.state.password
      }).then(res => {
        console.log(res);
        if (res.data.code == 1) {
          let userinfo = res.data.data.userinfo;
          localStorage.setItem("iask_token", userinfo.token);
          this.setState({
            token: userinfo.token,
            nickname: userinfo.nickname,
            loginVisible: false
          })
          this.getUserInfo();
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.msg);
        }
        this.setState({
          loading: false,
        })
      }).catch(err => {
        Notify.error(err.message);
        this.setState({
          loading: false,
        })
      })
    } else if (this.state.loginControl === 'register') {
      console.log(this.state.sendRequest);
      if (!this.state.sendRequest) {
        return false;
      }
      if (!this.checkMobile()) {
        return
      }
      if (!this.checkCode()) {
        return
      }
      if (!this.checkPassword()) {
        return
      }
      this.setState({
        loading: true
      })
      register({
        mobile: this.state.mobile,
        password: this.state.password,
        code: this.state.code
      }).then(res => {
        console.log(res);
        if (res.data.code == 1) {
          let userinfo = res.data.data.userinfo;
          localStorage.setItem("iask_token", userinfo.token);
          // localStorage.setItem('iask_user_id', userinfo.user_id);
          // localStorage.setItem("iask_nickname", userinfo.nickname);
          this.setState({
            token: userinfo.token,
            nickname: userinfo.nickname,
            loginVisible: false
          })
          this.getUserInfo();
        } else {
          Notify.error(res.data.msg);
        }
        this.setState({
          loading: false
        })
      }).catch(err => {
        console.log(err);
        Notify.error(err.message);
        this.setState({
          loading: false
        })
      })
    }
  }

  hideLogin() {
    this.setState({
      loginVisible: false
    })
  }

  register() {
    this.setState({
      loginVisible: true,
      loginControl: 'register'
    })
  }

  getPassWordNext(step){
    if(step === 2){
      checkSmsCode({
        mobile: this.state.mobile,
        event: 'check',
        captcha: this.state.code
      }).then(res => {
        console.log(res);
        this.setState({
          getPasswordStep: step
        })
      })
    }
  }
  logout() {
    logout({ token: localStorage.getItem("iask_token") }).then(res => {
      if (res.data.code == 1) {
        this.setState({
          nickname: ''
        })
        Notify.success("退出成功");
        localStorage.removeItem("iask_token");
        localStorage.removeItem("iask_nickname");
        localStorage.removeItem("iask_user_id");
        this.props.history.push("/");
      }
    })
  }

  render() {
    return (
      <div className="iask-userinfo">
        {this.state.nickname === '' && <div className="iask-login" onClick={this.login.bind(this)}>登录</div>}
        {this.state.nickname === '' && <div className="iask-register" onClick={this.register.bind(this)}>注册</div>}
        {this.state.nickname && <div className="iask-nickname">
          {/* 欢迎光临：&nbsp;{this.state.nickname}&nbsp;&nbsp; */}
          <span onClick={this.logout.bind(this)} className="iask-logout">退出</span>
        </div>}
        {this.state.loginVisible && <div className="iask-login-box">
          <div className="iask-bg" onClick={this.hideLogin.bind(this)}></div>
          <form className="iask-login-form" name="loginForm" autoComplete="off">
            <div className="iask-login-form-head">{this.state.loginControl === 'login' ? '登录' : '注册'}</div>
            <input type='text' style={{ display: 'none' }} />
            {this.state.getPasswordStep !== 2 && <div className="iask-login-input-box">
              <div className="iask-login-input-wrapper">
                <input type="text" name="code" placeholder="请输入手机号"
                  value={this.state.mobile}
                  onChange={this.mobileChange.bind(this)}
                  onBlur={this.mobileBlur.bind(this)}
                ></input>
                {this.state.loginControl !== 'login' && !this.state.sending && <div className="iask-get-code" onClick={this.getCode.bind(this)}>获取动态码</div>}
                {this.state.loginControl !== 'login' && this.state.sending && <div className="iask-get-code disabled">{this.state.lastSeconds}s</div>}
              </div>
              <p className="iask-login-check-word">{this.state.mobileErrMsg}</p>
            </div>}
            {this.state.loginControl !== 'login' && this.state.getPasswordStep !== 2 && <div className="iask-login-input-box">
              <div className="iask-login-input-wrapper">
                <input type="text" name="code" placeholder="请输入验证码"
                  disabled={!this.state.sendRequest}
                  value={this.state.code}
                  autoComplete="off"
                  onChange={this.codeChange.bind(this)}
                  onBlur={this.codeBlur.bind(this)}
                ></input>
              </div>
              <p className="iask-login-check-word">{this.state.codeErrMsg}</p>
            </div>}
            {(this.state.loginControl !== "getPassword" || this.state.getPasswordStep === 2) && <div className="iask-login-input-box">
              <div className="iask-login-input-wrapper">
                <input type="password" name="password" placeholder={'密码（' + this.state.passwordMsg + '）'}
                  value={this.state.password}
                  autoComplete='new-password'
                  onChange={this.passwordChange.bind(this)}
                  onBlur={this.passwordBlur.bind(this)}
                  style={{ backgroundColor: "#FFFFFF!important" }} readOnly onFocus={this.removeAttributeReadonly.bind(this)}
                ></input>
              </div>
              <p className="iask-login-check-word">{this.state.passwordErrMsg}</p>
            </div>}
            <div className="iask-login-btn" onClick={this.onSubmit.bind(this)}>{this.state.loginControl === 'login' ? '登录' : '注册'}</div>
            <div className="iask-login-bottom">
              {this.state.loginControl === "getPassword" && this.state.getPasswordStep === 2 && <div className="iask-login-bottom-btn" onClick={this.getPassWordNext.bind(this,1)}>上一步</div>}
              {this.state.loginControl === "getPassword" && this.state.getPasswordStep === 1 && <div className="iask-login-bottom-btn" onClick={this.getPassWordNext.bind(this,2)}>下一步</div>}
              {this.state.loginControl !== "getPassword" && <div className="iask-login-bottom-btn" onClick={this.getPassword.bind(this)}>找回密码</div>}
              <div className="iask-login-bottom-right">
              {this.state.loginControl !== 'login' && <div className="iask-login-bottom-btn iask-login-to-login" onClick={this.login.bind(this)}>登录</div>}
              {this.state.loginControl !== 'register' && <div className="iask-login-bottom-btn" onClick={this.register.bind(this)}>注册新用户</div>}
              </div>
            </div>
          </form>
        </div>}
        <FullScreenLoading loading={this.state.loading} icon="circle" iconSize={64} iconText="加载中" />
      </div>
    )
  }
}

export default withRouter(UserInfo)