import React from 'react';
import "./newPaper.css";
import Header from "./components/header";
import Left from "./components/left/left";
import Center from "./components/center/center";
import Right from "./components/right/right";
import { addJuan, getDetails } from "@/api/index";
import { Notify, Sweetalert, FullScreenLoading } from 'zent';
import global from '@/utils/config';
import dataFactory from "@/utils/dataFactory";
import SuccessPage from './success';
import Preivew from '../preview/preivew';
import getPageNum from "@/mixins/getPageNum";
const allData = {
    id: 0,
    aPid: 1,
    aMid: 1,
    aMIndex: 0,
    aPIndex: 0,
    name: "",
    base: JSON.parse(JSON.stringify(global.base)),
    begin_time: '', //2021-05-30 00:00:00
    end_time: '',
    is_login: 0, //0是不需要登录，1是需要登录
    is_follow: 0, //0是不需要关注，1是需要关注
    pages: [
        {
            // tab: <span>第一页</span>,
            id: 1,
        },
        {
            // tab: <span>结束页</span>,
            id: global.maxPid,
        },
    ],
    previewMode: false,
    editType: 0 // 编辑模式，0 不受限模式， 1 受限模式 2 复制
}


/**
 * 受限模式，只能修改问卷细节，例如更改错别字、添加选项、增加跳题逻辑。不能对问卷做以下操作：删除题目或选项、移动题目或选项
 */
class NewPaper extends React.Component {
    constructor(props) {
        super(props)
    }

    state = {
        activeModule: null,
        errorMsg: "",
        errorType: "",
        allData: JSON.parse(JSON.stringify(allData)),
        loading: false,
        loadingNum: 0,
        successState: 0,
        mode: "new",
        notSaveData: null,
        hasNoSaveData: false,
        qid: 0,
        textareaValid: true
    }
    centerRef = React.createRef();
    rightRef = React.createRef();
    mixins = [getPageNum];
    showModule(module) {
        this.setState({
            activeModule: module
        })
    }

    getPIndex() {
        let pIndex = -1;
        if (this.state.allData.aPid === global.maxPid) {
            pIndex = this.state.allData.pages.length - 1;
        } else {
            pIndex = this.state.allData.aPid - 1;
        }
        return pIndex;
    }

    updateRequire() {
        this.centerRef.current.updatePages();
    }

    updateAllData(data) {
        console.log(data);
        this.setState({
            allData: data
        })
    }

    checkForm(orderData) {
        let errorMsg = "";
        if (!orderData.name) {
            errorMsg = "问卷名称不能为空";
            this.state.errorMsg = errorMsg;
            this.state.errorType = 1;
            this.centerRef.current.setBase();
            return false;
        }
        if (!orderData.begin_time || !orderData.end_time) {
            errorMsg = "有效期不能为空";
            this.state.errorType = 2;
            this.state.errorMsg = errorMsg;
            this.centerRef.current.setBase();
            return false;
        }

        let questionNum = 0;
        for (let i = 0; i < orderData.pages.length; i++) {
            const page = orderData.pages[i];
            for (let j = 0; j < page.module.length; j++) {
                const module = page.module[j];
                if (module.type !== "text" && module.type !== "textarea") {
                    questionNum++;
                }
            }
        }
        if (questionNum === 0) {
            errorMsg = "请至少设置一个题目(不包括标题文本和富文本)";
            this.state.errorType = 10;
            this.state.errorMsg = errorMsg;
            this.centerRef.current.onTabChange(this.state.allData.pages[0].id);
            return
        }
        for (let i = 0; i < orderData.pages.length; i++) {
            const page = orderData.pages[i];
            let pageQuestionNum = 0;
            for (let j = 0; j < page.module.length; j++) {
                const module = page.module[j];
                if (module.type !== "text" && module.type !== "textarea") {
                    pageQuestionNum++;
                }
            }
            if (pageQuestionNum === 0 && i < orderData.pages.length) {
                errorMsg = "第" + (i + 1) + "页还未设置题目";
                this.state.errorType = 14;
                this.state.errorMsg = errorMsg;
                this.centerRef.current.onTabChange(this.state.allData.pages[i].id);
                return false;
            }
            let tiHao = 0;
            for (let j = 0; j < page.module.length; j++) {
                const module = page.module[j];
                if(module.type !== 'text' && module.type !== 'textarea'){
                    tiHao += 1;
                }
                if (module.required == 1 && !module.title) {
                    errorMsg = "第" + (i + 1) + "页的第" + tiHao + "题的标题未设置";
                    this.state.errorMsg = errorMsg;
                    this.state.allData.aMIndex = j;
                    this.state.allData.aMid = this.state.allData.pages[i].modules[j].id;
                    this.state.errorType = 11;
                    this.centerRef.current.onTabChange(this.state.allData.pages[i].id, this.state.allData.aMid);
                    return false;
                }
                if (module.type === "radio" || module.type === "checkbox") {
                    for (let k = 0; k < module.list.length; k++) {
                        const option = module.list[k];
                        if (!(option.value || option.image) && option.type != 1) {
                            errorMsg = "第" + (i + 1) + "页的第" + tiHao + "题的第" + (k + 1) + "个选项的标题或者图片至少设置一项";
                            this.state.errorMsg = errorMsg;
                            this.state.allData.aMIndex = j;
                            this.state.allData.aMid = this.state.allData.pages[i].modules[j].id;
                            this.state.allData.aOIndex = k;
                            this.state.errorMsg = errorMsg;
                            this.state.errorType = 12;
                            this.centerRef.current.onTabChange(this.state.allData.pages[i].id, this.state.allData.aMid);
                            return false;
                        }
                    }
                }
            }
        }
        if (!orderData.end) {
            this.state.errorMsg = "请设置结束页提交成功选项";
            this.state.errorType = 3;
            this.centerRef.current.onTabChange(global.maxPid)
            return false;
        }
        // if (!orderData.end.url) {
        //     this.state.errorType = 9;
        //     this.state.errorMsg = "请设置跳转链接";
        //     return false;
        // }
        if (orderData.end.joinTimes == "3") {
            this.centerRef.current.onTabChange(global.maxPid)
            if (!(orderData.end.day && /^[1-9]\d*$/.test(orderData.end.day))) {
                this.state.errorType = 4;
                this.state.errorMsg = "参与天数需要填正整数";
                return false;
            }
            if (!(orderData.end.number && /^[1-9]\d*$/.test(orderData.end.number))) {
                this.state.errorType = 5;
                this.state.errorMsg = "参与次数需要填正整数"
                return false;
            }
        }
        if (orderData.end.prize == "1") {
            this.centerRef.current.onTabChange(global.maxPid)
            if (orderData.end.giveObjects == "2") {
                if (orderData.end.giveTags.length === 0) {
                    this.state.errorType = 6;
                    this.state.errorMsg = "指定客户身份请选择客户分群";
                    this.centerRef.current.onTabChange(global.maxPid)
                    return false;
                }
            }
            if (orderData.end.choosePrize.includes("1")) {
                if (orderData.end.coupons.length === 0 || orderData.end.coupons[0].id == 0 || orderData.end.coupons[0].id == undefined) {
                    this.state.errorType = 7;
                    this.state.errorMsg = "请选择优惠券";
                    this.centerRef.current.onTabChange(global.maxPid)
                    return false;
                }
            }
            if (orderData.end.choosePrize.includes("2")) {
                if (orderData.end.promoCode.length === 0 || orderData.end.promoCode[0].id == 0 || orderData.end.promoCode[0].id == undefined) {
                    this.state.errorType = 8;
                    this.state.errorMsg = "请选择优惠码";
                    this.centerRef.current.onTabChange(global.maxPid)
                    return false;
                }
            }
            if (orderData.end.choosePrize.includes("4")) {
                if (orderData.end.present.length === 0 || orderData.end.present[0].id == 0 || orderData.end.present[0].id == undefined) {
                    this.state.errorType = 9;
                    this.state.errorMsg = "请选择赠品";
                    this.centerRef.current.onTabChange(global.maxPid)
                    return false;
                }
            }
        }
        return true;
    }

    save(type) {
        let orderData = dataFactory.putDataInOrder(this.state.allData);
        this.setState({
            loading: true
        })
        if (this.checkForm(orderData)) {
            // orderData["authority_id"] = localStorage.getItem("wj_authority_id");
            addJuan(orderData).then(res => {
                console.log(res);
                if (res.data.code == 1) {
                    let qid = res.data.data.id;
                    localStorage.removeItem("yz_wj_all_data")
                    this.setState({
                        qid: qid
                    })
                    if (type === "preview") {
                        Notify.success("问卷已自动保存", 1500, () => {
                            // 跳到预览页
                            let editType = this.state.editType || 0;
                            this.props.history.push({ pathname: "/preview/" + qid + "/" + editType });
                        })
                    } else {
                        this.setState({
                            successState: 1
                        })
                    }
                    this.setState({
                        loading: false
                    })
                } else {
                    Notify.info(res.data.msg)
                    this.setState({
                        loading: false
                    })
                }
            })
        } else {
            const close = Sweetalert.alert({
                content: <p style={{ color: "#D40000" }}>{this.state.errorMsg}</p>,
                // parentComponent: this,
                confirmText: "前去查看",
                onConfirm: () => {
                    if (this.state.errorType === 1) {
                        this.rightRef.current.allModulesComRef.current.baseRef.current.titleRef.current.stateBlur();
                    } else if (this.state.errorType === 2) {
                        this.rightRef.current.allModulesComRef.current.baseRef.current.dateRangeRef.current.dateBlur();
                    } else if (this.state.errorType === 4) {
                        // 参与天数
                        this.rightRef.current.allModulesComRef.current.endRef.current.joinTimesRef.current.dayBlur();
                    } else if (this.state.errorType === 5) {
                        // 参与次数
                        this.rightRef.current.allModulesComRef.current.endRef.current.joinTimesRef.current.timesBlur();
                    } else if (this.state.errorType === 6) {
                        // 选择客户群
                        this.rightRef.current.allModulesComRef.current.endRef.current.giveObjectsRef.current.tagRef.current.stateBlur();
                    } else if (this.state.errorType === 7) {
                        // 优惠券
                        this.rightRef.current.allModulesComRef.current.endRef.current.choosePrizeRef.current.delPrize(0);
                    } else if (this.state.errorType === 8) {
                        // 优惠码
                        this.rightRef.current.allModulesComRef.current.endRef.current.choosePrizeRef.current.delPrize(1);
                    } else if (this.state.errorType === 9) {
                        // 赠品
                        this.rightRef.current.allModulesComRef.current.endRef.current.choosePrizeRef.current.delPrize(3);
                        // 链接
                        // this.rightRef.current.allModulesComRef.current.endRef.current.urlRef.current.stateBlur();
                    } else if (this.state.errorType === 11) {
                        // 标题
                        this.rightRef.current.allModulesComRef.current.moduleRef.current.titleRef.current.stateBlur();
                    } else if (this.state.errorType === 12) {
                        // 单选题选项标题
                        this.rightRef.current.allModulesComRef.current.moduleRef.current.state.optionsRefs[this.state.allData.aOIndex].current.stateBlur();
                    }
                }
            });
            this.setState({
                loading: false
            })
        }

    }

    // 数据返结构化
    reverseOrderData(data) {
        data.editType = this.state.editType;
        dataFactory.reverseOrderData(data, (reverseData) => {
            console.log(reverseData);
            getPageNum.calcPageNum(reverseData);

            if (this.state.editType == 1) {
                // 受限模式，做下处理
                let pages = reverseData.pages;
                for (let index = 0; index < pages.length; index++) {
                    let freezeModuleNum = 0;
                    const modules = pages[index].modules;
                    for (let j = 0; j < modules.length; j++) {
                        const module = modules[j];
                        if (module.type !== "text" && module.type !== "textarea" && module.type !== "end") {
                            freezeModuleNum++;
                            module.freezeOptionNum = 1;
                        }
                        if (module.type === "radio" || module.type === "checkbox") {
                            module.freezeOptionNum = module.formData.options.length;
                            if (module.formData.otherOption) {
                                module.freezeOtherOption = true;
                            }
                        }
                    }
                    pages[index].freezeModuleNum = freezeModuleNum;
                }
                reverseData.freezePageNum = pages.length - 1;
            }
            this.updateAllData(reverseData);
            this.setState({
                mode: "edit",
                loading: false
            })
            this.updateRequire();
        })
    }

    componentDidUpdate() {
    }
    beforeLeave() {
        return '关闭提示';
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        let notSaveData = localStorage.getItem("yz_wj_all_data");
        let that = this;
        if(notSaveData){
            notSaveData = JSON.parse(notSaveData);
        }
        this.setState({
            loading: true
        })
        console.log("id", id);
        let totalLoadNum = 1;
        if (id) {
            totalLoadNum = 2;
            if(notSaveData && notSaveData.id == id){
                this.setState({
                    notSaveData: notSaveData,
                    hasNoSaveData: true
                })
            }
            let editType = this.props.match.params.type;
            this.setState({
                editType
            })
            getDetails({ id: id }).then(res => {
                console.log(res);
                if(res.data.code === 1){
                    this.reverseOrderData(res.data.msg);
                }
                this.updateLoading(totalLoadNum);
            })
        } else {
            if(notSaveData && notSaveData.id == 0){
                this.setState({
                    notSaveData: notSaveData,
                    hasNoSaveData: true
                })
            }
            this.init(allData);
        }
        function createScriptDom(url, callback, errorback) {
            const scriptDom = document.createElement('script');
            scriptDom.type = 'text/javascript';
            scriptDom.async = true;
            scriptDom.src = url;

            scriptDom.onload = () => {
                callback && callback();
            }
            scriptDom.onerror = (err) => {
                that.setState({
                    textareaValid: false,
                    loading: false
                })
                Notify.error('富文本模块加载失败，请联系管理员', 2500, () => {})
                errorback && errorback(err);
            }
            document.body.appendChild(scriptDom);
        }

        function loadUeditorJS() {
            let ueditorList = ["https://www.dongyi.shop/wenjuan/js/ueditor.config.iask.js", "https://www.dongyi.shop/wenjuan/js/ueditor.all.min.js", "https://www.dongyi.shop/wenjuan/js/zh-cn.js"];
            console.log("load");
            createScriptDom(ueditorList[0], () => {
                createScriptDom(ueditorList[1], () => {
                    createScriptDom(ueditorList[2], () => {
                        that.updateLoading(totalLoadNum);
                    })
                })
            })
        }
        if (!window.UE) {
            this.setState({
                loading: true
            })
            loadUeditorJS();
        } else {
            this.updateLoading(totalLoadNum);
        }
    }

    updateLoading(totalLoadNum){
        this.setState({
            loadingNum: this.state.loadingNum + 1
        })
        setTimeout(() => {
            if(this.state.loadingNum === totalLoadNum){
                this.setState({
                    loading: false
                })
            }
        },0)
    }

    changePreviewMode() {
        this.setState({
            previewMode: !this.state.previewMode
        })
    }
    init(originData) {
        let data = JSON.parse(JSON.stringify(originData));
        data.pages[0].tab = <span>第一页</span>
        data.pages[1].tab = <span>结束页</span>
        this.setState({
            mode: "new",
            successState: 0,
            allData: data
        })
    }

    giveUpNoSaveData(){
        this.setState({
            hasNoSaveData: false,
            notSaveData: null
        })
        localStorage.removeItem("yz_wj_all_data");
    }
    useNoSaveData(){
        getPageNum.calcPageNum(this.state.notSaveData);
        this.state.allData = this.state.notSaveData
        this.updateAllData(this.state.notSaveData);
        if(this.state.notSaveData.id){
            this.setState({
                mode: "edit"
            })
        }
        this.setState({
            hasNoSaveData: false,
            notSaveData: null
        })
        localStorage.removeItem("yz_wj_all_data");
        setTimeout(() => {
            this.updateRequire();
        },0);
    }
    
    closeNoSaveData(){
        this.setState({
            hasNoSaveData: false
        })
    }

    render() {
        if (this.state.previewMode) {
            return <Preivew changePreviewMode={this.changePreviewMode.bind(this)} allData={this.state.allData}></Preivew>
        } else {
            return (<div className="w1_container">
                <Header save={this.save.bind(this)}
                    successState={this.state.successState}
                    mode={this.state.mode}
                    changePreviewMode={this.changePreviewMode.bind(this)}
                    allData={this.state.allData}></Header>
                {this.state.successState == 0 ? <div className="w1_content">
                    <Left></Left>
                    <Center
                        showModule={this.showModule.bind(this)}
                        updateAllData={this.updateAllData.bind(this)}
                        allData={this.state.allData}
                        editType={this.state.editType}
                        hasNoSaveData={this.state.hasNoSaveData}
                        useNoSaveData={this.useNoSaveData.bind(this)}
                        closeNoSaveData={this.closeNoSaveData.bind(this)}
                        giveUpNoSaveData={this.giveUpNoSaveData.bind(this)}
                        textareaValid={this.state.textareaValid}
                        ref={this.centerRef} ></Center>
                    <Right
                        activeModule={this.state.activeModule}
                        allData={this.state.allData}
                        getPIndex={this.getPIndex.bind(this)}
                        updateAllData={this.updateAllData.bind(this)}
                        updateRequire={this.updateRequire.bind(this)}
                        ref={this.rightRef}></Right>
                </div> :
                    <SuccessPage mode={this.state.mode} qid={this.state.qid}></SuccessPage>}
                <FullScreenLoading loading={this.state.loading} icon="circle" iconText="加载中"/>
            </div>)
        }

    }
}

export default NewPaper