import React from 'react';

function Question1(props){

  return (
    <div className="iask-paper-question">
      <div className='iask-paper-question-inner'>
        <div className="iask-paper-question-num">问题1</div>
        <div className="iask-paper-question-title">What are the top reasons that made you choose us?</div>
        <ul className="iask-paper-select-ul">
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/1.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>asdasdasdasdasd</p>
              <p className="iask-paper-ql">A</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿asdAsdDasdadAdasdasd阿萨德阿达萨达阿萨德asd萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
          <li className="iask-paper-select-li">
            <div className="iask-paper-select-li-img">
              <img src={require('@/assets/images/paper/answer1/2.png').default}></img>
            </div>
            <div className="iask-paper-qt">
              <p>奥术大师大所大所多阿萨德阿萨德阿萨德</p>
              <p className="iask-paper-ql">B</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Question1;