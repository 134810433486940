import React from "react";
import { Input } from 'zent';
import star1 from "@/assets/images/icons/star.png";

/**
 * @description 描述组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    stateChange(e) {
        e.persist();
        this.props.item.value = e.target.value;
        this.props.setValue(this.props.item.key, e.target.value)
    }
    stateBlur(){
        if(this.props.item.required){
            this.dist = true;
            this.setState({ value: 1 });
        }
    }

    render() {
        return (
            <div className={`w1_form_item ${this.props.item.value? "fill": ""} ${this.dist? "dist": ''}`}>
                <div className="name_line">
                    {this.props.item.required && <img src={star1}></img>}
                    <span>{this.props.item.title}</span>
                </div>
                <Input
                    type="textarea"
                    onChange={this.stateChange.bind(this)}
                    onBlur={this.stateBlur.bind(this)}
                    placeholder="请输入要说明的文字，最多100字"
                    // maxLength={100}
                    autoSize
                    value={this.props.item.value}
                    />
                {this.props.item.msg}
            </div>
        )
    }
}

export default Item