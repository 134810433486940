import React from "react";
import Title from "./formItem/title";
import Description from "./formItem/description";
import CheckRequire from "./formItem/checkRequire";
import Level from "./formItem/level";
import Jump from "./formItem/jump";
import Tag from "./formItem/tag";

class ImageModule extends React.Component {
    constructor(props) {
        super(props);
    }
    state ={
        optionsRefs: []
    }
    titleRef = React.createRef();

    setValue(key, value, index) {
        if (key === "del") {
            //  删除
            this.props.activeModule.formData["options"].splice(index, 1);
        } else {
            this.props.activeModule.formData[key].value = value;
        }
        if (key === "checkRequire") {
            var result = value === "1" ? true : false
            this.props.activeModule.required = result;
        }
        this.props.updateRequire();
    }

    componentDidUpdate() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="w1_single w1_base">
                {/* 标题 */}
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.title} ref={this.titleRef}></Title>
                {/* 描述 */}
                <Description setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.desc}></Description>
                {/* 按钮文案 */}
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.button_txt}></Title>
                {/* 是否必填 */}
                <CheckRequire setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.checkRequire}></CheckRequire>
                {/* 跳题 */}
                <Jump setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.jump}></Jump>
                {/* 同步打标签 */}
                <Tag setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.tags}></Tag>
                {/* 是否设置查看权限 */}
                <Level setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.level}></Level>
            </div>
        )
    }
}

export default ImageModule