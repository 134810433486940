
import React from "react";
import { withRouter } from 'react-router-dom'
import { answerDetail } from "@/api/index";
import "./answer.css"
import { Tag, Pop,Button } from 'zent';


class Answer extends React.Component {
    state = {
        aid: 0,
        answer: {},
        question: {},
        module: []
    };

    constructor(props) {
        super(props);
    }
    goBack(){
        this.props.history.goBack();
    }
    componentDidMount() {
        this.setState({aid:this.props.match.params.aid});

        answerDetail({aid:this.props.match.params.aid}).then(res => {
            this.setState({
                answer: res.data.answer,
                question: res.data.question,
                module: res.data.module
            });
        })
    }

    render() {
        return (
            <div className="wj_wrapper_container">
                <div className="wj_wrapper">
                    <div className="wj_title">
                        <Button className="wj_go_back" type="primary" onClick={this.goBack.bind(this)}>返回</Button>
                        <span className="title_left">{this.state.answer.nickname}</span>
                        <Tag theme="blue" outline>{this.state.question.status}</Tag>
                        <div className="sub_title">
                            有效时间：{this.state.question.begin_time} 至 {this.state.question.end_time}
                        </div>
                    </div>
                    <div className="a_content">
                        {
                            this.state.module.map((one, index) => {
                                if (one.type != "text" && one.type != "textarea")
                                {
                                    if (one.type == 'image' && one.answer != "跳过") {
                                        return <div className="a_row" key={index}>
                                            {
                                                one.required == 1 && <span className="bx"> * </span>
                                            }
                                            第{index + 1}题：{one.title} ({one.type_v})
                                            <div className="a_row_view">
                                                {
                                                    // if (one.answer == "跳过") {
                                                            
                                                    // } else {
                                                        one.answer.map((img_item, img_index) => {
                                                            var Content = Pop.withPop(function Content({ pop }) {
                                                                return (
                                                                <div>
                                                                    <div style={{ marginBottom: 16, width:'500px' }}><img src={img_item} style={{width: "100%"}}></img></div>
                                                                    {/* <Button onClick={pop.close}>关闭</Button> */}
                                                                </div>
                                                                );
                                                            });
                                                            return <Pop trigger="hover" content={<Content />} key={img_index}><img src={img_item} className="a_img"></img></Pop>
                                                        })
                                                    // }
                                                }
                                            </div>
                                        </div>
                                    }
                                    else if (one.type == 'checkbox' && one.answer_checkbox.length > 0) {
                                        return <div className="a_row" key={index}>
                                            {
                                                one.required == 1 && <span className="bx"> * </span>
                                            }
                                            第{index + 1}题：{one.title} ({one.type_v})
                                            <div className="a_row_view">
                                                {
                                                    one.answer_checkbox.map((v,i) => {
                                                        if (v.image != '') {
                                                            return <div key={i}><img className="a_img" src={v.image}></img> <span className="a_checkbox_title">{v.value}; </span></div>
                                                        } else {
                                                            return <div key={i}><span className="a_checkbox_title">{v.value}; </span></div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                    else {
                                        return <div className="a_row" key={index}>
                                            {
                                                one.required == 1 && <span className="bx"> * </span>
                                            }
                                            第{index + 1}题：{one.title} ({one.type_v})
                                            <div className="a_row_view">
                                                {
                                                    one.image && one.image != "" && <img className="a_img" src={one.image}></img>
                                                }
                                                {one.answer}
                                            </div>
                                        </div>
                                    }
                                }
                                
                            })
                        }
                        
                    </div>
                </div>
                
            </div>
        )
    }
}

export default withRouter(Answer)