import request from "@/utils/request";

// 新增问卷
export function addJuan(data){
    Object.assign(data, {
        authority_id: localStorage.getItem("wj_authority_id")
    })
    return request({
        url: "/api/wenjuan/save",
        method: "post",
        data: data
    })
}

// 问卷列表
export function juanList(data){
    return request({
        url: "/api/wenjuan/list",
        method: "get",
        params: data
    })
}

// 获取标签
//https://wenjuan.isv-dev.youzan.com/getTags?authority_id=92517845
export function getTags(data){
    Object.assign(data, {
        authority_id: localStorage.getItem("wj_authority_id")
    })
    return request({
        url: "/api/wenjuan/getTags",
        method: "get",
        params: data
    })
}

// 获取优惠券，优惠码
//https://wenjuan.isv-dev.youzan.com/couponlist?authority_id=92517845&type=1优惠券列表接口 type=2优惠码列表接口
export function couponlist(data){
    Object.assign(data, {
        authority_id: localStorage.getItem("wj_authority_id")
    })
    return request({
        url: "/couponlistv2",
        method: "get",
        params: data
    })
}

//https://wenjuan.isv-dev.youzan.com/getPresentList?authority_id=92517845
export function presentList(data){
    Object.assign(data, {
        authority_id: localStorage.getItem("wj_authority_id")
    })
    return request({
        url: "/getPresentList",
        method: "get",
        params: data
    })
}

// 上传图片
// https://wenjuan.isv-dev.youzan.com/upload post 2个参数： authority_id file
export function upload(data){
    return request({
        url: "/api/common/upload",
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: "post",
        data: data
    })
}

// 详情页数据
export function getDetails(data){
    return request({
        url: "/api/wenjuan/get",
        method: "get",
        params: data
    })
}

//暂停回收与开始回收
export function setStatus(data) {
    return request({
        url: "/api/wenjuan/setStatus",
        method: "get",
        params: data
    })
}

//删除
export function deleteQuestion(data) {
    return request({
        url: "/api/wenjuan/delete",
        method: "get",
        params: data
    });
}

export function getAnswerData(data) {
    return request({
        url: "/api/stat/get",
        method: "get",
        params: data
    });
}

export function getAnswerListByUser(data) {
    return request({
        url: "/api/stat/getListByUser",
        method: "get",
        params: data
    });
}

export function getAnswerOtherOption(data) {
    return request({
        url: "/api/stat/getOtherOption",
        method: "get",
        params: data
    });
}

export function getAnswerInputOption(data) {
    return request({
        url: "/api/stat/getInputOption",
        method: "get",
        params: data
    });
}

export function answerDetail(data) {
    return request({
        url: "/api/stat/answerDetail",
        method: "get",
        params: data
    });
}

export function delData(data) {
    return request({
        url: "/api/wenjuan/delData",
        method: "get",
        params: data
    });
}

export function getAccessToken(data) {
    return request({
        url: "/api/wenjuan/getAccessToken",
        method: "get",
        params: data
    });
}

export function newUserToken(data) {
    return request({
        url: "/api/wenjuan/newUserToken",
        method: "get",
        params: data
    });
}

//https://wenjuan.isv-dev.youzan.com/qr?authority_id=92517845&qid=222
export function getQrData(data) {
    Object.assign(data, {
        authority_id: localStorage.getItem("wj_authority_id")
    })
    return request({
        url: "/qr",
        method: "get",
        params: data
    });
}

export function getShareConfig(data) {
    return request({
        url: "/getShareConfig",
        method: "get",
        params: data
    });
}

export function saveShareConfig(data) {
    return request({
        url: "/saveShareConfig",
        method: "get",
        params: data
    });
}

export function saveAnswer(data) {
    console.log(data);
    return request({
        url: "/api/answer/save",
        method: "post",
        data: data
    });
}