import React from "react";
import { Icon } from "zent";
import "./left.css";

class Left extends React.Component {
    
    render() {
        return (
            <div className="w1_left_title" >
                <Icon type="order" style={{ "fontSize": "20px" }} />
                {<span>问卷</span>}
                {/* <span>装修</span> */}
            </div>
        )
    }
}

export default Left