import React from "react";
import { Radio } from 'zent';
import star1 from "@/assets/images/icons/star.png";
const RadioGroup = Radio.Group;

/**
 * @description 是否必选组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    stateChange(e) {
        e.persist();
        this.props.setValue(this.props.item.key, e.target.value)
    }
    stateBlur(){
        if(this.props.item.required){
            this.dist = true;
            this.setState({ value: 1 });
        }
    }

    render() {
        return (
            <div className={`w1_form_item ${!this.props.item.notsameline && "sameline"} ${this.props.item.value? "fill": ""} ${this.dist? "dist": ''}`}>
                <div className="name_line">
                    {this.props.item.required && <img src={star1}></img>}
                    <span>{this.props.item.title}</span>
                </div>
                <RadioGroup onChange={this.stateChange.bind(this)} value={this.props.item.value}>
                    {this.props.item.options.map((one, index) => {
                        return <Radio value={one.key} key={index}>{one.value}</Radio>
                    })}
                </RadioGroup>
                {this.props.item.msg}
            </div>
        )
    }
}

export default Item