import React from "react";
import { Icon } from "zent";
import { provinces, cities, areas } from "@/utils/cityData";

class Item extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
        value: [],
        visible: true,
        tabs: [
            {
                key: 1,
                value: "请选择"
            },
            {
                key: 2,
                value: "请选择"
            },
            {
                key: 3,
                value: "请选择"
            },
        ],
        activeIndex: 0,
        provinceCode: "",
        provinceName: "",
        cityCode: "",
        cityName: "",
        areaCode: "",
        areaName: "",
        show: false
    }
    ref = React.createRef();

    closeCascader(type){
        this.setState({
            show: false
        })
        if(type != "submit"){
            this.init();
        }
        setTimeout(() => {
            this.ref.current.style.display = "none";
        }, 500)
    }
    showCascader(){
        this.ref.current.style.display = "block";
        this.init();
        setTimeout(() => {
            this.setState({
                show: true
            });
            this.resetActiveLinePosition(this.state.activeIndex);
        },0);
    }
    getAreaResult(){
        this.closeCascader("submit");
        this.props.getAreaResult({
            provinceCode: this.state.provinceCode,
            provinceName: this.state.provinceName,
            cityCode: this.state.cityCode,
            cityName: this.state.cityName,
            areaCode: this.state.areaCode,
            areaName: this.state.areaName,
        });
    }
    activeTab(index) {
        if (index !== this.state.activeIndex) {
            this.setState({
                activeIndex: index
            })
            this.resetActiveLinePosition(index);
        }
    }
    resetActiveLinePosition(index) {
        let wrapperRect = this.ref.current.getBoundingClientRect();
        let activeTab = document.querySelectorAll(".w2c_tab_tag")[index];
        let left = activeTab.querySelector(".hidden_span").getBoundingClientRect().left - wrapperRect.left;
        document.querySelector(".w2c_active_line").style.left = left + "px";
        document.querySelectorAll(".w2c_scroll_box").forEach(scrollDom => {
            scrollDom.scrollTop = 0;
        });
    }
    selectThis(obj, type) {
        if (type === "province") {
            this.state.activeIndex = 1;
            if (this.state.provinceCode != obj.code) {
                this.state.tabs[0].value = obj.name;
                this.state.tabs[1].value = "请选择";
                this.setState({
                    provinceCode: obj.code,
                    provinceName: obj.name,
                    cityCode: "",
                    cityName: "",
                    areaName: "",
                    areaCode: ""
                })
            }
            this.setState({
                activeIndex: 1
            })
        } else if (type === "city") {
            this.state.activeIndex = 2;
            if (this.state.cityCode != obj.code) {
                this.state.tabs[1].value = obj.name;
                this.state.tabs[2].value = "请选择";
                this.setState({
                    cityCode: obj.code,
                    cityName: obj.name,
                    areaName: "",
                    areaCode: ""
                })
            }
            this.setState({
                activeIndex: 2
            })
        } else {
            if (this.state.areaCode != obj.code) {
                this.state.tabs[2].value = obj.name;
                this.setState({
                    areaCode: obj.code,
                    areaName: obj.name
                })
            }
            setTimeout(() =>{
                this.getAreaResult();
            } , 0)
        }
        setTimeout(() => {
            this.resetActiveLinePosition(this.state.activeIndex);
        }, 0);
    }
    init(){
        this.state.activeIndex = 0;
        let areaObj = this.props.areaObj || {};
        if(areaObj.provinceName){
            this.state.tabs[0].value = areaObj.provinceName;
        } else {
            this.state.tabs[0].value = "请选择";
        }
        if(areaObj.cityName){
            this.state.tabs[1].value = areaObj.cityName;
        } else {
            this.state.tabs[1].value = "请选择";
        }
        if(areaObj.areaName){
            this.state.tabs[2].value = areaObj.areaName;
        } else {
            this.state.tabs[2].value = "请选择";
        }
        this.setState({
            activeIndex: 0,
            provinceCode: areaObj.provinceCode,
            provinceName: areaObj.provinceName,
            cityCode: areaObj.cityCode,
            cityName: areaObj.cityName,
            areaCode: areaObj.areaCode,
            areaName: areaObj.areaName,
        })
    }
    componentDidMount() {
        
    }
    render() {
        return (
            <div className="w2_cascader_wrapper"
                ref={this.ref}>
                <div className={`w2_popover_box ${this.state.show ? "active" : ""}`}>
                    <div className="w2c_title_line">
                        <div>请选择所在地区</div>
                        <div className="w2_close_cascader" onClick={this.closeCascader.bind(this)}>
                            <Icon type="close"/>
                        </div>
                    </div>
                    <div className="w2c_content">
                        <div className="w2c_tabs">
                            {this.state.tabs.map((t, index) => {
                                return <div key={t.key} className="w2c_tab_tag" onClick={this.activeTab.bind(this, index)}
                                    style={{ visibility: ((index == 1 && this.state.provinceCode) || (index == 2 && this.state.cityCode) || index == 0) ? "visible" : "hidden" }}
                                >{t.value}
                                    <span className="hidden_span"></span>
                                </div>
                            })}
                            <div className="w2c_active_line"></div>
                        </div>

                        <div className={`w2c_tab_content active_${this.state.activeIndex}`}>
                            <div className="province_wrapper w2c_tab_content_item">
                                <div className="w2c_scroll_box">
                                    <ul>
                                        {
                                            provinces.map((p) => {
                                                return (
                                                    <li onClick={this.selectThis.bind(this, p, "province")} key={p.code} className={`${p.code == this.state.provinceCode ? "active" : ""}`}>
                                                        <div>{p.name}</div>
                                                        {this.state.provinceCode == p.code ? <Icon type="check" style={{ color: "#1989fa" }} /> : ""}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="cities_wrapper w2c_tab_content_item">
                                <div className="w2c_scroll_box">
                                    <ul>
                                        {
                                            cities.map((p) => {
                                                if (p.provinceCode == this.state.provinceCode) {
                                                    return (
                                                        <li onClick={this.selectThis.bind(this, p, "city")} key={p.code} className={`${p.code == this.state.cityCode ? "active" : ""}`}>
                                                            <div>{p.name}</div>
                                                            {this.state.cityCode == p.code ? <Icon type="check" style={{ color: "#1989fa" }} /> : ""}
                                                        </li>
                                                    )
                                                } else {
                                                    return ""
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="areas_wrapper w2c_tab_content_item">
                                <div className="w2c_scroll_box">
                                    <ul>
                                        {
                                            areas.map((p) => {
                                                if (p.provinceCode == this.state.provinceCode && p.cityCode == this.state.cityCode) {
                                                    return (
                                                        <li onClick={this.selectThis.bind(this, p, "area")} key={p.code} className={`${p.code == this.state.areaCode ? "active" : ""}`}>
                                                            <div>{p.name}</div>
                                                            {this.state.areaCode == p.code ? <Icon type="check" style={{ color: "#1989fa" }} /> : ""}
                                                        </li>
                                                    )
                                                } else {
                                                    return ""
                                                }
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg" onClick={this.closeCascader.bind(this)}></div>
            </div>
        )
    }
}

export default Item;