import React from "react";
import Title from "./formItem/title";
import Description from "./formItem/description";
import CheckRequire from "./formItem/checkRequire";
import Level from "./formItem/level";
import Jump from "./formItem/jump";
import Option from "./formItem/option";
import { Button, ButtonGroup,Pop,Sweetalert } from 'zent';
import global from "@/utils/config";

class Single extends React.Component {
    constructor(props) {
        super(props);

    }
    state ={
        optionsRefs: []
    }
    titleRef = React.createRef();

    setImg(value, index) {
        this.props.activeModule.formData["options"][index].image = value;
        this.props.updateRequire();
    }
    setValue(key, value, index) {
        if (key === "option") {
            this.props.activeModule.formData["options"][index].value = value;
        } else if (key === "option_jump") {
            this.props.activeModule.formData["options"][index].jump = value;
        } else if (key === "option_tag") {
            if(index == -1){
                this.props.activeModule.formData["otherOption"].tags = value;
            } else{
                this.props.activeModule.formData["options"][index].tags = value;
            }
        } else if (key === "del") {
            //  删除
            this.props.activeModule.formData["options"].splice(index, 1);
        } else if (key === "delOther") {
            delete this.props.activeModule.formData.otherOption;
        } else {
            this.props.activeModule.formData[key].value = value;
        }
        if (key === "checkRequire") {
            var result = value === "1" ? true : false
            this.props.activeModule.required = result;
        }
        this.props.updateRequire();
    }

    addOption() {
        if(this.props.activeModule.freezeOptionNum){
            Sweetalert.confirm({
                content: <p>此选项不能删除，是否确认增加选项？</p>,
                onConfirm: () => {this.excuteAddOption()},
                onCancel: () => {},
                parentComponent: this
            });
        } else {
            this.excuteAddOption();
        }
    }
    excuteAddOption(){
        global.option.id++;
        this.props.activeModule.formData.options.push(JSON.parse(JSON.stringify(global.option)));
        this.props.updateRequire();
    }
    addOtherOption() {
        this.props.activeModule.formData.otherOption = JSON.parse(JSON.stringify(global.otherOption));
        this.props.updateRequire();
    }
    componentDidUpdate() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="w1_single w1_base">
                <Title setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.title} ref={this.titleRef}></Title>
                <Description setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.desc}></Description>
                <CheckRequire setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.checkRequire}></CheckRequire>
                <Level setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.level}></Level>
                <Jump setValue={this.setValue.bind(this)} item={this.props.activeModule.formData.jump}></Jump>
                {this.props.activeModule.formData.options 
                && (this.state.optionsRefs = [])
                && this.props.activeModule.formData.options.map((one, index) => {
                    let optionRef = React.createRef();
                    this.state.optionsRefs.push(optionRef);
                    return <Option setValue={this.setValue.bind(this)}
                        setImg={this.setImg.bind(this)}
                        key={index}
                        freezeOptionNum={this.props.activeModule.freezeOptionNum}
                        item={one}
                        optionJump={this.props.activeModule.formData.jump.value.key === "0"}
                        index={index}
                        ref={optionRef}></Option>
                })}
                <ButtonGroup className="add_btn first_btn">
                    <Button icon="plus" onClick={this.addOption.bind(this)}>添加选项</Button>
                </ButtonGroup>
                {this.props.activeModule.formData.otherOption ? <Option setValue={this.setValue.bind(this)} index={-1} item={this.props.activeModule.formData.otherOption} freezeOtherOption={this.props.activeModule.freezeOtherOption}></Option> : ""}
                <ButtonGroup className="add_btn">
                    {this.props.activeModule.formData.otherOption && <Pop trigger={"hover"} position="top-left" content="一道题仅能添加一个「其他」项" fixMouseEventsOnDisabledChildren>
                        <Button icon="plus" disabled={true} onClick={this.addOtherOption.bind(this)}>添加「其他」项</Button>
                    </Pop>}
                    {!this.props.activeModule.formData.otherOption &&
                        <Button icon="plus" onClick={this.addOtherOption.bind(this)}>添加「其他」项</Button>
                    }
                </ButtonGroup>

            </div>
        )
    }
}

export default Single