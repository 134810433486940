import React from "react"
import {FullScreenLoading} from "zent";

class Ueditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id ? this.props.id : null,
            ueditor: null,
            time: 0,
            loading: true
        }
        this.observer = new MutationObserver(this.mutationOberserverCallback.bind(this));
        this.screenChangeHandler = this.screenChangeHandler.bind(this);
    }

    mutationOberserverCallback(mutationList, observer) {
        this.props.setAttr(this.props.key1, this.props.key2, this.state.ueditor.getContent());
        // mutationList.forEach((mutation) => {
        //   switch(mutation.type) {
        //     case 'childList':
        //       /* 从树上添加或移除一个或更多的子节点；参见 mutation.addedNodes 与
        //          mutation.removedNodes */
        //       break;
        //     case 'attributes':
        //       /* mutation.target 中某节点的一个属性值被更改；该属性名称在 mutation.attributeName 中，
        //          该属性之前的值为 mutation.oldValue */
        //       break;
        //   }
        // });
    }

    screenChangeHandler(event, isFullScreen){
        if(isFullScreen){
            document.querySelector(".w1_header").style.zIndex = "10";
        } else{
            document.querySelector(".w1_header").style.zIndex = "11";
        }
    }
    componentDidMount() {
        let UE = window.UE;
        let { id } = this.state;
        if (id) {
            try {
                /*加载之前先执行删除操作，否则如果存在页面切换，
                再切回带编辑器页面重新加载时不刷新无法渲染出编辑器*/
                UE.delEditor(id);
            } catch (e) { }
            if(UE){
                let ueditor = UE.getEditor(id, {
                    autoHeightEnabled: true,
                    autoFloatEnabled: true,
                    initialFrameHeight: 300
                });
                ueditor.ready(() => {
                    ueditor.setContent(this.props.value);
                    this.setState({
                        loading: false
                    })
                    var observerOptions = {
                        childList: true,  // 观察目标子节点的变化，是否有添加或者删除
                        attributes: true, // 观察属性变动
                        subtree: true     // 观察后代节点，默认为 false
                    }
                    this.observer.observe(ueditor.body, observerOptions);
                    console.log("ueditor-ready");
                });
                ueditor.addListener('beforefullscreenchange', this.screenChangeHandler);
                this.setState({ ueditor });
            }
        }
    }
    componentWillUnmount() {
        this.state.ueditor && this.state.ueditor.removeListener('beforefullscreenchange', this.screenChangeHandler);
        this.observer.disconnect();
    }
    render() {
        let { id } = this.state;
        return (
            <div>
                <div id={id} />
                <FullScreenLoading loading={this.state.loading} icon="circle" iconText="加载中"/>
            </div>
        );
    }
}

export default Ueditor