
import axios from 'axios';
import {Notify} from 'zent';
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
// let www = window.location.hostname.substring(0, window.location.hostname.indexOf(process.env.APP_BASE_URL))
const service = axios.create({
  baseURL: "https://www." + process.env.APP_BASE_URL,
  // baseURL: window.location.protocol + "//" + www + process.env.APP_BASE_URL,
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // 超时
  timeout: 0
})


//request请求拦截器
service.interceptors.request.use(
  config => {
    // console.log(config);
    // config.headers['Authorization'] = 'Bearer  kltesttoken'
    if (localStorage.getItem("iask_token")) {
      config.headers['token'] = localStorage.getItem("iask_token");
    }
    return config
  },
  error => {
    // console.log(error)
    Promise.reject(error)
  }
)
//response响应拦截器
service.interceptors.response.use(
  response => {
    // console.log(response);
    if (response.status === 200) {
    }

    if (response.headers.refreshtoken) {
      localStorage.setItem("iask_token", response.headers.refreshtoken)
    }

    return Promise.resolve(response);
  },
  error => {
    if(error.message.indexOf("401") >=0 ){
      Notify.error("请登录后操作", 1500, function(){
        console.log(window.location);
        if(window.location.hash !== "#/"){
          window.location.href = "/"
        }
      })
    }
    return Promise.reject(error);
  }
)

export default service
