import React from "react";
import { Input } from 'zent';

class Item extends React.Component {
    state = {
        errorNum: 0
    }
    ref = React.createRef();
    onChange = e => {
        e.persist();
        this.props.item.formData.value = e.target.value;
        this.props.questionChange(this.props.item);
        let checkResult = this.props.timeCheck(this.props.item);
        this.setState({
            errorNum: checkResult.errorNum
        })
    };
    componentDidMount() {
    }
    render() {
        return (
            <div className="preview_module_wrapper"
                ref={this.ref}>
                {/* 提示 */}
                <div className="preview_tooltip">
                    <span className="left_triangle"></span>
                    <div className="preview_tooltip_text">{this.props.item.title}</div>
                </div>
                {/* 标题 */}
                <div className="preview_name_line">
                    <div className="require_wrapper">
                        {this.props.item.formData.checkRequire.value == 1 && <span style={{color: "#E02020"}}>*</span>}
                    </div>
                    <div>
                        {this.props.item.num + " . "}{this.props.item.formData.title.value ? this.props.item.formData.title.value : "标题"}
                    </div>
                </div>
                {/* 题目描述 */}
                <div className="preview_des">{this.props.item.formData.desc.value}</div>
                <div className="preview_input_wrapper">
                    <Input
                        type={this.props.item.formData.multi.value == "1" ? "text" : "textarea"}
                        onChange={this.onChange.bind(this)}
                        value={this.props.item.formData.value} />
                </div>
                {this.state.errorNum == this.props.item.num && <div className="preview_regex_check_tips">
                    {this.props.item.data_type === "1" ? "请输入文本" : ""}
                    {this.props.item.data_type === "2" ? "请输入数字" : ""}
                </div>}
            </div>
        )
    }
}

export default Item;