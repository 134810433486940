import React from "react";
const pageNameDic = {
    1: "一",
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
};
const getPageNum = {
    getPageNumName(pageNum) {
        let str = pageNum.toString();
        let name = "";
        for (let i = 0, len = str.length; i < len; i++) {
            if (i === len - 1 && str[i] === "0") {

            } else {
                name += pageNameDic[str[i]];
            }
            let unit = "";
            switch (len - i) {
                case 2:
                    unit = "十";
                    break;
                case 3:
                    unit = "百";
                    break;
                case 4:
                    unit = "千";
                    break;
                default:
                    break;
            }
            name += unit;
        }
        return name;
    },

    calcPageNum(allData) {
        let pages = allData.pages;
        for (let i = 0, len = pages.length; i < len - 1; i++) {
            pages[i].tab = <span>第{this.getPageNumName.bind(this, i + 1)()}页</span>;
            pages[i].id = i + 1;
            if (pages[i].modules) {
                for (let j = 0; j < pages[i].modules.length; j++) {
                    const m = pages[i].modules[j];
                    m.pid = i + 1;
                }
            }
        }
        pages[pages.length - 1].tab = <span>结束页</span>;
        return allData;
    }
}

export default getPageNum;