import React from "react";
import { Radio } from 'zent';
import Tag from "./tag";
import star1 from "@/assets/images/icons/star.png";
const RadioGroup = Radio.Group;

/**
 * @description 是否必选组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
        this.dist = false;
    }
    tagRef = React.createRef();
    stateChange(e) {
        e.persist();
        if (e.target.value == "2") {
            this.props.item.special.required = true;
        }
        this.props.setValue(this.props.item.key, e.target.value)
    }
    stateBlur() {
        if (this.props.item.required) {
            this.dist = true;
            this.setState({ value: 1 });
        }
    }
    setValue(key, value) {
        this.props.item[key].value = value;
        this.props.setValue(this.props.item.key, this.props.item.value);
    }

    render() {
        return (
            <div className={`w1_form_item ${!this.props.item.notsameline && "sameline"} ${this.props.item.value ? "fill" : ""} ${this.dist ? "dist" : ''}`}>
                <div className="name_line">
                    {this.props.item.required && <img src={star1}></img>}
                    <span>{this.props.item.title}</span>
                </div>
                <RadioGroup onChange={this.stateChange.bind(this)} value={this.props.item.value}>
                    {this.props.item.options.map((one, index) => {
                        return (<div key={index} className="give_obj_line">
                            <Radio value={one.key}>{one.value}</Radio>
                            {one.append && this.props.item.value == "2"
                                && <Tag
                                    setValue={this.setValue.bind(this)}
                                    item={this.props.item[one.append]}
                                    ref={this.tagRef}></Tag>}
                        </div>)
                    })}
                </RadioGroup>
                {this.props.item.msg && <div className="require">{this.props.item.msg}</div>}
            </div>
        )
    }
}

export default Item