import React from "react";
import { Input } from 'zent';

import star1 from "@/assets/images/icons/star.png";

/**
 * @description 标题组件
 */
class Item extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        dist:false
    }
    stateChange(e) {
        e.persist();
        // this.props.item.value = e.target.value;
        let value = e.target.value;
        value && (value = value.trim());
        this.props.item.value = value;
        this.props.setValue(this.props.item.key, value)
    }
    stateBlur() {
        if (this.props.item.required) {
            this.setState({ dist: true});
        }
    }

    componentDidMount(){
    }
    render() {
        return (
            <div className={`w1_form_item ${!this.props.item.notsameline && "sameline"} ${this.props.item.value ? "fill" : ""} ${this.state.dist ? "dist" : ''}`}>
                <div className="name_line" style={{ color: this.props.item.color }}>
                    <div>
                        {this.props.item.required && <img src={star1}></img>}
                        <span>{this.props.item.title}</span>
                    </div>
                </div>
                <Input placeholder={this.props.item.placeholder}
                    onChange={this.stateChange.bind(this)}
                    onBlur={this.stateBlur.bind(this)}
                    value={this.props.item.value} />
                {this.props.item.msg && <div className="require">{this.props.item.msg}</div>}
            </div>
        )
    }
}

export default Item